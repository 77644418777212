import * as SQLite from 'expo-sqlite';
import { Database } from './database';

export class UserDatabase extends Database {
  readonly TABLE_NAME = "Users";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      // var sql = `CREATE TABLE IF NOT EXISTS ${this.TABLE_NAME} ( 
      //   id TEXT PRIMARY KEY,
      //   email TEXT,
      //   password TEXT,
      //   name TEXT
      // )`;

      // this.getDatabase().exec([{ sql: sql, args: [] }], false, () =>
      //   resolve()
      // );  
    });
  }

  insertOrUpdateList(entities: any): Promise<void> {
    throw new Error('Method not implemented.');
  }

  login(email, password) : Promise<any> {
    return new Promise((resolve, reject) => {
      resolve("FROM DATABASE");
      // var sql = `CREATE TABLE IF NOT EXISTS ${this.TABLE_NAME} ( 
      //   Id TEXT PRIMARY KEY,
      //   Email TEXT,
      //   Password TEXT,
      //   Name TEXT
      // )`;

      // this.getDatabase().exec([{ sql: sql, args: [] }], false, () =>
      //   resolve()
      // );  
    });
  }

}