import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, ScrollView, Image, Alert, Modal, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { MaterialCommunityIcons  } from '@expo/vector-icons';
import { TitleBar } from '../../../../screens/app/components/TitleBar';
import { setInspectionData, updateDefectData, updateQuestionData, updateQuestionListData } from '@store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { Camera } from 'expo-camera';
import { CategoryDatabase } from '@store/services/database/category-database';
import { InspectionDatabase } from '@store/services/database/inspection-database';
import { EquipmentDatabase } from '@store/services/database/equipment-database';


export default function RoteStep3({navigation}: {navigation: any}) {
  const dispatch = useDispatch();
  const job = useSelector((state: RootState) => state.job);
  const inspection = useSelector((state: RootState) => state.roteInspection);

  const [questionsList, setQuestionsList] = useState(inspection.questions);
  const [optionsList, setOptionsList] = React.useState<any>([]);
  const [selectedQuestion, setSelectedQuestion] = React.useState<any>();

  const onSubmit = () => {
    dispatch(updateQuestionListData({ questions: questionsList }));
    navigation.navigate("Step4");
  };

  const setOptions = (question, options) => {
    setSelectedQuestion(question);
    var optionList = options.split(",")
    setOptionsList(optionList);
  }

  const updateQuestionResponse = (questionId, val) => {
    // Find our group that the question is in iniitially
    // I'm sure we can do this better but not sure right now and time is against us
    var group = null;
    for (let questionGroup of questionsList) {
      for (let question of questionGroup.questions) {
        if (question.id == questionId) {
          group = questionGroup;
          break;
        }
      }
    }

    const groupIndex = questionsList.indexOf(group);

    // Update our individual question using the group
    const updatedQuestions = group.questions.map(question => {
      if (question.id == questionId) { 
        return { ...question, response: val }; 
      } else {
        return question;
      }
    });

    // Update the group
    setQuestionsList(questionsList => {
      return questionsList.map((item, index) => {
        if (index == groupIndex) { return { ...item, questions: updatedQuestions }; }
        return item;
      })
    }) 

    // Set redux to be aware of this update
    // On web we need to update this per question as it updates because
    // we are showing all the steps in 1 go
    if (Platform.OS == 'web') {
      dispatch(updateQuestionData({
        questionId: questionId,
        response: val
      }));
    }
    
  }
  
  const onBack = () => {
    dispatch(updateQuestionListData({ questions: questionsList }));
    navigation.navigate("Step2");
  }

  return (
    <View style={styles.container}>
      { Platform.OS != 'web' && 
        <TitleBar navigation={navigation} title="Step 3 of 4" 
        showSearchButton={false} showBackButton={true} 
        onSynced={null} onSearch={null}
        backButtonDelegate={onBack}/> 
      }

      <Modal
        animationType="fade"
        transparent={true}
        visible={optionsList.length > 0}>

        <View style={styles.modal__block}>
          <View style={styles.modal}>
            <View style={{ flexDirection: 'row',  paddingBottom: 5, borderBottomWidth: 1, borderBottomColor: '#000', alignItems: 'center'}}>
              <Text style={{ fontWeight: 'bold', flex: 1}}>Options</Text>
              <TouchableOpacity style={styles.cancel__button} onPress={() => setOptionsList([])}>
                <Text style={styles.cancel__button__text}>CANCEL</Text>
              </TouchableOpacity>
            </View>

            { optionsList.map((option) => {
              return (
                <TouchableOpacity key={option} onPress={() => { updateQuestionResponse(selectedQuestion.id, option); setOptionsList([]) }}>
                  <Text style={{ paddingTop: 10, paddingBottom: 10, borderTopColor: '#CECECE', borderTopWidth: 1}}>{ option }</Text>
                </TouchableOpacity>
              )
            })}
          </View>
        </View>

      </Modal>

      { questionsList == null || questionsList.length == 0 &&
        <View style={styles.form}>
          <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
            <Text style={{ flex: 1, fontSize: 44, marginTop: 20, fontStyle: 'italic', fontWeight: '100', textAlign: 'center'}}>No Inspection Data</Text>
          </View>
        </View>
      }

      { questionsList != null && questionsList.length > 0 && 
        <ScrollView style={styles.form}>
          { questionsList.map((group, index) => {
            return (
              <View key={group.title} style={styles.form__group}>
                <Text style={styles.form__title}>{group.title}</Text>

                <View style={{ flexDirection: 'row'}}>
                  { group.questions.map((question) => {
                    return (
                      <View key={question.id} style={styles.step__question}>
                        <Text style={styles.step__question__label}>{question.question}</Text>

                        <View style={[styles.step__question__input, styles.step__question__input__grid]}>
                          <TextInput
                            //onBlur={(e) => updateQuestionResponse(question.id, e.nativeEvent.text)}
                            onChangeText={(e) => updateQuestionResponse(question.id, e)}
                            value={question.response}
                            style={{ flex: 1 }}
                            keyboardType={question.keyboardType}
                          />

                          { question.dropdownOptions != null && question.dropdownOptions != "" && 
                            <TouchableOpacity onPress={() => setOptions(question, question.dropdownOptions)}>
                              <MaterialCommunityIcons name="arrow-down-drop-circle-outline" size={24} color="black" />
                            </TouchableOpacity>
                          }
                        </View>
                      </View>
                    )
                  })}
                </View>
              </View>
            )
          })}
        </ScrollView>
      }

      {Platform.OS != 'web' && 
        <TouchableOpacity style={styles.proceed__button} onPress={onSubmit}>
          <Text style={styles.proceed__button__text}>NEXT</Text>
        </TouchableOpacity>
      }

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFAFA',
    padding: 40,
    ...Platform.select({ 
      android: { flex: 1},
      ios: { flex: 1 },
      web: { paddingBottom: 20, paddingTop: 0 }
    })
  },

  form: {
    flex: 1,
    marginTop: 10,
    ...Platform.select({ 
      web: { marginTop: 5 }
    })
  },
  
    form__group: {
      borderWidth: 1,
      borderColor: '#CECECE',
      marginTop: 5,
      padding: 1,
    },

    form__title: {
      padding: 10,
      backgroundColor: '#f5f5f5',
    },

    step__question: {
      padding: 20,
      backgroundColor: '#FFF',
      flex: 1
    },

    step__question__label: {
      fontFamily: 'OpenSans-Light',
      fontSize: 14,
      marginBottom: 5,
    },

    step__question__input: {
      borderWidth: 1,
      borderColor: '#CECECE',
      padding: 10,
      marginTop: 5,
    },

      step__question__input__grid: {
        flexDirection: 'row'
      },

    input__validation: {
      color: 'red',
      marginTop: 10
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },

  safe__block: {
    flexDirection: 'row',
  },

  safe__button: {
    flex: 1,
    height: 140,
    backgroundColor: '#edebeb',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 5,
  },

    safe__button__text: {
      fontFamily: 'OpenSans-Bold',
      marginLeft: 10,
      fontSize: 38
    },

    not_safe: {
      marginRight: 10,
    },

    safe: {
      marginLeft: 10,
    },

    unsafe_checked: {
      backgroundColor: 'red',
    },

    safe_checked: {
      backgroundColor: 'green',
    },

    safe__button__text__checked: {
      color: '#FFF'
    },

  image__container: {
    flexDirection: 'row',
    padding: 10,
    marginBottom: 20,
    alignItems: 'center'
  },

    // image__block: {
    //   flex: 1,
    // },

      image: {
        height: 150,
        width: 150,
        marginRight: 20,
        marginLeft: 20,
      },

    empty__image: {
      borderWidth: 1,
      borderColor: '#CECECE'
    },

  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

    modal: {
      backgroundColor: '#FFF',
      padding: 10,
      width: 600,
      borderRadius: 5,

      flexDirection: 'column',
    },

  cancel__button: {
    backgroundColor: '#CECECE',
    padding: 5,
    borderRadius: 5,
    width: 80,
    alignItems: 'center'
  },

    cancel__button__text: {

    },
});
