import { useState, useEffect, forwardRef } from 'react';
import { TitleBar } from '@app/components/TitleBar';
import { StyleSheet, Text, TextInput, View, TouchableOpacity, Alert, KeyboardAvoidingView, Image, ScrollView } from 'react-native';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Ionicons  } from '@expo/vector-icons';
import { BreakdownDatabase } from '@store/services/database/breakdown-database';
import { Picker } from '@react-native-picker/picker';
import { Controller, useForm } from 'react-hook-form';
import { colours } from '../../../../../config';
import { FlashList } from '@shopify/flash-list';
import { setBreakdownData } from '@store/slices/breakdown-slice';
import moment from 'moment';
import { CameraModal } from '@app/components/CameraModal';

const FaultsTab = () => {
  const dispatch = useDispatch();

  const breakdown = useSelector((state: RootState) => state.breakdown);

  const [commonFaults, setCommonFaults] = useState<any>([]);
  const [faults, setFaults] = useState<any>([]);

  const { control, handleSubmit, getValues, setValue, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      commonFaultId: null,
      details: "",
      action: "",
      hours: null
    }
  });

  useEffect(() => {
    var breakdownDatabase = new BreakdownDatabase();

    const loadFaults = async () => {
      var faults = await breakdownDatabase.getBreakdownCommonFaults();
      setCommonFaults(faults);
    }

    loadFaults();
    
    // If we have fault in our breakdown we can set the,
    if (breakdown.faults != null && breakdown.faults.length > 0) {
      setFaults(breakdown.faults);
    }
  }, []);

  useEffect(() => {
    if (faults == null || faults.length == 0)
      return;

    dispatch(setBreakdownData({
      faults: faults,
    }))
  }, [faults]);

  const onSubmit = data => {
    var newFault = data;
    newFault.commonFault = commonFaults.find(f => f.id == data.commonFaultId);
    setFaults(faults => { return [newFault, ...faults]});

    reset();
  };
  
  const renderItem = ({item, index}) => (
    <View style={styles.row}>
      <Text style={styles.column}>{item.commonFault.fault}</Text>
      <Text style={styles.column}>{item.details}</Text>
      <Text style={styles.column}>{item.action}</Text>
      <Text style={styles.column}>{item.hours}</Text>
    </View>
  );

  return (
    <ScrollView style={{ flex: 1 }}>
      <Controller
        control={control}
        name="commonFaultId"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Fault Type</Text>
            <Picker
              selectedValue={value}
              onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="Please Select" value="" />

              { commonFaults != null && commonFaults.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.fault} value={value.id} />
                )
              })}
            </Picker>

            {errors.commonFaultId && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
          </View>
        )}
      />

      <Controller
        control={control}
        name="details"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Details of fault</Text>

            <TextInput
              style={styles.question__input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />

            {errors.details && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
          </View>
        )}
      />

      <View style={{ flexDirection: 'row' }}>
        <Controller
          control={control}
          name="action"
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{ flex: 1}}>
              <View style={styles.question}>
                <Text style={styles.question__label}>Action taken</Text>

                <TextInput
                  style={styles.question__input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                />

                {errors.action && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
              </View>
            </View>
          )}
        />

        <Controller
          control={control}
          name="hours"
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{width: 150}}>
              <View style={styles.question}>
                <Text style={styles.question__label}>Hours</Text>

                <TextInput
                  style={styles.question__input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  keyboardType={'decimal-pad'}
                />

                {errors.action && <Text style={styles.input__validation}>Enter</Text>}
              </View>
            </View>
          )}
        />

        <TouchableOpacity style={styles.add__button} onPress={handleSubmit(onSubmit)}>
          <Text style={styles.add__button__text}>ADD</Text>
        </TouchableOpacity>
      </View>

      <FlashList
        data={faults}
        renderItem={renderItem}
        estimatedItemSize={100}
      />
    </ScrollView>
  )
}

const SaveTab = () => {
  const dispatch = useDispatch();
  const breakdown = useSelector((state: RootState) => state.breakdown);

  const [partsList, setPartsList] = useState<any>([]);
  const [parts, setParts] = useState<any>([]);

  const [selectedPartId, setSelectedPartId] = useState<any>(null);

  const [showCamera, setShowCamera] = useState(false);
  const [images, setImages] = useState([]);


  useEffect(() => {
    var breakdownDatabase = new BreakdownDatabase();

    const loadParts = async () => {
      var parts = await breakdownDatabase.getBreakdownParts();
      setPartsList([...parts]);
    }

    loadParts();

    if (breakdown.parts != null && breakdown.parts.length > 0) {
      setParts(breakdown.parts);
    }
  }, []);

  useEffect(() => {
    if (parts == null || parts.length == 0)
      return;

    dispatch(setBreakdownData({
      parts: parts,
    }))
  }, [parts]);

  useEffect(() => {
    dispatch(setBreakdownData({
      images: images,
    }))
  }, [images]);

  const addPart = () => {
    var part = partsList.find(p => p.id == selectedPartId);
    setParts(parts => { return [part, ...parts]});
  }

  const addImage = (image) => {
    setImages([...images, image]);
    setShowCamera(false);
  }
  

  const removeImage = (image) => {
    Alert.alert("Images", "Are you sure you want to remove this image?", [
      {
        text: 'No',
        style: 'cancel'
      },
      { text: 'Yes', onPress: () => {setImages(images.filter(i => i !== image))}}
    ])
  }

  const loadCamera = () => {
    if (images.length >= 3) {
      Alert.alert("You have reached the maximum images.")
      return;
    }

    setShowCamera(true);
  }

   
  if (showCamera) {
    return (
      <CameraModal style={{ paddingTop: 10 }} onCancel={() => setShowCamera(false)} onPictureTaken={(image) => addImage(image)}/>
    )
  }

  const renderItem = ({item, index}) => (
    <View style={styles.row}>
      <Text style={styles.column}>{item.description}</Text>
    </View>
  );

  return (
    <View style={{ flex: 1}}>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <View style={styles.question}>
            <Text style={styles.question__label}>Parts</Text>
            <Picker
              selectedValue={selectedPartId}
              onValueChange={(itemValue, itemIndex) => setSelectedPartId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="Please Select" value="" />

              { partsList != null && partsList.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.description} value={value.id} />
                )
              })}
            </Picker>
          </View>
        </View>
        <TouchableOpacity style={styles.add__button} onPress={addPart}>  
          <Text style={styles.add__button__text}>ADD</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.image__container}>
        <TouchableOpacity onPress={() => loadCamera() } style={{ paddingLeft: 30, paddingRight: 30 }}>
          <Ionicons name="camera" size={92} color="black" />
        </TouchableOpacity>

        { images.map((image, idx) => {
          return (
            <TouchableOpacity key={idx} style={[styles.image__block]} onPress={() => removeImage(image)}>
              <Image
                style={[styles.image]}
                resizeMode={"contain"}
                source={{
                  uri: image,
                }}
              />
            </TouchableOpacity>
          )
        })}
      </View>

      <FlashList
        data={parts}
        renderItem={renderItem}
        estimatedItemSize={100}
      />
    </View>
  )
}


export default function BreakdownInspect({ navigation }: { navigation: any }) {
  const breakdown = useSelector((state: RootState) => state.breakdown);
  const job = useSelector((state: RootState) => state.job);
  
  const [loading, setLoading] = useState<any>(false);

  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState<any>(0);

  useEffect(() => {
    var tabList = [];

    // Load our tabs
    tabList.push({title: "Faults"})
    tabList.push({title: "Save"})

    setTabs(tabList);
    setActiveTab(tabList[0]);
    setTabIndex(0);
  }, []);

  const previous = () => {
    if (tabIndex == 0)
      return;

    var idx = tabIndex - 1;
    setTabIndex(idx);

    updateActiveTab(idx);
  }

  const next = () => {
    if (tabIndex == tabs.length - 1) {
      save();
      return;
    };

    var idx = tabIndex + 1;
    setTabIndex(idx);

    updateActiveTab(idx);
  }

  const save = async () => {
    setLoading(true);


    if (breakdown.faults != null) {
   
      // if (descriptionOfFault != "") {
      //   descriptionOfFault = descriptionOfFault.substring(0, descriptionOfFault.length - 2);
      // }

      // console.log(descriptionOfFault);
    }

    var breakdownDatabase = new BreakdownDatabase();

    var inspectionDate = moment().format("YYYY-MM-DD");
    var nextInspectionDate = moment().format("YYYY-MM-DD");

    await breakdownDatabase.addInspection(
      inspectionDate, nextInspectionDate, job.equipmentId, job.engineerId, job.jobId, job.purposeId, job.colourId, false, breakdown.parts, breakdown.faults, breakdown.images
    );

    setLoading(false);

    navigation.navigate("JobAssets");
  }

  const updateActiveTab = (idx) => {
    var newTab = tabs[idx];
    setActiveTab(newTab);
  }

  if (tabs.length == 0 || activeTab == null || loading) {
    return <LoadingIndicator/>
  }


  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title={"Breakdown"} showBackButton={true} showSearchButton={false} onSynced={null} promptBack={true}/>

      <View style={styles.tabs}>
        { tabs.map((screen: any, index: number) => {
          return (
            <TouchableOpacity key={index} style={[styles.tab, activeTab.title == screen.title && styles.tab__active]}>
              <Text style={[styles.tab__text, activeTab.title == screen.title && styles.tab__text__active]}>{screen.title}</Text>
            </TouchableOpacity>
          )
        })}
      </View>

      <View style={{ flex: 1 }}>
        {tabIndex == 0 && <FaultsTab />}
        {tabIndex == 1 && <SaveTab />}
      </View>

      <View style={styles.action__buttons}>
        <TouchableOpacity style={styles.action__button} onPress={() => previous()}>
          <Text style={styles.action__button__text}>PREVIOUS</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.action__button} onPress={() => next()}>
          <Text style={styles.action__button__text}>
            {tabIndex == tabs.length - 1 ? 'SAVE' : 'NEXT'}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 20,
    paddingTop: 40,
    flexDirection: 'column',
  },

  tabs: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },

    tab: {
      flex: 1,
      backgroundColor: '#FFF',
      borderBottomWidth: 1,
      borderBottomColor: colours.primary,
    },

      tab__active: {
        backgroundColor: colours.primary
      },

      tab__text: {
        color: colours.primary,
        fontFamily: 'OpenSans-Bold',
        fontSize: 16,
        padding: 10,
        textAlign: 'center'
      },

        tab__text__active: {
          color: '#FFF'
        },

  action__buttons: {
    flexDirection: 'row',
    marginTop: 30,
  },

    action__button: {
      backgroundColor: colours.primary,
      flex: 1,
      marginLeft: 5,
      marginRight: 5,
      borderRadius: 5
    },

      action__button__text: {
        color: '#FFF',
        padding: 10,
        textAlign: 'center',
      },

  row: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    marginBottom: 10,
  },
  
    column: {
      flex: 1,
      padding: 10,
      fontSize: 14,
      textAlign: 'center',
    },

  input__validation: {
    color: 'red',
    marginTop: 10
  },

  question: {
    margin: 5,
    padding: 10,
    backgroundColor: '#FFF',
  },

    question__label: {
      fontWeight: 'bold',
      fontSize: 20,
    },

    question__input: {
      borderWidth: 1,
      borderColor: '#CECECE',
      padding: 10,
      marginTop: 5,
    },

  add__button: {
    backgroundColor: colours.primary,
    width: 100,
    alignSelf: 'flex-end',
    marginBottom: 10,
    height: 70,

    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    borderRadius: 10,
  },

    add__button__text: {
      color: '#FFF',
      fontWeight: 'bold',
    },

    image__container: {
      flexDirection: 'row',
      padding: 10,
      marginBottom: 20,
      alignItems: 'center'
    },
  
      // image__block: {
      //   flex: 1,
      // },
  
        image: {
          height: 150,
          width: 150,
          marginRight: 20,
          marginLeft: 20,
        },
  
      empty__image: {
        borderWidth: 1,
        borderColor: '#CECECE'
      }
});

