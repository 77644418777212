import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface BreakdownState {
  // Inspection Data
  faults: Array<any> | null,
  parts: Array<any> | null,
  images: Array<any> | [],
}

const initialState: BreakdownState = {
  faults: null,
  parts: null,
  images: [],
}

export const breakdownSlice = createSlice({
  name: 'breakdown',
  initialState,
  reducers: {
    setBreakdownData: (state, action: PayloadAction<any>) => {
      if (action.payload.faults != null) state.faults = action.payload.faults;
      if (action.payload.parts != null) state.parts = action.payload.parts;
      if (action.payload.images != null) state.images = action.payload.images;
    },

    clearBreakdownData: (state, action: PayloadAction) => {
      state.faults = null;
      state.parts = null;
      state.images = [];
    },
  }
})

export const { setBreakdownData, clearBreakdownData } = breakdownSlice.actions;

export default breakdownSlice.reducer;