import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';

export class EquipmentDatabase extends Database {
  TABLE_NAME = "Equipment";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Equipment ( 
        id TEXT PRIMARY KEY,
        description TEXT,
        serialNumber TEXT,
        assetNumber TEXT,
        dateOfManufacture TEXT,
        proofLoad TEXT,
        swl TEXT,
        locationId TEXT,
        categoryId TEXT,
        manufacturerId TEXT,

        DOC INT,
        transposedStandards TEXT,
        nationalStandards TEXT,
        dateOfFirstUse TEXT,

        couplings TEXT,
        wireRopes TEXT,

        toBeSynced INT
      )`;

      var sqlExtras = `CREATE TABLE IF NOT EXISTS EquipmentExtras (
        id TEXT PRIMARY KEY,
        serialNum TEXT,
        make TEXT,
        equipmentExtraType INT,
        equipmentId TEXT
      )`;

      var sqlLoadTypes = `CREATE TABLE IF NOT EXISTS LoadTypes (
        id TEXT PRIMARY KEY,
        type TEXT
      )`;

      var sqlLoadUnits = `CREATE TABLE IF NOT EXISTS LoadUnits (
        id TEXT PRIMARY KEY,
        unit TEXT
      )`;

      var sqlInspectionAnsweredQuestions = `CREATE TABLE IF NOT EXISTS EquipmentInspectionAnsweredQuestions (
        id TEXT PRIMARY KEY,
        inspectionQuestionId TEXT,
        question TEXT,
        response TEXT,
        position INT,
        equipmentId TEXT
      )`;

      var sqlInspectionDefects = `CREATE TABLE IF NOT EXISTS EquipmentInspectionDefects (
        id TEXT PRIMARY KEY,
        defectGroup TEXT,
        defect TEXT,
        timeToRectifyInWeeks TEXT,
        equipmentId TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sqlExtras);
        tx.executeSql(sql);
        tx.executeSql(sqlLoadTypes);
        tx.executeSql(sqlLoadUnits);
        tx.executeSql(sqlInspectionAnsweredQuestions);
        tx.executeSql(sqlInspectionDefects);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateLoadUnitsList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO LoadUnits (id, unit)
            VALUES (?, ?)
          `;

          tx.executeSql(sql, [
            entity.id, entity.unit
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  insertOrUpdateLoadTypesList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO LoadTypes (id, type)
            VALUES (?, ?)
          `;

          tx.executeSql(sql, [
            entity.id, entity.type
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  insertOrUpdateList(entities: any, toBeSynced: boolean) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, description, serialNumber, assetNumber, dateOfManufacture, proofLoad, swl, locationId, categoryId, manufacturerId,
              DOC, transposedStandards, nationalStandards, dateOfFirstUse, couplings, wireRopes, toBeSynced
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.description, entity.serialNumber, entity.assetNumber, entity.dateOfManufacture, entity.proofLoad, 
            entity.swl, entity.locationId, entity.categoryId, entity.manufacturerId,
            entity.doc == true ? 1 : 0, entity.transposedStandards, entity.nationalStandards, entity.dateOfFirstUse, 
            entity.couplings, entity.wireRopes, toBeSynced
          ]);

          sql = "INSERT OR REPLACE INTO EquipmentExtras (id, serialNum, make, equipmentExtraType, equipmentId) " +
          "VALUES (?, ?, ?, ?, ?)";
          for (let extra of entity.equipmentExtras) {
            if (extra.id == undefined || extra.id == null) { extra.id = uuid.v4()}
            tx.executeSql(sql, [extra.id, extra.serialNum, extra.make, extra.equipmentExtraType, entity.id]);
          }
          

          // Add inspection questions
          sql = "DELETE FROM EquipmentInspectionAnsweredQuestions WHERE equipmentId = ?";
          tx.executeSql(sql, [entity.id]);
          
          if (entity.questions == null) { entity.questions = []; }
          for (let question of entity.questions) {
            sql = `INSERT OR REPLACE INTO EquipmentInspectionAnsweredQuestions (id, inspectionQuestionId, question, response, position, equipmentId) VALUES (?, ?, ?, ?, ?, ?)`;
            tx.executeSql(sql, [question.id, question.inspectionQuestionId, question.question, question.response, question.position, entity.id]);
          }

          // Add inspection defects
          sql = "DELETE FROM EquipmentInspectionDefects WHERE equipmentId = ?";
          tx.executeSql(sql, [entity.id]);
          
          if (entity.defects == null) { entity.defects = []; }
          for (let defect of entity.defects) {
            sql = `INSERT OR REPLACE INTO EquipmentInspectionDefects (id, defectGroup, defect, timeToRectifyInWeeks, equipmentId) VALUES (?, ?, ?, ?, ?)`;
            tx.executeSql(sql, [defect.id, defect.defectGroup, defect.defect, defect.timeToRectifyInWeeks, entity.id]);
          }
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getById(equipmentId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var equipment = null;
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT Equipment.*, Manufacturers.name AS manufacturerName, Categories.name AS categoryName FROM Equipment
          INNER JOIN Manufacturers ON Equipment.manufacturerId = Manufacturers.id
          INNER JOIN Categories ON Equipment.categoryId = Categories.id
          WHERE Equipment.id = ?
        `;

        tx.executeSql(sql, [equipmentId], (_, { rows: { _array } }) => {
          //console.log(_array)
          if (_array.length > 0) {
            equipment = _array[0];

            sql = `SELECT * FROM EquipmentExtras WHERE equipmentId = ?`;
            equipment.equipmentExtras = [];
            tx.executeSql(sql, [equipment.id], (_, { rows: { _array } }) => {
              equipment.equipmentExtras = _array;
            });

            sql = `SELECT * FROM EquipmentInspectionAnsweredQuestions WHERE equipmentId = ?`;
            equipment.questions = [];

            tx.executeSql(sql, [equipment.id], (_, { rows: { _array } }) => {
              equipment.questions = _array;
            });

            sql = `SELECT * FROM EquipmentInspectionDefects WHERE equipmentId = ?`;
            equipment.defects = [];
            tx.executeSql(sql, [equipment.id], (_, { rows: { _array } }) => {
              equipment.defects = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(equipment);
      })
    })
  }

  getByLocationId(locationId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Equipment WHERE locationId = ?
        `;

        tx.executeSql(sql, [locationId], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Equipment WHERE toBeSynced = 1

        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          for (let equip of data) {
            sql = `SELECT * FROM EquipmentExtras WHERE equipmentId = ?`;
            equip.equipmentExtras = [];
            tx.executeSql(sql, [equip.id], (_, { rows: { _array } }) => {
              equip.equipmentExtras = _array;
            });
          }

          console.log(data);
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  setToBeSynced(equipmentId: any, toBeSynced: boolean) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET toBeSynced = ? WHERE id = ?
          `

          tx.executeSql(sql, [toBeSynced, equipmentId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getLoadTypes() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM LoadTypes ORDER BY Type
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getLoadUnits() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM LoadUnits ORDER BY Unit
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }
}