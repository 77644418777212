import { Entypo, EvilIcons, FontAwesome, Foundation, SimpleLineIcons } from '@expo/vector-icons';
import { Picker } from '@react-native-picker/picker';
import { RootState } from '@store/index';
import { PagedTableDto } from '@store/services/api/base-api';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { inspectionApi } from '@store/services/api/inspection-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { useGetEngineersQuery } from '@store/services/api/user-api';
import { ReportableService } from '@store/services/reportable/reportable.service';
import DateTimePicker from '@web/components/navigation/controls/DateTimePicker';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { DataTable } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyle from '../../../../constants/GlobalStyle';

export default function DashboardScreen({navigation}: {navigation: any}) {
  const inspectionData = false;

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const [passedCount, setPassedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [missingCount, setMissingCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: engineers } = useGetEngineersQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);

  const [page, setPage] = React.useState<number>(0);
  const [trigger, inspectionResult] = inspectionApi.useLazyGetInspectionsQuery();
  const [sortedColumn, setSortedColumn] = React.useState<string>("inspectionDate");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [engineerId, setEngineerId] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState(moment().add(-6, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [serialNumber, setSerialNumber] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [reportNumber, setReportNumber] = useState("");
  const [statusId, setStatusId] = useState("-1");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (inspectionResult.isSuccess) {
      setPassedCount(inspectionResult.data.passed);
      setFailedCount(inspectionResult.data.failed);
      setMissingCount(inspectionResult.data.missing);
      setTotalCount(inspectionResult.data.pagination.totalCount);
    }
  }, [inspectionResult])

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  const resetFilters = () => {
    setCompanyId(user.companyId);
    setLocationId("");
    setEngineerId("");
    setDateRange("");
    setStartDate(moment().add(-6, 'months').toDate());
    setEndDate(moment().toDate());
    setSerialNumber("");
    setCategoryId("");
    setJobNumber("");
    setReportNumber("");
    setStatusId("-1");
    setDescription("");
    setPage(0);
  }

  const loadTable = () => {
    var dto = new PagedTableDto(page, { 
      companyId: companyId,
      locationId: locationId,
      engineerId: engineerId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      serialNumber: serialNumber,
      categoryId: categoryId,
      jobNumber: jobNumber,
      reportNumber: reportNumber,
      statusId: statusId,
      description: description,
      inspectionData: inspectionData,
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const downloadCert = async (inspection) => {
    console.log(inspection);
    //inspection.isLoading = true;
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRote(inspection.id);
    //inspection.isLoading = false;
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  const downloadRAMS = async (inspection) => {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRAMS(inspection.ramsId);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  useEffect(() => {
    loadTable();
  }, [page])
  
  if (inspectionResult.isLoading || inspectionResult.status == 'pending') {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={styles.stats}>
        <View style={[styles.stat, styles.stat__passed]}>
          <Entypo style={styles.stat__icon} name="check" size={65} color="white" />
          <View style={styles.stat__info}>
            <Text style={styles.stat__label}>PASSED</Text>
            <Text style={styles.stat__count}>{ passedCount }</Text>
          </View>
        </View>

        <View style={[styles.stat, styles.stat__failed]}>
          <Entypo style={styles.stat__icon} name="circle-with-cross" size={65} color="white" />
          <View style={styles.stat__info}>
            <Text style={styles.stat__label}>FAILED</Text>
            <Text style={styles.stat__count}>{ failedCount }</Text>
          </View>
        </View>

        <View style={[styles.stat, styles.stat__missing]}>
          <FontAwesome style={styles.stat__icon} name="question-circle" size={65} color="white" />
          <View style={styles.stat__info}>
            <Text style={styles.stat__label}>MISSING</Text>
            <Text style={styles.stat__count}>{ missingCount }</Text>
          </View>
        </View>

        <View style={[styles.stat, styles.stat__total]}>
          <Entypo style={styles.stat__icon} name="list" size={65} color="white" />
          <View style={styles.stat__info}>
            <Text style={styles.stat__label}>TOTAL</Text>
            <Text style={styles.stat__count}>{ totalCount }</Text>
          </View>
        </View>
      </View>

      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              enabled={user.role != 'User'}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); }}>

              <Picker.Item label="All" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

              <Picker.Item label="All" value="" />

              { locations != null && locations.filter(l => 
                (companyId == "" || l.companyId == companyId) &&
                (user.role != 'User' || user.allowedLocationsList.includes(l.id))
              ).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Engineers</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={engineerId}
              onValueChange={(itemValue, itemIndex) => setEngineerId(itemValue)}>

              <Picker.Item label="All" value="" />

              { engineers != null && engineers.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Date Range</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={dateRange}
              onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

              <Picker.Item label="Predefined Date Range" value="" />
              <Picker.Item label="1 Week" value="-1" />
              <Picker.Item label="1 Month" value="1" />
              <Picker.Item label="3 Months" value="3" />
              <Picker.Item label="6 Months" value="6" />
              <Picker.Item label="12 Months" value="12" />
              
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Start Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChange={setStartDate}
              value={startDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>End Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input,]} 
              onChange={setEndDate}
              value={endDate}>
            </DateTimePicker>
          </View>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Serial / Asset #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChangeText={setSerialNumber}
              value={serialNumber}
            />
          </View>
          
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Description</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChangeText={setDescription}
              value={description}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Categories</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={categoryId}
              onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}>

              <Picker.Item label="All" value="" />

              { categories != null && categories.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChangeText={setJobNumber}
              value={jobNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={statusId}
              onValueChange={(itemValue, itemIndex) => setStatusId(itemValue)}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="Failed" value="0" />
              <Picker.Item label="Safe For Use" value="1" />
              <Picker.Item label="Not Seen" value="2" />
            </Picker>
          </View>


          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Report</DataTable.Title>
          <DataTable.Title>Description</DataTable.Title>
          <DataTable.Title>Inspection Date</DataTable.Title>
          <DataTable.Title>Next Inspection Date</DataTable.Title>
          <DataTable.Title>Safe For Use</DataTable.Title>
          <DataTable.Title>Missing</DataTable.Title>
          <DataTable.Title>Serial Number</DataTable.Title>
          <DataTable.Title>Manufacturer</DataTable.Title>
          <DataTable.Title>Category</DataTable.Title>
          <DataTable.Title>Location</DataTable.Title>
          <DataTable.Title>Company</DataTable.Title>
        </DataTable.Header>

        { inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>
                  { element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadRAMS(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity> }
                </View>
              </DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.inspectionDateFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.nextInspectionDateFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.safeForUseFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.missingFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.manufacturerName}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={inspectionResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={inspectionResult.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  stats: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10
  },

    stat: {
      backgroundColor: '#CECECE',
      height: 100,
      borderRadius: 5,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      margin: 5,
    },

      stat__info: {
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFF'
      },

      stat__label: {
        color: '#FFF',
        fontWeight: 'bold'
      },

      stat__count: {
        color: '#FFF',
        fontWeight: 'bold',
        fontSize: 38
      },

      stat__icon: {
        marginLeft: 20
      },

      stat__passed: {
        backgroundColor: '#1ABB9C'
      },

      stat__failed: {
        backgroundColor: '#E74C3C'
      },

      stat__missing: {
        backgroundColor: '#9B59B6'
      },

      stat__total: {
        backgroundColor: '#3498DB'
      }
});
