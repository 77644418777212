import { baseApi, PagedTableDto } from './base-api'

export const jobApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAutoScheduledJobs: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `jobs/getautoscheduledjobs?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getJobs: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `jobs/getjobs?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getJobAssetInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `jobs/getjobassetinspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getJob: build.query<any, any>({
      query: (id) => ({
        url: `jobs/getjob/${id}`,
      }),
    }),
    createJob: build.mutation<any, any>({
      query: (body) => ({
        url: 'jobs/addjob',
        method: 'post',
        body: body
      }),
    }),
    updateJob: build.mutation<any, any>({
      query: (body) => ({
        url: `jobs/updatejob/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    completeJob: build.mutation<any, any>({
      query: (id) => ({
        url: `jobs/completejob/${id}`,
        method: 'post',
      }),
    }),
    approveJob: build.mutation<any, any>({
      query: (id) => ({
        url: `jobs/approvejob/${id}`,
        method: 'post',
      }),
    }),
    deleteJob: build.mutation<any, any>({
      query: (id) => ({
        url: `jobs/deletejob/${id}`,
        method: 'delete',
      }),
    }),
    // Used for tablet
    sendJob: build.mutation<any, any>({
      query: (body) => ({
        url: `jobs/updatejob/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncJobs: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `jobs/getjobsforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    sendJobAssets: build.mutation<any, any>({
      query: (body) => ({
        url: `jobs/updatejobasset/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useGetJobsQuery, useGetJobQuery, useCreateJobMutation, useUpdateJobMutation,
  useCompleteJobMutation, useSendJobMutation, useSendJobAssetsMutation, useDeleteJobMutation, 
  useApproveJobMutation, useGetJobAssetInspectionsQuery,
  useGetAutoScheduledJobsQuery
} = jobApi