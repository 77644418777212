import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Platform } from 'react-native';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, Ionicons, SimpleLineIcons  } from '@expo/vector-icons';
import { useDeleteBreakdownPartMutation, useGetBreakdownPartsListQuery } from '@store/services/api/breakdown-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { useDebounce } from '../../../../../../components/helpers/UseDebounce';
import { useDeleteUserMutation, useGetEngineersQuery, useGetUsersQuery, userApi } from '@store/services/api/user-api';
import { Picker } from '@react-native-picker/picker';


export default function WebUserList({navigation}: {navigation: any}) {
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();
  const isFocused = useIsFocused()

  // Filters 
  const [name, setName] = useState("");
  const [role, setRole] = useState("All");

  const [trigger, usersResult] = userApi.useLazyGetUsersQuery();

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [isFocused]);

  const loadTable = () => {
    var dto = new PagedTableDto(0, { 
      name: name,
      role: role,
    });
    trigger(dto);
  }

  const resetFilters = () => {
    setName("");
    setRole("All");
  }

  const remove = async (entityId) => {
    if (Platform.OS == 'web') {
      if (confirm("Are you sure you want to delete this user?")) {
        var result = await deleteUser(entityId) as any;
        if (result.error) {
          Alert.alert("Error", "Something went wrong. Please try again");
          return;
        }

        loadTable();
      }
    }
  }

  if (usersResult.isLoading || deleteUserResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>  
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Name/Email</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Name"
              onChangeText={setName}
              value={name}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Role</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={role}
              onValueChange={(itemValue, itemIndex) => setRole(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item key={0} label={'Admin'} value={"Admin"} />
              <Picker.Item key={1} label={'Client'} value={"User"} />
              <Picker.Item key={2} label={'Engineer'} value={"Engineer"} />
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

       
      <TouchableOpacity onPress={() => navigation.navigate("manage")}>
        <Text style={GlobalStyle.add__new__link}>Add New User</Text>
      </TouchableOpacity>
      

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>Email</DataTable.Title>
          <DataTable.Title>Role</DataTable.Title>
          <DataTable.Title>Company</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
        </DataTable.Header>

        { usersResult?.data?.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.email}</DataTable.Cell>
              <DataTable.Cell>{element.role}</DataTable.Cell>
              <DataTable.Cell>{element.company}</DataTable.Cell>
           
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { userId: element.id })}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => remove(element.id) } style={{ marginLeft: 20 }}>
                    <Ionicons name="ios-remove-circle" size={24} color="red" />
                  </TouchableOpacity>
                </View>
                
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
