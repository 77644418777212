import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';


import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, SimpleLineIcons } from '@expo/vector-icons';
import { companyApi, useUpdateCompanyCostsMutation } from '@store/services/api/company-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { DataTableHeader } from '@web/components/table/DataTableHeader';

export default function CompaniesListScreen({navigation}: {navigation: any}) {
  const user = useSelector((state: RootState) => state.user.user);

  const isFocused = useIsFocused();
  const [updateCompanyCosts, updateCompanyCostsResult] = useUpdateCompanyCostsMutation();

  const optionsPerPage = [5, 10, 50];
  const [page, setPage] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  // Filters 
  const [companyName, setCompanyName] = useState("");
  const [address1, setAddress1] = useState("");  

  const [trigger, companiesResult] = companyApi.useLazyGetCompaniesQuery();
  
  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending]);

  const loadTable = () => {
    trigger(new PagedTableDto(page, {
      name: companyName, 
      address1: address1,
    }, sortedColumn, sortedColumnAscending));
  }

  const resetFilters = () => {
    setCompanyName("");
    setAddress1("");
  }

  const updateCosts = async (companyId) => {
    var result = await updateCompanyCosts(companyId) as any;
    if (result.error) {
      alert("Error, please contact CoreRFID");
      return;
    }

    alert("Successfully updated costs");
  }

  if (companiesResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company Name</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Company Name"
              onChangeText={setCompanyName}
              value={companyName}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Address 1</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Address 1"
              onChangeText={setAddress1}
              value={address1}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <TouchableOpacity onPress={() => navigation.navigate("manage")}>
        <Text style={GlobalStyle.add__new__link}>Add New Company</Text>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Name', sortedName: "name", visible: true },
            { text: 'Address 1', sortedName: "address1", visible: true },
            { text: 'Address 2', sortedName: "address2", visible: true },
            { text: 'Address 3', sortedName: "address3", visible: true },
            { text: 'Address 4', sortedName: "address4", visible: true },
            { text: 'Postcode', sortedName: "postcode", visible: true },
            { text: 'Phone Number', sortedName: "phoneNumber", visible: true },
            { text: 'Option', visible: true },
            { text: 'Option', visible: user.role != 'User' && user.role != 'Engineer' },
          ]}
        />
        {/* <DataTable.Header>
          <DataTable.Title sortDirection={sortedColumn != 'name' ? null : sortedColumnDirection}>Name</DataTable.Title>
          <DataTable.Title>Address 2</DataTable.Title>
          <DataTable.Title>Address 3</DataTable.Title>
          <DataTable.Title>Address 4</DataTable.Title>
          <DataTable.Title>Postcode</DataTable.Title>
          <DataTable.Title>Phone Number</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
          { user.role != 'User' && user.role != 'Engineer' && <DataTable.Title>Option</DataTable.Title> }
        </DataTable.Header> */}

        { companiesResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.address1}</DataTable.Cell>
              <DataTable.Cell>{element.address2}</DataTable.Cell>
              <DataTable.Cell>{element.address3}</DataTable.Cell>
              <DataTable.Cell>{element.address4}</DataTable.Cell>
              <DataTable.Cell>{element.postcode}</DataTable.Cell>
              <DataTable.Cell>{element.phoneNumber}</DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("manage", { companyId: element.id })}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
              { user.role != 'User' && user.role != 'Engineer' && 
                <DataTable.Cell>
                  <TouchableOpacity onPress={() => updateCosts(element.id)}>
                    <Text>Update Costs</Text>
                  </TouchableOpacity>
                </DataTable.Cell>
              }

            </DataTable.Row>
          )
        })}

        
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={companiesResult?.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={companiesResult?.data?.pagination.pageSize}
        setItemsPerPage={setItemsPerPage}
        showFastPagination
        optionsLabel={'Rows per page'}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
