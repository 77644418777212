import { baseApi, PagedTableDto } from './base-api'

export const defectsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDefect: build.query<any, any>({
      query: (defectId) => ({
        url: `defects/getdefect/${defectId}`,
      }),
    }),
    getDefects: build.query<any, any>({
      query: (categoryId) => ({
        url: `defects/getcategorydefects?categoryId=${categoryId}`,
      }),
    }),
    getDefectsTable: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `defects/getdefectstable?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncDefects: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `defects/getdefectsforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    createDefect: build.mutation<any, any>({
      query: (body) => ({
        url: 'defects/adddefect',
        method: 'post',
        body: body
      }),
    }),
    updateDefect: build.mutation<any, any>({
      query: (body) => ({
        url: `defects/updatedefect/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    deleteDefect: build.mutation<any, any>({
      query: (defectId) => ({
        url: `defects/deletedefect/${defectId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useSyncDefectsMutation,
  useGetDefectsQuery,
  useGetDefectsTableQuery,
  useCreateDefectMutation,
  useUpdateDefectMutation,
  useDeleteDefectMutation
} = defectsApi