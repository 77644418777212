import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, SimpleLineIcons } from '@expo/vector-icons';
import { locationApi, useGetLocationsQuery } from '@store/services/api/location-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import Companies from '../Companies/Companies';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { Picker } from '@react-native-picker/picker';
import { DataTableHeader } from '@web/components/table/DataTableHeader';

export default function LocationsListScreen({navigation}: {navigation: any}) {
  const isFocused = useIsFocused()

  const optionsPerPage = [5, 10, 50];
  const [page, setPage] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  // Filters 
  const { data: companies } = useGetAllCompaniesQuery(null);
  const [locationName, setLocationName] = useState("");
  const [address1, setAddress1] = useState("");
  const [companyId, setCompanyId] = useState("");

  //const { data, error, isLoading, refetch } = useGetLocationsQuery(new PagedTableDto(page, { name: locationName, address1: address1 }));
  const [trigger, locationsResult] = locationApi.useLazyGetLocationsQuery();

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending]);

  const loadTable = () => {
    trigger(new PagedTableDto(page, {
      name: locationName, 
      address1: address1,
      companyId: companyId
    }, sortedColumn, sortedColumnAscending));
  }

  const resetFilters = () => {
    setLocationName("");
    setAddress1("");
    setCompanyId("");
  }

  if (locationsResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
       <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location Name</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Location Name"
              onChangeText={setLocationName}
              value={locationName}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Address 1</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Address 1"
              onChangeText={setAddress1}
              value={address1}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              onValueChange={(itemValue, itemIndex) => setCompanyId(itemValue)}>

              <Picker.Item label="All Companies" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <TouchableOpacity onPress={() => navigation.navigate("manage")}>
        <Text style={GlobalStyle.add__new__link}>Add New Location</Text>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Name', sortedName: "name", visible: true },
            { text: 'Address 1', sortedName: "address1", visible: true },
            { text: 'Address 2', sortedName: "address2", visible: true },
            { text: 'Address 3', sortedName: "address3", visible: true },
            { text: 'Address 4', sortedName: "address4", visible: true },
            { text: 'Postcode', sortedName: "postcode", visible: true },
            { text: 'Company', sortedName: "companyName", visible: true },
            { text: 'Option', visible: true },
          ]}
        />

        {/* <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>Address 1</DataTable.Title>
          <DataTable.Title>Address 2</DataTable.Title>
          <DataTable.Title>Address 3</DataTable.Title>
          <DataTable.Title>Address 4</DataTable.Title>
          <DataTable.Title>Postcode</DataTable.Title>
          <DataTable.Title>Company</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
        </DataTable.Header> */}

        { locationsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.address1}</DataTable.Cell>
              <DataTable.Cell>{element.address2}</DataTable.Cell>
              <DataTable.Cell>{element.address3}</DataTable.Cell>
              <DataTable.Cell>{element.address4}</DataTable.Cell>
              <DataTable.Cell>{element.postcode}</DataTable.Cell>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("manage", { locationId: element.id })}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}

        
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={locationsResult?.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={locationsResult?.data?.pagination.pageSize}
        setItemsPerPage={setItemsPerPage}
        showFastPagination
        optionsLabel={'Rows per page'}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
