import { useIsFocused } from "@react-navigation/native";
import { PagedTableDto } from "@store/services/api/base-api";
import { useGetAllCompaniesQuery } from "@store/services/api/company-api";
import { jobApi } from "@store/services/api/job-api";
import { useGetAllLocationsQuery } from "@store/services/api/location-api";
import { LoadingIndicator } from "@web/components/navigation/controls/LoadingIndicator";
import { useState, useEffect } from "react";
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from "react-native";
import GlobalStyle from "../../../../../../constants/GlobalStyle";
import { Picker } from '@react-native-picker/picker';
import { DataTable } from "react-native-paper";
import DateTimePicker from "@web/components/navigation/controls/DateTimePicker";
import { useGetEngineersQuery } from "@store/services/api/user-api";
import moment from 'moment';
import { SimpleLineIcons } from "@expo/vector-icons";
import Checkbox from "expo-checkbox";

export default function JobAutoScheduledScreen({ navigation }: { navigation: any }) {
  const isFocused = useIsFocused();

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: engineers } = useGetEngineersQuery(null);

  // Filters 
  const [companyId, setCompanyId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().add(3, 'months').toDate());
  const [engineerId, setEngineerId] = useState("");
  const [showAll, setShowAll] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [trigger, jobsResult] = jobApi.useLazyGetAutoScheduledJobsQuery();

  useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused]);

  const loadTable = () => {
    trigger(new PagedTableDto(page, {
      companyId: companyId,
      locationId: locationId,
      jobNumber: jobNumber,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      engineerId: engineerId,
      showAll: showAll,
    }));
  }

  const resetFilters = () => {
    setCompanyId("");
    setLocationId("");
    setJobNumber("");
    setStartDate(moment().toDate());
    setEndDate(moment().add(3, 'months').toDate());
    setEngineerId("");
  }

  if (jobsResult.isLoading) {
    return <LoadingIndicator />
  }


  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={companyId}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); }}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Companies" value="" />

              {companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

              <Picker.Item label="All" value="" />

              {locations != null && locations.filter(l => (companyId == "" || l.companyId == companyId)
              ).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              placeholder="Job #"
              value={jobNumber}
              onChangeText={setJobNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Engineers</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={engineerId}
              onValueChange={(itemValue, itemIndex) => setEngineerId(itemValue)}>

              <Picker.Item label="All" value="" />

              {engineers != null && engineers.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Start Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChange={setStartDate}
              value={startDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>End Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChange={setEndDate}
              value={endDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Show All</Text>
            <Checkbox style={{ marginTop: 10 }} value={showAll} onValueChange={(value) => setShowAll(value)} />
          </View>
          
          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Company</DataTable.Title>
          <DataTable.Title>Location</DataTable.Title>
          <DataTable.Title>Job Number</DataTable.Title>
          <DataTable.Title>Job Type</DataTable.Title>
          <DataTable.Title>Scheduled Date</DataTable.Title>
        </DataTable.Header>

        {jobsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.jobNumber}</DataTable.Cell>
              <DataTable.Cell>{element.jobTypeFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.autoRescheduledDateFormatted}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={jobsResult?.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)}
        //@ts-ignore
        itemsPerPage={jobsResult?.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
