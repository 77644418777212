export class ReportableService {
  //private baseUrl = "http://reportable.dev.corerfid.com";
  private baseUrl = "https://reportable.corerfid.net";

  // public downloadProofLoad = async (inspectionId) => {
  //   const response = await fetch(`${this.baseUrl}/api/portengineering/getproofload?inspectionId=${inspectionId}`, {
  //     method: "GET",
  //   });

  //   if (response.status == 200) {
  //     var data = await response.json();
  //     let blob = this.convertBase64toBlob(data, 'application/pdf');
  //     return blob;
  //   }
  // }

  public downloadAssetRegister = async (locationId, categoryId = null, base64: boolean = false) => {
    var url = `${this.baseUrl}/api/portengineering/getassetregister?locationId=${locationId}&categoryId=${categoryId}`;
    const response = await fetch(url, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadRote = async (inspectionId) => {
    const response = await fetch(`${this.baseUrl}/api/portengineering/getliftingexam?inspectionId=${inspectionId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  // public downloadService = async (inspectionId) => {
  //   const response = await fetch(`${this.baseUrl}/api/portengineering/getservice?inspectionId=${inspectionId}`, {
  //     method: "GET",
  //   });

  //   if (response.status == 200) {
  //     var data = await response.json();
  //     let blob = this.convertBase64toBlob(data, 'application/pdf');
  //     return blob;
  //   }
  // }

  public downloadRAMS = async (ramsId) => {
    console.log("LOADING RAMS");
    const response = await fetch(`${this.baseUrl}/api/portengineering/getrams?ramsId=${ramsId}`, {
      method: "GET",
    });

    console.log(response);

    if (response.status == 200) {
      var data = await response.json();
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }


  private convertBase64toBlob = (content, contentType) => {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
