import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Foundation } from '@expo/vector-icons';

export const PdfKey = ({ state }) => {
  // State
  // 0 = InDate
  // 1 = Due in 28
  // 2 = Overdue

  const getColour = () => {
    if (state == 0)
      return "green";
    else if (state == 1)
      return "orange";
    else if (state == 2)
      return "red";
  }

  return (
    <Foundation name="page-pdf" size={24} color={getColour()} />
  )
};

export const ReportKey = ({ }) => {
  return (
    <View style={styles.report__key__container}>
      <View style={styles.key}>
        <Foundation name="page-pdf" size={24} color="green" />
        <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>In Date</Text>
      </View>
      

      <View style={styles.key}>
        <Foundation name="page-pdf" size={24} color="orange" />
        <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>Due in 28 Days</Text>
      </View>

      <View style={styles.key}>
        <Foundation name="page-pdf" size={24} color="red" />
        <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>Overdue</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  report__key__container: {
    marginBottom: 10,
    alignItems: 'flex-start',

    flexDirection: 'row'
  },

  key: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: '#ebe8e8',
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,

    marginRight: 10
  }
});