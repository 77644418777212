import { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Modal, Image, TouchableOpacity } from 'react-native';
import GlobalStyle from '../../../constants/GlobalStyle';
import { Entypo } from '@expo/vector-icons';
import { inspectionApi, useGetInspectionAuditsQuery } from '@store/services/api/inspection-api';
import { DataTable } from 'react-native-paper';
import { DataTableHeader } from './table/DataTableHeader';

export const AuditViewer = ({ inspectionId, onClose }) => {

  const { data: audits } = useGetInspectionAuditsQuery(inspectionId);

  return (
    <View style={styles.container}>
      <Modal
        animationType="fade"
        transparent={true}>
        <View style={styles.modalContainer}>
          <View style={styles.viewerContainer}>
            <View style={GlobalStyle.filters}>
              <View style={[GlobalStyle.filters__header, { flexDirection: 'row', alignItems: 'center' }]}>
                <Text style={[GlobalStyle.filters__header__text, { flex: 1 }]}>Audit Viewer</Text>
                <TouchableOpacity style={{ paddingRight: 10 }} onPress={() => onClose()}>
                  <Entypo name="circle-with-cross" size={22} color="white" />
                </TouchableOpacity>
              </View>
            </View>

        
            <DataTable style={[GlobalStyle.table, { flex: 1, overflow: 'scroll' }]}>
              <DataTableHeader
                headers={[
                  { text: 'User', sortedName: "name", visible: true },
                  { text: 'Viewed At', sortedName: "description", visible: true },
                ]}
              />

              { audits?.map((element: any) => {
                return (
                  <DataTable.Row key={element.viewedAtUnix}>
                    <DataTable.Cell>{element.name}</DataTable.Cell>
                    <DataTable.Cell>{element.viewedAtFormatted}</DataTable.Cell>
                  </DataTable.Row>
                )
              })}
            </DataTable>
          </View>
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',

    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999999,
  },

  centeredContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },

  viewerContainer: {
    backgroundColor: '#FFF',
    width: 850,
    height: 600,

    flexDirection: 'column',

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
})