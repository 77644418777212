import { EvilIcons, Foundation, MaterialIcons, SimpleLineIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { PagedTableDto } from '@store/services/api/base-api';
import { jobApi } from '@store/services/api/job-api';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';

export default function JobApprovalAssetsScreen({ navigation, route }: { navigation: any, route: any }) {
  const isFocused = useIsFocused();

  // Filters 
  const [serialNumber, setSerialNumber] = useState("");

  const [page, setPage] = React.useState<number>(0);
  const [trigger, jobsResult] = jobApi.useLazyGetJobAssetInspectionsQuery();

  React.useEffect(() => {
    if (isFocused && route.params?.jobId != null) {
      loadTable();
    }
  }, [page, isFocused]);

  const loadTable = () => {
    trigger(new PagedTableDto(page, {
      jobId: route.params?.jobId, 
      serialNumber: serialNumber
    }));
  }

  const resetFilters = () => {
    setSerialNumber("");
  }

  const downloadCert = async (inspection) => {
    //inspection.isLoading = true;
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRote(inspection.id);
    //inspection.isLoading = false;
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  if (jobsResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.navigate("job-approval") }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{"JOB APPROVAL"}</Text>
      </View>

      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Serial Number</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Serial #"
              value={serialNumber}
              onChangeText={setSerialNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}></View>
          <View style={GlobalStyle.column__flex}></View>
          <View style={GlobalStyle.column__flex}></View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        
      </View>
      
      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Report</DataTable.Title>
          <DataTable.Title>Serial Number</DataTable.Title>
          <DataTable.Title>Description</DataTable.Title>
          <DataTable.Title>Category Name</DataTable.Title>
          <DataTable.Title>Inspection Date</DataTable.Title>
          <DataTable.Title>Safe For Use</DataTable.Title>
          <DataTable.Title>Amend</DataTable.Title>
        </DataTable.Header>

        { jobsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id} style={{ backgroundColor: element.isAmended ? '#FFE599' : '#FFF' }}>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>
                </View>
              </DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.inspectionDateFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.safeForUseFormatted}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => navigation.navigate("job-inspect", { equipmentId: element.equipmentId, inspectionId: element.id, jobId: route.params?.jobId, categoryId: element.categoryId })}>
                    <MaterialIcons name="approval" size={24} color="black" />
                  </TouchableOpacity>
                </View>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={jobsResult?.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={jobsResult?.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
