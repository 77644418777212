import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, SimpleLineIcons } from '@expo/vector-icons';
import { PagedTableDto } from '@store/services/api/base-api';
import { categoryApi, useGetAllMasterCategoriesQuery, useGetCategoriesQuery } from '@store/services/api/category-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { Picker } from '@react-native-picker/picker';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { DataTableHeader } from '@web/components/table/DataTableHeader';

export default function CategoriesListScreen({navigation}: {navigation: any}) {
  const user = useSelector((state: RootState) => state.user.user);
  const isFocused = useIsFocused()

  const optionsPerPage = [5, 10, 50];
  const [page, setPage] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const { data: masterCategories } = useGetAllMasterCategoriesQuery(null);

  // Filters 
  const [categoryName, setCategoryName] = useState("");
  const [masterCategoryId, setMasterCategoryId] = useState("");
  const [statusId, setStatusId] = useState(0);

  const [trigger, categoriesResult] = categoryApi.useLazyGetCategoriesQuery();

  const loadTable = () => {
    var dto = new PagedTableDto(page, { 
      name: categoryName,
      masterCategoryId: masterCategoryId,
      statusId: statusId
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const resetFilters = () => {
    setCategoryName("");
    setMasterCategoryId("");
  }

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending]);

  if (categoriesResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Category Name</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Category Name"
              onChangeText={setCategoryName}
              value={categoryName}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Master Category</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={masterCategoryId}
              onValueChange={(itemValue, itemIndex) => setMasterCategoryId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Master Categories" value="" />

              { masterCategories != null && masterCategories.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={statusId}
              onValueChange={(itemValue, itemIndex) => setStatusId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="Active" value="0" />
              <Picker.Item label="Archived" value="1" />
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <TouchableOpacity onPress={() => navigation.navigate("manage")}>
        <Text style={GlobalStyle.add__new__link}>Add New Category</Text>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Name', sortedName: "name", visible: true },
            { text: 'Inspection Interval', sortedName: "inspectionInterval", visible: true },
            { text: 'Master Category', sortedName: "masterCategory", visible: true },
            { text: 'Cost', sortedName: "cost", visible: user.role != 'User' && user.role != 'Engineer' },
            { text: 'Option', visible: true },
          ]}
        />
        {/* <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>Inspection Interval</DataTable.Title>
          <DataTable.Title>Master Category</DataTable.Title>
          { user.role != 'User' && user.role != 'Engineer' && <DataTable.Title>Cost</DataTable.Title> }
          <DataTable.Title>Option</DataTable.Title>
        </DataTable.Header> */}

        { categoriesResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.inspectionInterval}</DataTable.Cell>
              <DataTable.Cell>{element.masterCategoryName}</DataTable.Cell>
              { user.role != 'User' && user.role != 'Engineer' && <DataTable.Cell>£{element.cost?.toFixed(2)}</DataTable.Cell> }
              <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("manage", { categoryId: element.id })}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={categoriesResult?.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={categoriesResult?.data?.pagination.pageSize}
        setItemsPerPage={setItemsPerPage}
        showFastPagination
        optionsLabel={'Rows per page'}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
