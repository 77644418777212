import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';

export class InspectionDatabase extends Database {
  TABLE_NAME = "Inspections";
  TABLE_NAME_INSPECTION_DEFECTS = "InspectionDefects";
  TABLE_NAME_IMAGES = "InspectionImages";

  migrateColumn(columnName, columnType) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        try {
          tx.executeSql(`ALTER TABLE InspectionImages ADD COLUMN ${columnName} ${columnType}`);
        } catch {  }
      }, (error) => {
        resolve();
      }, () => {
        resolve();
      })
    });
  }

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Inspections ( 
        id TEXT PRIMARY KEY,
        inspectionDate TEXT,
        nextInspectionDate TEXT,

        firstExamination INT,
        installedCorrectly INT,

        identification TEXT,
        particulars TEXT,
        safeForUse INT,

        equipmentId TEXT,
        engineerId TEXT,
        jobId TEXT,
        purposeId TEXT,
        colourId TEXT,
        missing INT,

        ramsId TEXT
      )`;

      var sqlInspectionDefects = `CREATE TABLE IF NOT EXISTS InspectionDefects (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        defectGroup TEXT,
        defect TEXT,
        timeToRectifyInWeeks INT
      )`

      var sqlImages = `CREATE TABLE IF NOT EXISTS InspectionImages (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        imageName TEXT,
        base64 TEXT
      )`;

      var sqlInspectionQuestions = `CREATE TABLE IF NOT EXISTS InspectionQuestions (
        id TEXT PRIMARY KEY,
        title TEXT,
        question TEXT,
        dropdownOptions TEXT,
        categoryId TEXT,
        position INT,
        keyboardType TEXT
      )`;

      var sqlInspectionAnsweredQuestions = `CREATE TABLE IF NOT EXISTS InspectionAnsweredQuestions (
        id TEXT PRIMARY KEY,
        inspectionQuestionId TEXT,
        question TEXT,
        response TEXT,
        position INT,
        inspectionId TEXT
      )`;

      Database.getDb().transaction(async (tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlInspectionDefects);
        tx.executeSql(sqlImages);
        tx.executeSql(sqlInspectionAnsweredQuestions);
        tx.executeSql(sqlInspectionQuestions);

        await this.migrateColumn("imageName", "TEXT");
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  addMissingInspection(inspectionDate, equipmentId, engineerId, jobId, purposeId, colourId) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (
            id, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, purposeId, colourId, missing
          )
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, 1)
        `

        var inspectionId = uuid.v4();
        tx.executeSql(sql, [
          inspectionId, inspectionDate, inspectionDate, false, equipmentId, engineerId, jobId, purposeId, colourId
        ]);

        sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
        tx.executeSql(sql, [jobId, equipmentId]);
      }, (error) => {
        console.log("ERROR");
        console.log(error);
        reject();
      }, () => {
        console.log("SUCCESS");
        resolve();
      })
    });
  }

  addInspection(inspectionDate: any, nextInspectionDate: any, firstExamination: any, installedCorrectly: any, defects: any, identification: any, particulars: any,
    safeForUse: any, equipmentId: any, engineerId: any, jobId: any, ramsId: any, purposeId: any, colourId: any, missing: any, images: any, questions: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (
            id, inspectionDate, nextInspectionDate, firstExamination, installedCorrectly, identification, particulars, safeForUse, 
            equipmentId, engineerId, jobId, ramsId, purposeId, colourId, missing
          )
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
        `

        var inspectionId = uuid.v4();

        tx.executeSql(sql, [
          inspectionId, inspectionDate, nextInspectionDate, firstExamination, installedCorrectly, identification, 
          particulars, safeForUse, equipmentId, engineerId, jobId, ramsId, purposeId, colourId, missing
        ]);

        if (defects.length > 0) {
          sql = `INSERT INTO ${this.TABLE_NAME_INSPECTION_DEFECTS} (id, inspectionId, defectGroup, defect, timeToRectifyInWeeks) VALUES (?, ?, ?, ?, ?)`;
          for (let defect of defects) {
            var group = "";
            if (defect.defectGroup == 0) group = "A";
            if (defect.defectGroup == 1) group = "B";
            if (defect.defectGroup == 2) group = "C";

            tx.executeSql(sql, [uuid.v4(), inspectionId, group, defect.name, defect.timeToRectify]);
          }
        }

        if (questions != null && questions.length > 0) {
          sql = `INSERT INTO InspectionAnsweredQuestions (id, inspectionQuestionId, question, response, position, inspectionId) VALUES (?, ?, ?, ?, ?, ?)`;
          for (let question of questions) {
            tx.executeSql(sql, [uuid.v4(), question.id, question.question, question.response, question.position, inspectionId]);
          }
        }

        if (images.length > 0) {
          sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, imageName, base64) VALUES (?, ?, ?, ?)`;
          for (let image of images) {
            tx.executeSql(sql, [uuid.v4(), inspectionId, image.name, image.base64]);
          }
        }

        //Update this asset to be completed 
        sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
        tx.executeSql(sql, [jobId, equipmentId]);

        console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
      }, (error) => {
        console.log("ERROR");
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  deleteInspection(inspectionId) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `DELETE FROM Inspections WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => {});

        sql = `DELETE FROM InspectionImages WHERE inspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => {});

        sql = `DELETE FROM InspectionAnsweredQuestions WHERE inspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => {});
        
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Inspections
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
          for (let inspection of data) {
            // Make sure our dates our YYYY/MM/DD
            inspection.inspectionDate = Database.formatDate(inspection.inspectionDate);
            inspection.nextInspectionDate = Database.formatDate(inspection.nextInspectionDate);
            sql = `SELECT * FROM ${this.TABLE_NAME_INSPECTION_DEFECTS} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.inspectionDefects = [];
              for (let defect of _array) {
                inspection.inspectionDefects.push(defect)
              }
            });

            sql = `SELECT * FROM InspectionAnsweredQuestions WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.questions = [];
              for (let question of _array) {
                inspection.questions.push(question)
              }
            });

            sql = `SELECT imageName, base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.images = [];
              for (let image of _array) {
                inspection.images.push({
                  base64: image.base64,
                  imageName: image.imageName
                })
              }
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getInspectionQuestions(categoryId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM InspectionQuestions WHERE categoryId = ?
        `;

        tx.executeSql(sql, [categoryId], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  insertOrUpdateQuestionsList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO InspectionQuestions (id, title, question, dropdownOptions, categoryId, position, keyboardType)
            VALUES (?, ?, ?, ?, ?, ?, ?)
          `

          if (entity.isDeleted || entity.isDeleted == 1) {
            sql = "DELETE FROM InspectionQuestions WHERE id = ?";
            tx.executeSql(sql, [entity.id]);
            continue;
          }

          var sql = `
            INSERT OR REPLACE INTO InspectionQuestions (id, title, question, dropdownOptions, categoryId, position, keyboardType)
            VALUES (?, ?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.title, entity.question, entity.dropdownOptions, entity.categoryId, entity.position, entity.keyboardType
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }
}