
import React, { useState, useEffect } from "react";
import { createElement } from "react-native";

export default function DateTimePicker({ value, onChange, style }: Props) {
  const [date, setDate] = useState(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  return createElement('input', {
    type: 'date',
    value: date.toISOString().split("T")[0],
    onInput: onChange,
    style: style,

    onChange: (event) => {
      setDate(new Date(event.target.value));
      onChange(new Date(event.target.value));
    },
  })
}