import * as React from 'react';
import { useGetAllCategoriesQuery } from "@store/services/api/category-api";
import { defectsApi, useCreateDefectMutation, useUpdateDefectMutation } from "@store/services/api/defects-api";
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { LoadingIndicator } from "@web/components/navigation/controls/LoadingIndicator";

import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';

export default function DefectManageScreen({navigation, route}: {navigation: any, route: any}) {
  const [createDefect, createDefectResult] = useCreateDefectMutation();
  const [updateDefect, updateDefectResult] = useUpdateDefectMutation();

  const [trigger, defectResult] = defectsApi.useLazyGetDefectQuery();
  const { data: categories } = useGetAllCategoriesQuery(null);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: "",
      timeToRectify: 0,
      categoryId: null,
      defectGroup: null,
      position: 0,
      approved: true
    }
  });

  const defectGroup = watch("defectGroup");

  React.useEffect(() => {
    if (route.params?.defectId != null) {
      trigger(route.params?.defectId);
    }
  }, [])

  React.useEffect(() => {
    if (defectResult.isSuccess) {
      var data = defectResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
      setValue("timeToRectify", data.timeToRectify);
      setValue("categoryId", data.categoryId);
      setValue("defectGroup", data.defectGroup);
      setValue("position", data.position);
      setValue("approved", data.approved);
    }
  }, [defectResult]);

  const onSubmit = async (data) => {
    var result = null;

    if (data.id != null) {
      result = await updateDefect(data) as any;
    } else {
      result = await createDefect(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createDefectResult.isLoading || updateDefectResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("name") == null ? "CREATE DEFECT" : "UPDATE DEFECT"}</Text>
      </View>

      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Defect Name (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="categoryId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Category (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) =>
                      onChange(itemValue)
                    }
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { categories != null && categories.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.categoryId && <Text style={GlobalStyle.form__input__validation}>Category is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="defectGroup"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Group (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) =>
                      onChange(parseInt(itemValue))
                    }
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />
                          <Picker.Item label="A" value={0} />
                          <Picker.Item label="B" value={1} />
                          <Picker.Item label="C" value={2} />
                    
                  </Picker>
                </View>
              )}
            />

            {errors.defectGroup && <Text style={GlobalStyle.form__input__validation}>Group is required.</Text>}
          </View>
        </View>

        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="position"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Position (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value.toString()}
                    keyboardType={'decimal-pad'}>
                  </TextInput>
                </View>
              )}
            />

            {errors.position && <Text style={GlobalStyle.form__input__validation}>Position is required.</Text>}
          </View>

          {defectGroup == "1" && 
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="timeToRectify"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Time To Rectify (in weeks) (*)</Text>
                    <TextInput 
                      style={GlobalStyle.form__column__input} 
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value.toString()}
                      keyboardType={'decimal-pad'}>
                    </TextInput>
                  </View>
                )}
              />

              {errors.position && <Text style={GlobalStyle.form__input__validation}>Time To Rectify is required.</Text>}
            </View>
          }
        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
