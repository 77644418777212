import { View, Text, StyleSheet, Image } from "react-native";
import { MaterialCommunityIcons, AntDesign, EvilIcons, MaterialIcons, FontAwesome, Feather  } from '@expo/vector-icons';
import { useSelector } from "react-redux";
import { RootState } from "@store/index";

export const DrawerContent = (props) => {
  const user = useSelector((state: RootState) => state.user.user);

  return (
    <View style={styles.navigation}>
      <Image style={styles.logo} source={require("../../../../assets/images/main logo.png")}/>
      
      <Text style={[styles.link, styles.link__border__top]} onPress={() => props.navigation.navigate("dashboard")}>
        <MaterialCommunityIcons style={styles.link__icon} name="monitor-dashboard" size={18} color="black" />
        Dashboard
      </Text>

      <Text style={styles.title}>Reports</Text>
      <View>
        <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("reports-asset-register")}>
          <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
          Asset Register
        </Text>
      </View>
      <View>
        <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("reports-inspections")}>
          <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
          Inspections
        </Text>
      </View>
      { user.role != 'User' && user.role != 'Engineer' &&
        <View>
          <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("reports-inspection-costs")}>
            <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
            Inspection Costs
          </Text>
        </View>
      }
      <View>
        <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("reports-defects")}>
          <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
          Defects
        </Text>
      </View>
      {/* <View>
        <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("reports-breakdowns")}>
          <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
          Breakdowns
        </Text>
      </View>
      <View>
        <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("reports-servicing")}>
          <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
          Servicing
        </Text>
      </View> */}
      {/* <View>
        <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("reports-erstr")}>
          <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
          ERSTR
        </Text>
      </View> */}
      <View>
        <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("reports-rams")}>
          <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
          RAMS
        </Text>
      </View>
      {/* <View>
        <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("reports-proofloads")}>
          <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
          Proof Loads
        </Text>
      </View> */}
      { user.role != 'User' &&
        <View>
          <Text style={styles.title}>Administration</Text>
          <View>
            <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("job-management")}>
              <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
              Job Management
            </Text>
            <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("job-approval")}>
              <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
              Job Approval
            </Text>
            <Text style={[styles.link, styles.sub__menu]}  onPress={() => props.navigation.navigate("job-auto-scheduled")}>
              <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
              Auto Scheduled Jobs
            </Text>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-companies")}>
              <AntDesign style={styles.link__icon} name="iconfontdesktop" size={18} color="black" />
              Company Management
            </Text>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-locations")}>
              <EvilIcons style={styles.link__icon} name="location" size={18} color="black" />
              Location Management
            </Text>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-categories")}>
              <AntDesign style={styles.link__icon} name="folder1" size={18} color="black" />
              Category Management
            </Text>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-manufacturers")}>
              <FontAwesome style={styles.link__icon} name="certificate" size={18} color="black" />
              Manufacturers Management
            </Text>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-equipment")}>
              <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
              Equipment Management
            </Text>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-defects")}>
              <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
              Defect Management
            </Text>
          </View>
          <Text style={styles.title}>Users</Text>
          <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("administration-web-users")}>
          <Feather style={styles.link__icon} name="users" size={18} color="black" />
            Users
          </Text>

        </View>
      }
    </View>
    // <DrawerContentScrollView {...props}>
    //   <DrawerItemList {...props} />
    // </DrawerContentScrollView>
  );
}


const styles = StyleSheet.create({
  navigation: {
    
  },

  sub__menu: {
    paddingLeft: 20,
  },

  title: {
    fontFamily: 'OpenSans-ExtraBold',
    padding: 10,
  },

  link: {
    padding: 10,
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'OpenSans-Regular'
  },

    link__border__top: {
      borderBottomColor: '#CECECE',
      borderTopWidth: 1,
    },

  link__icon: {
    paddingRight: 10,
  },



  logo: {
    width: '100%',
    height: 60,
    resizeMode: 'contain',
    marginTop: 10,
    marginBottom: 10,
  },
});