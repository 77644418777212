import { EvilIcons } from '@expo/vector-icons';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
//@ts-ignore
import { RootState, store } from '@store/';
import { categoryApi } from '@store/services/api/category-api';
import { defectsApi } from '@store/services/api/defects-api';
import { inspectionApi } from '@store/services/api/inspection-api';
import { jobApi } from '@store/services/api/job-api';
import { setJobData } from '@store/slices/job-slice';
import { setInspectionData } from '@store/slices/rote-inspection-slice';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import React, { useEffect, useRef, useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import RoteStep1 from '../../../../../../components/management/Inspection/Rote/Step1';
import RoteStep2 from '../../../../../../components/management/Inspection/Rote/Step2';
import RoteStep3 from '../../../../../../components/management/Inspection/Rote/Step3';
import RoteStep4 from '../../../../../../components/management/Inspection/Rote/Step4';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
//import JobAssetList from './JobAssetList';

const Stack = createNativeStackNavigator();

export default function JobInspect({ navigation, route }: { navigation: any, route: any }) {
  const dispatch = useDispatch();

  const [jobTrigger, jobResult] = jobApi.useLazyGetJobQuery(); 
  const [inspectionTrigger, inspectionResult] = inspectionApi.useLazyGetInspectionQuery(); 
  const [categoryTrigger, categoryResult] = categoryApi.useLazyGetCategoryQuery();
  const [defectsTrigger, defectsResult] = defectsApi.useLazyGetDefectsQuery();
  const [questionsTrigger, questionsResult] = inspectionApi.useLazyGetCategoryInspectionQuestionsQuery();

  const inspection = useSelector((state: RootState) => state.roteInspection);
  
  const [dbInspection, setDbInspection] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    // Load the job
    if (route.params?.jobId && route.params?.inspectionId) {
      jobTrigger(route.params?.jobId);
      inspectionTrigger(route.params?.inspectionId);
    }

    if (route.params?.categoryId) {
      categoryTrigger(route.params?.categoryId);
      questionsTrigger(route.params?.categoryId);
    }
  }, []);

  // We want to load the defects after the inspection is loaded to ensure the defects are correctly added server side
  useEffect(() => {
    if (inspectionResult.isSuccess) {
      defectsTrigger(route.params?.categoryId);
    }
  }, [inspectionResult])

  useEffect(() => {
    if (jobResult.isSuccess && inspectionResult.isSuccess && defectsResult.isSuccess && questionsResult.isSuccess && categoryResult.isSuccess) {
      setCategory(categoryResult.data);

      var dbInspection = inspectionResult.data;;
      setDbInspection(dbInspection);

      var questions = JSON.parse(JSON.stringify(questionsResult.data)).sort((a, b) => a.postiion - b.position);
      var questionGroups = [];
      for (let question of questions) {
        var group = questionGroups.find(q => q.title == question.title);
        if (group == null) {
          group = { title: question.title, questions: [] }
          questionGroups.push(group);
        }
        
        // Check to see if this question has already been answered
        var answeredQuestion = dbInspection.inspectionAnsweredQuestions.find(i => i.inspectionQuestionId == question.id);
        if (answeredQuestion != null) {
          question.response = answeredQuestion.response;
        }

        group.questions.push(question);
      }

      // Sort
      for (var group of questionGroups) {
        group.questions = group.questions.sort((a, b) => a.position - b.position);
      }

      var defects = JSON.parse(JSON.stringify(defectsResult.data));

      // Set defects 
      for (let defect of dbInspection.inspectionDefects) {
        var dbDefect = defects.find(d => d.groupFormatted == defect.defectGroup && d.name == defect.defect);
        if (dbDefect != null) {
          dbDefect.isSelected = dbDefect != null;
          if (dbDefect.isSelected) { dbDefect.inspectionDefectId = defect.id; }
        } else {
          console.log(defect);
        }
      }
      
      // Need to manually set our inspection data
      dispatch(setInspectionData({
        id: dbInspection.id,
        firstExamination: dbInspection.firstExamination, 
        installedCorrectly: dbInspection.installedCorrectly, 
        defects: defects,
        questions: questionGroups,
        identification: dbInspection.identification ?? "",
        particulars: dbInspection.particulars ?? "",
        safeForUse: dbInspection.safeForUse,
      }));
    }
  }, [jobResult, inspectionResult, defectsResult, questionsResult]);


  const onSubmit = async () => {
    var inspectionToEdit = JSON.parse(JSON.stringify(dbInspection));

    const getDefectGroup = (group) => {
      switch (group) {
        case 0: return 'A';
        case 1: return 'B';
        case 2: return 'C';
        default: return group;
      }
    }

    inspectionToEdit.inspectionDefects = inspection.defects.filter(d => d.isSelected == true).map(d => ({
      id: d.inspectionDefectId,
      defectGroup: getDefectGroup(d.defectGroup),
      defect: d.name,
      timeToRectify: d.timeToRectify,
      timeToRectifyInWeeks: d.timeToRectify
    }))
    inspectionToEdit.firstExamination = inspection.firstExamination;
    inspectionToEdit.installedCorrectly = inspection.installedCorrectly;
    
    // Remap questions
    var questions = [];
    for (var group of inspection.questions) {
      for (var question of group.questions) {
        questions.push({
          id: question.id,
          question: question.question,
          response: question.response,
          position: question.position,
          inspectionQuestionId: question.id
        })
      }
    }

    inspectionToEdit.questions = questions;
    //inspectionToEdit.inspectionAnsweredQuestions = inspection.inspectionAnsweredQuestions;

    inspectionToEdit.identification = inspection.identification;
    inspectionToEdit.particulars = inspection.particulars;
    inspectionToEdit.safeForUse = inspection.safeForUse;

    var result = await store.dispatch(inspectionApi.endpoints.updateInspection.initiate(inspectionToEdit)) as any;
    // console.log(result);
    goBack();
  }
  
  const goBack = () => {
    navigation.navigate("job-approval-assets", { jobId: route.params?.jobId })
  }

  if (inspection == null) {
    return <LoadingIndicator/>
  }

  return (
    <ScrollView>
      <View>
        <View style={GlobalStyle.sub__title}>
          <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => goBack() }>
            <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
          </TouchableOpacity>
          <Text style={GlobalStyle.sub__title__text}>Inspection</Text>
        </View>

        <RoteStep1 navigation={navigation} />
        <RoteStep2 navigation={navigation} route={route} />
        <RoteStep3 navigation={navigation} />
        <RoteStep4 navigation={navigation} category={category} submitHandler={onSubmit}/>
      </View>
    </ScrollView>
    // <Stack.Navigator>
    //   {/* <Stack.Screen name="list" component={JobAssetList} options={{ headerShown: false, title: 'Job Assets'  }} /> */}
    // </Stack.Navigator>
  )
};