import * as React from 'react';
import { useState } from 'react';
import { useIsFocused } from "@react-navigation/native";
import { PagedTableDto } from "@store/services/api/base-api";
import { useGetAllCategoriesQuery } from "@store/services/api/category-api";
import { defectsApi, useDeleteDefectMutation, useUpdateDefectMutation } from "@store/services/api/defects-api";
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Platform, Alert } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { Entypo, SimpleLineIcons } from '@expo/vector-icons';
import { DataTable } from 'react-native-paper';

import GlobalStyle from '../../../../../../constants/GlobalStyle';
import { DataTableHeader } from '@web/components/table/DataTableHeader';

export default function DefectsListScreen({navigation}: {navigation: any}) {
  const [deleteDefect, deleteDefectResult] = useDeleteDefectMutation();
  const [updateDefect, updateDefectResult] = useUpdateDefectMutation();

  const isFocused = useIsFocused();

  const optionsPerPage = [5, 10, 50];
  const [page, setPage] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const [sortedColumn, setSortedColumn] = React.useState<string>("name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const { data: categories } = useGetAllCategoriesQuery(null);
  const [categoryId, setCategoryId] = useState("");
  const [defectName, setDefectName] = useState("");
  const [status, setStatus] = useState(-1);

  const [trigger, defectsResult] = defectsApi.useLazyGetDefectsTableQuery();

  const loadTable = () => {
    var dto = new PagedTableDto(page, { 
      name: defectName,
      categoryId: categoryId,
      status: status,
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const resetFilters = () => {
    setDefectName("");
    setCategoryId("");
  }

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending]);

  const remove = async (entityId) => {
    if (Platform.OS == 'web') {
      if (confirm("Are you sure you want to delete this defect?")) {
        var result = await deleteDefect(entityId) as any;
        if (result.error) {
          alert("Something went wrong. Please try again");
          return;
        }

        loadTable();
      }
    }
  }

  const approve = async (defect) => {
    
    if (Platform.OS == 'web') {
      if (confirm("Are you sure you want to approve this defect?")) {
        var defectToUpdate = JSON.parse(JSON.stringify(defect));
        defectToUpdate.approved = true;
        console.log(defectToUpdate);
        var result = await updateDefect(defectToUpdate) as any;
        if (result.error) {
          alert("Something went wrong. Please try again");
          return;
        }

        loadTable();
      }
    }
  }

  if (defectsResult.isLoading || deleteDefectResult.isLoading || updateDefectResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Defect Name</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Defect Name"
              onChangeText={setDefectName}
              value={defectName}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Category</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={categoryId}
              onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All" value="" />

              { categories != null && categories.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={status}
              onValueChange={(itemValue, itemIndex) => setStatus(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="Approved" value="1" />
              <Picker.Item label="Pending" value="0" />
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>

          <TouchableOpacity onPress={() => navigation.navigate("manage")}>
            <Text style={GlobalStyle.add__new__link}>Add New Defect</Text>
          </TouchableOpacity>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
        {/* <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>Time To Rectify (in weeks)</DataTable.Title>
          <DataTable.Title>Category</DataTable.Title>
          <DataTable.Title>Group</DataTable.Title>
          <DataTable.Title>Position</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
          <DataTable.Title>Approve</DataTable.Title>
        </DataTable.Header> */}

        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Name', sortedName: "name", visible: true },
            { text: 'Time To Rectify (in weeks)', sortedName: "timeToRectify", visible: true },
            { text: 'Category', sortedName: "categoryName", visible: true },
            { text: 'Group', sortedName: "groupFormatted", visible: true },
            { text: 'Position', sortedName: "position", visible: true },
            { text: 'Option', visible: true },
            { text: 'Approve', visible: true },
          ]}
        />

        { defectsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.timeToRectify}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.groupFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.position}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ display: 'flex', flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { defectId: element.id })}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => remove(element.id)} style={{ marginLeft: 50 }}>
                    <SimpleLineIcons name="trash" size={18} color="black" />
                  </TouchableOpacity>
                </View>
              </DataTable.Cell>
              <DataTable.Cell>
                { !element.approved && 
                  <TouchableOpacity onPress={() => approve(element)}>
                    <Entypo name="check" size={24} color="green" />
                  </TouchableOpacity>
                }
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
          </DataTable>
          <DataTable.Pagination
              page={page}
              numberOfPages={defectsResult?.data?.pagination.totalPages}
              onPageChange={(page) => setPage(page)}
              //@ts-ignore
              itemsPerPage={defectsResult?.data?.pagination.pageSize}
              setItemsPerPage={setItemsPerPage}
              showFastPagination
              optionsLabel={'Rows per page'}
          />
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
