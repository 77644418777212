import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Platform, Modal, TextInput } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { TitleBar } from '../../../../screens/app/components/TitleBar';
import { addDefectData, setInspectionData, updateDefectData } from '@store/slices/rote-inspection-slice';
import config, { colours } from '../../../../config';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { DefectsDatabase } from '@store/services/database/defects-database';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../constants/GlobalStyle';
import { Picker } from '@react-native-picker/picker';
import { Entypo } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import uuid from 'react-native-uuid';
import { equipmentApi } from '@store/services/api/equipment-api';
import { RootState, store } from '@store/index';
import { defectsApi } from '@store/services/api/defects-api';

export function AddNewDefect({ equipmentId, defectGroup, position, onCancel, onSaved, defectToEdit }: { equipmentId: any, defectGroup: any, position: any, onCancel: any, onSaved: any, defectToEdit: any }) {
  const dispatch = useDispatch();
  const job = useSelector((state: RootState) => state.job);
  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: defectToEdit?.id ?? uuid.v4(),
      name: defectToEdit?.name ?? null,
      timeToRectify: defectToEdit?.timeToRectify ?? 0,
      timeToRectifyInWeeks: defectToEdit?.timeToRectify ?? 0,
      defectGroup: defectToEdit?.defectGroup ?? defectGroup,
      categoryId: defectToEdit?.categoryId ?? null,
      approved: defectToEdit?.approved ?? 0,
      position: defectToEdit?.postiion ?? position,
      toBeSynced: true,
      isSelected: true
    }
  });


  const submit = async (data) => {
    if (Platform.OS == "web") {
      // Get our equipment
      var result = await store.dispatch(equipmentApi.endpoints.getEquipment.initiate(equipmentId)) as any;
      var equipment = result.data;

      data.categoryId = equipment.categoryId;
      data.timeToRectifyInWeeks = data.timeToRectify;

      var defectResult = await store.dispatch(defectsApi.endpoints.createDefect.initiate(data)) as any;
    } else {
      var equipmentDatabase = new EquipmentDatabase();
      var equipment = await equipmentDatabase.getById(equipmentId);

      data.categoryId = equipment.categoryId;
      data.timeToRectifyInWeeks = data.timeToRectify;

      var defectDatabase = new DefectsDatabase();
      await defectDatabase.insertOrUpdateList([data]);
    }

    onSaved(data, defectToEdit != null);
  };

  return (
    <View>
      <Text style={styles.modal__header}>{defectToEdit == null ? 'Add New Defect' : 'Edit Defect'}</Text>
      <View style={GlobalStyle.form__row}>
        <View style={GlobalStyle.form__column_full_width}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="name"
            render={({ field: { onChange, onBlur, value } }) => (
              <View>
                <Text style={GlobalStyle.form__column__text}>Name (*)</Text>
                <TextInput
                  style={GlobalStyle.form__column__input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}>
                </TextInput>
              </View>
            )}
          />

          {defectGroup == 1 &&
            <Controller
              control={control}
              rules={{ required: true }}
              name="timeToRectify"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={{ marginTop: 10 }}>
                  <Text style={GlobalStyle.form__column__text}>Time To Rectify (in weeks) (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    keyboardType={'numeric'}
                    value={value.toString()}>
                  </TextInput>
                </View>
              )}
            />
          }
        </View>
      </View>

      <View style={styles.modal__action__buttons}>
        <TouchableOpacity style={styles.modal__cancel__button} onPress={() => onCancel()}>
          <Text style={styles.modal__cancel__button__text}>CANCEL</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.modal__proceed__button} onPress={handleSubmit(submit)}>
          <Text style={styles.modal__proceed__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export function DefectsList({ title, defectGroup, addNewDefect, editExistingDefect }: { title: any, defectGroup: number, addNewDefect: any }) {
  const dispatch = useDispatch();
  const inspection = useSelector((state: RootState) => state.roteInspection);
  const [selectedDefectId, setSelectedDefectId] = useState("");


  const addDefect = (selectedDefectId) => {
    dispatch(updateDefectData({
      defectId: selectedDefectId,
      isSelected: true
    }));
  }

  const removeDefect = (defectId) => {
    dispatch(updateDefectData({
      defectId: defectId,
      isSelected: false
    }));
  }

  const addNew = () => {
    addNewDefect();
  }

  const editDefect = (defectId) => {
    const defect = inspection.defects.find(d => d.id == defectId);
    editExistingDefect(defect);
  }

  return (
    <View>
      <Text style={GlobalStyle.form__column__text}>{title}</Text>

      <View style={{ flexDirection: 'row' }}>
        <Picker
          style={[GlobalStyle.form__column__input, GlobalStyle.form__column__input__full_width]}
          selectedValue={selectedDefectId}
          onValueChange={(itemValue, itemIndex) => { setSelectedDefectId(itemValue); }}
          itemStyle={{ height: 150 }}>

          <Picker.Item label="None" value="" />

          {inspection.defects != null && inspection.defects.filter(d => d.defectGroup == defectGroup).map((value, index) => {
            return (
              <Picker.Item key={value.id} label={value.name} value={value.id} />
            )
          })}
        </Picker>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={styles.picker__button} onPress={() => addDefect(selectedDefectId)}>
          <Text style={styles.picker__button__text}>Add</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.picker__button, styles.new__button]} onPress={() => addNew()}>
          <Text style={styles.picker__button__text}>New</Text>
        </TouchableOpacity>
      </View>
      {inspection.defects.filter(d => d.defectGroup == defectGroup && d.isSelected).length > 0 &&
        <View style={{ borderTopWidth: 1, marginTop: 10, borderTopColor: '#CECECE' }}>
          {inspection.defects.filter(d => d.defectGroup == defectGroup && d.isSelected).map((value, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => removeDefect(value.id)}
                style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingTop: 5, paddingBottom: 5, borderBottomColor: '#CECECE', borderBottomWidth: 1 }}>
                <Entypo name="chevron-small-right" size={24} color="black" />
                <Text style={{ flex: 1 }}>
                  <Text>{value.name}</Text>
                  {value.timeToRectify > 0 && <Text style={{ marginLeft: 10 }}>Time to Rectify: {value?.timeToRectify} - Weeks</Text>}
                </Text>
                <TouchableOpacity onPress={() => editDefect(value.id)}>
                  <Entypo name="pencil" size={16} color="black" />
                </TouchableOpacity>
                <View  style={{ marginLeft: 30 }}>
                  <Entypo name="trash" size={16} color="black" />
                </View>
              </TouchableOpacity>
            )
          })}
        </View>
      }
    </View>
  )
}

export default function RoteStep2({ navigation, route }: { navigation: any, route: any }) {
  const dispatch = useDispatch();
  const inspection = useSelector((state: RootState) => state.roteInspection);
  const job = useSelector((state: RootState) => state.job);

  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedDefectGroup, setSelectedDefectId] = useState(null);
  const [defectToEdit, setDefectToEdit] = useState(null);

  const getEquipmentId = () => {
    if (Platform.OS == "web") { return route.params?.equipmentId; }
    return job.equipmentId;
  }

  const onSubmit = data => {
    navigation.navigate("Step3");
  };

  const onAddNewDefect = (defectGroup) => {
    setSelectedDefectId(defectGroup);
    setShowAddModal(true);
  }

  const onEditExistingDefect = (defect) => {
    setDefectToEdit(defect);
    setShowAddModal(true);
  }

  const onNewDefectAdded = (defect, isEdit) => {
    if (!isEdit) {
      dispatch(addDefectData(defect));
    } else {
      dispatch(updateDefectData(defect))
    }
    setShowAddModal(false);
  }


  const onBack = () => {
    navigation.navigate("Step1");
  }

  return (
    <View style={[styles.container]}>
      {Platform.OS != 'web' &&
        <TitleBar navigation={navigation} title="Step 2 of 4"
          showSearchButton={false} showBackButton={true}
          onSynced={null} onSearch={null}
          backButtonDelegate={onBack} />
      }

      <Modal
        animationType="fade"
        transparent={true}
        visible={showAddModal}>

        <View style={styles.modal__block}>
          <View style={styles.modal}>
            <AddNewDefect
              defectGroup={selectedDefectGroup}
              position={inspection.defects.length + 1}
              onCancel={() => setShowAddModal(false)}
              onSaved={(defect, isEdit) => onNewDefectAdded(defect, isEdit)}
              equipmentId={getEquipmentId()}
              defectToEdit={defectToEdit}
            />
          </View>
        </View>

      </Modal>

      <ScrollView>
        <View style={styles.form}>
          <View style={styles.step__question}>
            <DefectsList
              title={"A DEFECTS (Asset out of service HSE to be notified) (*)"}
              defectGroup={0}
              addNewDefect={() => { onAddNewDefect(0) }}
              editExistingDefect={(defect) => {onEditExistingDefect(defect)}}
            />
          </View>

          <View style={styles.form}>
            <View style={styles.step__question}>
              <DefectsList
                title={"B DEFECTS (Time qualified) (*)"}
                addNewDefect={() => onAddNewDefect(1)}
                editExistingDefect={(defect) => {onEditExistingDefect(defect)}}
                defectGroup={1}
              />
            </View>
          </View>

          <View style={styles.form}>
            <View style={styles.step__question}>
              <DefectsList
                title={"C DEFECTS (Advisory/recommendation/monitor) (*)"}
                addNewDefect={() => onAddNewDefect(2)}
                editExistingDefect={(defect) => {onEditExistingDefect(defect)}}
                defectGroup={2}
              />
            </View>
          </View>
        </View>
      </ScrollView>

      {Platform.OS != 'web' &&
        <TouchableOpacity style={styles.proceed__button} onPress={onSubmit}>
          <Text style={styles.proceed__button__text}>NEXT</Text>
        </TouchableOpacity>
      }

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFAFA',
    padding: 40,
    ...Platform.select({
      android: { flex: 1 },
      ios: { flex: 1 },
      web: { paddingBottom: 0, paddingTop: 0 }
    })
  },

  form: {
    flex: 1,
    marginTop: 30,
    ...Platform.select({
      web: { marginTop: 5 }
    })
  },

  step__question: {
    padding: 20,
    backgroundColor: '#FFF',
    marginBottom: 20,
  },

  step__question__label: {
    fontFamily: 'OpenSans-Light',
    fontSize: 18,
    marginBottom: 5,
  },

  step__question__input: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 10,
    marginTop: 5,
  },

  step__question__input__full_width: {
    flex: 1
  },

  input__validation: {
    color: 'red',
    marginTop: 10
  },

  proceed__button: {
    padding: 10,
    backgroundColor: colours.primary,
    marginTop: 20,
    borderRadius: 7,
    height: 45,


    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  proceed__button__text: {
    fontFamily: 'OpenSans-Bold',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 18
  },


  picker__button: {
    backgroundColor: "#FFF",
    marginTop: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: config.colours.primary,
    alignItems: 'center',
    justifyContent: 'center',
    width: 80
  },

  picker__button__text: {
    padding: 5,
    color: config.colours.primary,
    textAlign: 'center',
    fontFamily: 'OpenSans-ExtraBold'
  },

  new__button: {
    marginLeft: 10
  },

  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  modal: {
    backgroundColor: '#FFF',
    padding: 10,
    width: 600,
    borderRadius: 5,

    flexDirection: 'column',
  },

  modal__header: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 24,
    borderBottomWidth: 1,
    borderBottomColor: '#CECECE',
    marginBottom: 5,
    paddingBottom: 5,
  },

  modal__proceed__button: {
    padding: 10,
    backgroundColor: colours.primary,
    marginTop: 20,
    borderRadius: 7,
    height: 45,

    flex: 1,
    marginLeft: 5,
  },

  modal__proceed__button__text: {
    fontFamily: 'OpenSans-Bold',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 18
  },

  modal__action__buttons: {
    flexDirection: 'row',
  },

  modal__cancel__button: {
    padding: 10,
    backgroundColor: '#FFF',
    marginTop: 20,
    borderRadius: 7,
    height: 45,
    flex: 1,
    marginRight: 5,
  },

  modal__cancel__button__text: {
    fontFamily: 'OpenSans-Bold',
    textAlign: 'center',
    fontSize: 18,
    color: colours.primary
  },
});
