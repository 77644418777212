import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';

export class JobDatabase extends Database {
  TABLE_NAME = "Jobs";
  TABLE_NAME_ENGINEERS = "JobEngineers";
  TABLE_NAME_ASSETS = "JobAssets";

  migrateColumn(table, columnName, columnType) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        try {
          tx.executeSql(`ALTER TABLE ${table} ADD COLUMN ${columnName} ${columnType}`);
        } catch {  }
      }, (error) => {
        resolve();
      }, () => {
        resolve();
      })
    });
  }

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Jobs ( 
        id TEXT PRIMARY KEY,
        locationId TEXT,
        jobType INT,
        jobNumber TEXT,
        scheduledDate TEXT,
        notes TEXT,
        createdOn TEXT,
        purposeId TEXT,
        colourId TEXT,
        ramsId TEXT,
        engineerReportId TEXT,
        isCompleted INT,
        autoReschedule INT,
        toBeSynced INT
      )`;

      var sqlEngineers = `CREATE TABLE IF NOT EXISTS JobEngineers ( 
        jobId TEXT,
        engineerId TEXT
      )`;

      var sqlAssets = `CREATE TABLE IF NOT EXISTS JobAssets ( 
        id TEXT,
        jobId TEXT,
        equipmentId TEXT,
        completed INT,
        toBeSynced INT
      )`;

      Database.getDb().transaction(async (tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlEngineers);
        tx.executeSql(sqlAssets);

        await this.migrateColumn("Jobs", "autoReschedule", "INT");
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any, toBeSynced: boolean = false) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          tx.executeSql(`DELETE FROM ${this.TABLE_NAME_ENGINEERS} WHERE jobId = ?`, [entity.id]);
          tx.executeSql(`DELETE FROM ${this.TABLE_NAME_ASSETS} WHERE jobId = ?`, [entity.id]);
          tx.executeSql(`DELETE FROM ${this.TABLE_NAME} WHERE id = ?`, [entity.id]);

          if (entity.isDeleted || entity.isDeleted == 1) {
            continue;
          }

       
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, locationId, jobType, jobNumber, scheduledDate, notes, createdOn, purposeId, colourId, ramsId, engineerReportId, isCompleted, autoReschedule, toBeSynced)
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `


          tx.executeSql(sql, [
            entity.id, entity.locationId, entity.jobType, entity.jobNumber, entity.scheduledDate, entity.notes,
            entity.createdOn, entity.purposeId, entity.colourId, entity.ramsId, entity.engineerReportId, entity.isCompleted,
            entity.autoReschedule, toBeSynced
          ]);
          for (let engineerId of entity.engineerIds) {
            tx.executeSql(`INSERT INTO ${this.TABLE_NAME_ENGINEERS} (jobId, engineerId) VALUES (?, ?)`, [entity.id, engineerId]);
          }

          for (let jobAsset of entity.jobAssets) {
            tx.executeSql(`INSERT INTO ${this.TABLE_NAME_ASSETS} (id, jobId, equipmentId, completed) VALUES (?, ?, ?, ?)`, [jobAsset.id, jobAsset.jobId, jobAsset.equipmentId, jobAsset.completed]);
          }
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  updateJobRams(jobId: any, ramsId: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET ramsId = ?, toBeSynced = 1 WHERE id = ?
          `

          tx.executeSql(sql, [ramsId, jobId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  completeJob(jobId: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE ${this.TABLE_NAME} SET isCompleted = 1, toBeSynced = 1 WHERE id = ?
        `

        tx.executeSql(sql, [jobId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  updateEngineerReport(jobId: any, engineerReportId: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET engineerReportId = ?, toBeSynced = 1 WHERE id = ?
          `

          tx.executeSql(sql, [engineerReportId, jobId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }


  updateJobData(jobId: any, purposeId: any, colourId: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET purposeId = ?, colourId = ?, toBeSynced = 1 WHERE id = ?
          `

          tx.executeSql(sql, [purposeId, colourId, jobId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getJobById(jobId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var job = null;
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT jobs.id, jobs.createdOn, jobs.locationId, companies.name as companyName,
          locations.name as locationName, scheduledDate, jobType, jobNumber,
          CASE jobType WHEN 0 THEN 'ROTE' WHEN 1 THEN 'Servicing' WHEN 2 THEN 'Breakdown' WHEN 3 THEN 'Proof Load' END AS jobTypeFormatted,
          purposeId, colourId, ramsId, engineerReportId, notes, companies.id as companyId, locations.id as locationId, autoReschedule
          FROM jobEngineers
          INNER JOIN jobs ON jobEngineers.jobId = jobs.id 
          INNER JOIN locations ON jobs.locationId = locations.id
          INNER JOIN companies ON locations.companyId = companies.id
          WHERE jobs.id = ?
        `;

        tx.executeSql(sql, [jobId], (_, { rows: { _array } }) => {
          //console.log(_array)
          if (_array.length > 0) {
            job = _array[0];
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(job);
      })
    })
  }

  getJobs(engineerId, searchQuery = null) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT jobs.id, jobs.createdOn, jobs.locationId, companies.name as companyName,
          locations.name as locationName, scheduledDate, jobType, jobNumber,
          CASE jobType WHEN 0 THEN 'ROTE' WHEN 1 THEN 'Servicing' WHEN 2 THEN 'Breakdown' WHEN 3 THEN 'Proof Load' END AS jobTypeFormatted,
          ramsId, engineerReportId, notes, autoReschedule
          FROM jobEngineers
          INNER JOIN jobs ON jobEngineers.jobId = jobs.id 
          INNER JOIN locations ON jobs.locationId = locations.id
          INNER JOIN companies ON locations.companyId = companies.id
          WHERE jobEngineers.engineerId = ? AND jobs.isCompleted = 0
        `;
        

        if (searchQuery != null && searchQuery != "") {
          sql += " AND lower(jobs.jobNumber) = '" + searchQuery.toLowerCase() + "'";
        }

        // console.log(sql);
        // console.log(engineerId);

        tx.executeSql(sql, [engineerId], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getJobAssets(jobId, searchQuery) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT equipment.id, description, serialNumber, 
          categories.name as categoryName, manufacturers.name as manufacturersName,
          completed, categories.id as categoryId, categories.allowQuickInspect,
          (SELECT COUNT(*) FROM InspectionQuestions WHERE categoryId = Categories.id) AS questionCount
          FROM JobAssets 
          INNER JOIN Equipment ON JobAssets.equipmentId = Equipment.id
          INNER JOIN Categories ON Equipment.categoryId = Categories.id
          INNER JOIN Manufacturers ON Equipment.manufacturerId = Manufacturers.id
          WHERE jobId = ? AND completed = 0
        `;

        if (searchQuery != null && searchQuery != "") {
          sql += " AND ( " +
            "lower(description) LIKE '%" + searchQuery.toLowerCase() + "%' OR " +
            "lower(serialNumber) LIKE '%" + searchQuery.toLowerCase() + "%' OR " +
            "lower(assetNumber) LIKE '%" + searchQuery.toLowerCase() + "%'" +
            ")";
        }

        tx.executeSql(sql, [jobId], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getJobsForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Jobs WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          var sqlEngineers = `SELECT engineerId FROM ${this.TABLE_NAME_ENGINEERS} WHERE jobId = ?`;
          var sqlAssets = `SELECT * FROM ${this.TABLE_NAME_ASSETS} WHERE jobId = ?`;

          for (let job of data) {
            tx.executeSql(sqlEngineers, [job.id], (_, { rows: { _array } }) => {
              job.engineerIds = [];
              for (var engineerRow of _array) {
                job.engineerIds.push(engineerRow.engineerId);
              }
            });

            tx.executeSql(sqlAssets, [job.id], (_, { rows: { _array } }) => {
              job.jobAssets = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getJobAssetsForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT id, equipmentId, completed, jobId FROM JobAssets WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  updateJobToBeSynced(id, toBeSynced) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE Jobs SET toBeSynced = ? WHERE id = ?
        `;

        tx.executeSql(sql, [toBeSynced, id], (_, { rows: { _array } }) => {
          
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  updateJobAssetToBeSynced(id, toBeSynced) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE JobAssets SET toBeSynced = ? WHERE id = ?
        `;

        tx.executeSql(sql, [toBeSynced, id], (_, { rows: { _array } }) => {
          
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  setJobAssetCompleted(id, completed) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE JobAssets SET completed = ? WHERE id = ?
        `;

        tx.executeSql(sql, [completed, id], (_, { rows: { _array } }) => {
          
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  createJobAsset(equipmentId, jobId) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT INTO JobAssets (id, equipmentId, completed, jobId, toBeSynced)
          VALUES (?, ?, 0, ?, 1)
        `;

        tx.executeSql(sql, [uuid.v4(), equipmentId, jobId], (_, { rows: { _array } }) => {
          
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }
}