import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Feather } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';
import { addBasketInspection, clearBasket, removeBasketInspection } from '@store/slices/basket-slice';
import { ScrollView } from 'react-native-gesture-handler';

export const Basket = (props) => {
  const dispatch = useDispatch();
  const inspections = useSelector((state: RootState) => state.basket.inspections);
  const [showList, setShowList] = useState<boolean>(false);

  useEffect(() => {
    dispatch(clearBasket());
  }, []);

  useEffect(() => {
    if (inspections.length == 0) { setShowList(false); }
  }, [inspections])

  const toggleList = () => {
    if (inspections.length == 0) { return; }
    setShowList(!showList);
  }

  const removeFromBasket = (id) => {
    dispatch(removeBasketInspection(id));
  }

  return (
    <View style={styles.basket__container}>
      <View style={styles.basket}>
        <TouchableOpacity style={styles.title}  onPress={() => toggleList()}>
          <Feather name="shopping-cart" size={24} color="black" />
          <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>Basket</Text>
          <View style={{ flex: 1 }}></View>
          <Text><Text style={{ fontWeight: 'bold' }}>{ inspections.length }</Text><Text> Items</Text></Text>
        </TouchableOpacity>
      </View>

      {showList && 
        <ScrollView style={styles.basket__list}>
          { inspections.map((inspection) => {
            return (
              <View style={styles.basket__row} key={inspection.id}>
                <Text style={{ fontWeight: 'bold' }}>{inspection.serialNumber}</Text>
                <View style={{ flex: 1 }}></View>
                <TouchableOpacity onPress={() => removeFromBasket(inspection.id)}>
                  <Feather name="trash" size={20} color="black" />
                </TouchableOpacity>
              </View>
            )
          })}
        </ScrollView>
      }
    </View>
  )
}

export const BasketIcon = ({inspectionId, serialNumber}) => {
  const dispatch = useDispatch();
  const inspections = useSelector((state: RootState) => state.basket.inspections);

  const inBasket = (id) => {
    return inspections.filter(i => i.id == id).length > 0;
  }

  const addToBasket = () => {
    dispatch(addBasketInspection({
      id: inspectionId,
      serialNumber: serialNumber
    }))
  }

  const removeFromBasket = () => {
    dispatch(removeBasketInspection(inspectionId));
  }

  if (inBasket(inspectionId)) {
    return (
      <TouchableOpacity onPress={() => removeFromBasket()}>
        <Foundation name="minus" size={24} color="black" />
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity onPress={() => addToBasket()}>
      <Foundation name="plus" size={24} color="black" />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  basket__container: {
    position: 'relative',
    zIndex: 999999
  },

  basket: {
    backgroundColor: '#FFF',
    padding: 10,
    marginBottom: 10,
    width: '300px',

    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#CECECE'
  },

    title: {
      flexDirection: 'row',
      alignItems: 'center',
    },

  basket__list: {
    position: 'absolute',
    top: 50,
    left: 0,
    zIndex: 9999999,
    backgroundColor: '#FFF',
    width: '300px',
    maxHeight: '300px',
    borderWidth: 1,
    borderColor: '#CECECE',
  },

    basket__row: {
      backgroundColor: '#f5f2f2',
      padding: 10,
      margin: 5,

      flexDirection: 'row',
      alignItems: 'center'
    }
});