import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { useDebounce } from '../../../../../components/helpers/UseDebounce';
import GlobalStyle from '../../../../../constants/GlobalStyle';
import { useGetEngineersQuery } from '@store/services/api/user-api';
import moment from 'moment';
import DateTimePicker from '@web/components/navigation/controls/DateTimePicker';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { Foundation, SimpleLineIcons } from '@expo/vector-icons';
import { inspectionApi } from '@store/services/api/inspection-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { PagedTableDto } from '@store/services/api/base-api';
import { ActivityIndicator, DataTable } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/index';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { Basket, BasketIcon } from '@web/components/Basket';
import { DataTableHeader } from '@web/components/table/DataTableHeader';

export default function DefectsScreen({ navigation }: { navigation: any }) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: engineers } = useGetEngineersQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);

  const [page, setPage] = React.useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("serialNumber");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const [trigger, inspectionResult] = inspectionApi.useLazyGetInspectionDefectsQuery();

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState(moment().add(-6, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [serialNumber, setSerialNumber] = useState("");
  const [description, setDescription] = useState("");
  const [defectStatus, setDefectStatus] = useState("-1");

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  const resetFilters = () => {
    setCompanyId(user.companyId);
    setLocationId("");
    setDateRange("");
    setStartDate(moment().add(-6, 'months').toDate());
    setEndDate(moment().toDate());
    setSerialNumber("");
    setDescription("");
    setDefectStatus("-1");
  }

  const loadTable = () => {
    var dto = new PagedTableDto(page, {
      companyId: companyId,
      locationId: locationId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      serialNumber: serialNumber,
      defectStatus: defectStatus,
      description: description
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const downloadCert = async (inspection) => {
    //inspection.isLoading = true;
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRote(inspection.id);
    //inspection.isLoading = false;
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  const downloadRAMS = async (inspection) => {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRAMS(inspection.ramsId);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending])


  if (inspectionResult.isLoading) {
    return <LoadingIndicator />
  }


  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={companyId}
              enabled={user.role != 'User'}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); }}>

              <Picker.Item label="All" value="" />

              {companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

              <Picker.Item label="All" value="" />

              {/* {locations != null && locations.filter(l => companyId == "" || l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })} */}

              { locations != null && locations.filter(l => 
                (companyId == "" || l.companyId == companyId) &&
                (user.role != 'User' || user.allowedLocationsList.includes(l.id))
              ).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Serial / Asset #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChangeText={setSerialNumber}
              value={serialNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Description</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChangeText={setDescription}
              value={description}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            
          </View>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={defectStatus}
              onValueChange={(itemValue, itemIndex) => setDefectStatus(itemValue)}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="A" value="0" />
              <Picker.Item label="B" value="1" />
              <Picker.Item label="C" value="2" />
            </Picker>
          </View>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Date Range</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              selectedValue={dateRange}
              onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

              <Picker.Item label="Predefined Date Range" value="" />
              <Picker.Item label="1 Week" value="-1" />
              <Picker.Item label="1 Month" value="1" />
              <Picker.Item label="3 Months" value="3" />
              <Picker.Item label="6 Months" value="6" />
              <Picker.Item label="12 Months" value="12" />

            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Start Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input]}
              onChange={setStartDate}
              value={startDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>End Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input,]}
              onChange={setEndDate}
              value={endDate}>
            </DateTimePicker>
          </View>
        
          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <Basket />

      <DataTable style={GlobalStyle.table}>
        {/* <DataTable.Header>
          <DataTable.Title style={{ flex: 0.5 }}>Report</DataTable.Title>
          <DataTable.Title>Serial Number</DataTable.Title>
          <DataTable.Title style={{ flex: 1.5 }}>A Defects</DataTable.Title>
          <DataTable.Title style={{ flex: 1.5 }}>B Defects</DataTable.Title>
          <DataTable.Title style={{ flex: 1.5 }}>C Defects</DataTable.Title>
          <DataTable.Title>Location</DataTable.Title>
          <DataTable.Title>Company</DataTable.Title>
        </DataTable.Header> */}

        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Report', flex: 0.5, visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'A Defects', flex: 1.5, visible: true },
            { text: 'B Defects', flex: 1.5, visible: true },
            { text: 'C Defects', flex: 1.5, visible: true },
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Company', sortedName: "companyName", visible: true },
          ]}
        />

        {inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              {/* <DataTable.Cell style={{ flex: 0.5 }}>
                <BasketIcon inspectionId={element.id} serialNumber={element.serialNumber} />
              </DataTable.Cell> */}
              <DataTable.Cell style={{ flex: 0.5 }}>
                <View style={{ flexDirection: 'row' }}>
                  <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>
                  {element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadRAMS(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity>}
                </View>
              </DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell style={{ flex: 1.5 }}>{element.aDefects}</DataTable.Cell>
              <DataTable.Cell style={{ flex: 1.5 }}>{element.bDefects}</DataTable.Cell>
              <DataTable.Cell style={{ flex: 1.5 }}>{element.cDefects}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={inspectionResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)}
        //@ts-ignore
        itemsPerPage={inspectionResult.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

});
