import { baseApi, PagedTableDto } from './base-api'

export const inspectionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInspection: build.query<any, any>({
      query: (id) => ({
        url: `inspection/getinspection/${id}`,
      }),
    }),
    getCategoryInspectionQuestions: build.query<any, any>({
      query: (categoryId) => ({
        url: `inspection/getcategoryinspectionquestions?categoryId=${categoryId}`,
      }),
    }),
    getInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        //url: `inspection/getinspections?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
        url: `inspection/getinspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));
        return {
          list: response,
          pagination: pagination,
          passed: pagination.passed,
          failed: pagination.failed,
          missing: pagination.missing
        };
      }
    }),
    getInspectionsCosts: build.query<any, PagedTableDto>({
      query: (dto) => ({
        //url: `inspection/getinspections?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
        url: `inspection/getinspectionscosts?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    exportInspectionsCosts: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `inspection/exportinspectionscosts?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return response;
      }
    }),
    getInspectionDefects: build.query<any, PagedTableDto>({
      query: (dto) => ({
        //url: `inspection/getinspections?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
        url: `inspection/getdefects?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    sendInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'inspection/addinspection',
        method: 'post',
        body: body
      }),
    }),
    updateInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'inspection/updateinspection',
        method: 'put',
        body: body
      }),
    }),
    addInspectionAudit: build.mutation<any, any>({
      query: (body) => ({
        url: 'inspection/addinspectionaudit',
        method: 'post',
        body: body
      }),
    }),
    getInspectionAudits: build.query<any, any>({
      query: (id) => ({
        url: `inspection/getinspectionaudits/${id}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var sortedResponse = response.sort((a, b) => b.viewedAtUnix - a.viewedAtUnix);
        return sortedResponse;
      }
    }),
    acknowledgeInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'inspection/acknowledgeInspection',
        method: 'post',
        body: body
      }),
    }),
    syncInspectionQuestions: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `inspection/getinspectionquestions?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
  }),
  overrideExisting: true,
})

export const { 
  useSendInspectionMutation,
  useGetInspectionsQuery,
  useGetInspectionDefectsQuery,
  useGetInspectionsCostsQuery,
  useExportInspectionsCostsQuery,
  useGetInspectionQuery,
  useUpdateInspectionMutation,
  useAddInspectionAuditMutation,
  useGetInspectionAuditsQuery,
  useAcknowledgeInspectionMutation,
} = inspectionApi