import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Image } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';

import { useIsFocused } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useCreateUserMutation, userApi, useUpdateUserMutation } from '@store/services/api/user-api';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import * as DocumentPicker from 'expo-document-picker';
import Checkbox from "expo-checkbox";
import { colours } from '../../../../../../config';


export default function WebUserManage({ navigation, route }: { navigation: any, route: any }) {
  const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);

  const [createUser, createUserResult] = useCreateUserMutation();
  const [updateUser, updateUserResult] = useUpdateUserMutation();
  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);

  const [trigger, usersResult] = userApi.useLazyGetUserQuery();

  const [locationsList, setLocationsList] = React.useState([]);
  const [toggleAll, setToggleAll] = React.useState(false);


  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "Admin",
      companyId: null,
      allowedLocations: null,
      signature: "",
      allowViewAudit: false,
    }
  });

  const userId = watch("id");
  const role = watch("role");
  const companyId = watch("companyId");
  const signature = watch("signature");

  React.useEffect(() => {
    if (route.params?.userId != null) {
      trigger(route.params?.userId);
    }
  }, [])

  React.useEffect(() => {
    if (locations != null) {
      var locList = JSON.parse(JSON.stringify(locations));
      setLocationsList(locList);
    }

    if (usersResult.isSuccess && locations != null) {
      var data = usersResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
      setValue("email", data.email);
      setValue("companyId", data.companyId);
      setValue("role", data.role);
      setValue("signature", data.signature);
      setValue("allowViewAudit", data.allowViewAudit);

      var locList = JSON.parse(JSON.stringify(locations));
      for (var location of locList) {
        location.isSelected = data.allowedLocationsList.includes(location.id);
      }

      setLocationsList(locList);
    }
  }, [usersResult, locations]);


  const toggleLocation = (location) => {
    if (location.isSelected == null) { location.isSelected = false; }

    location.isSelected = !location.isSelected;
    forceUpdate();
  }

  const toggleAllList = () => {
    const newToggle = !toggleAll;
    setToggleAll(newToggle);
    for (var location of locationsList) { location.isSelected = newToggle; }

    forceUpdate();
  }

  const onSubmit = async (data) => {
    var result = null;

    if (data.password != data.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    data.allowedLocations = locationsList.filter(l => l.isSelected).map(l => l.id).join(",");

    if (data.id != null) {
      result = await updateUser(data) as any;
    } else {
      result = await createUser(data) as any;
    }


    if (result.error) {
      alert(result.error.data);
      return;
    }

    navigation.goBack();
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const loadFile = async () => {
    var result = await DocumentPicker.getDocumentAsync({
      type: ".png"
    });

    //@ts-ignore
    const file = result.file;
    var base64 = await toBase64(file);

    //@ts-ignore
    setValue("signature", base64);
  }


  if (createUserResult.isLoading || updateUserResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.goBack()}>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{userId == null ? "CREATE USER" : "UPDATE USER"}</Text>
      </View>

      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Name (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="email"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Email (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    editable={userId != null ? false : true}>
                  </TextInput>
                </View>
              )}
            />

            {errors.email && <Text style={GlobalStyle.form__input__validation}>Email is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="role"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Role (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Admin" value={"Admin"} />
                    <Picker.Item label="Client" value={"User"} />
                    <Picker.Item label="Engineer" value={"Engineer"} />
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: userId != null ? false : true }}
              name="password"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Password (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    secureTextEntry={true}
                    placeholder={userId != null ? 'Unchanged' : ''}>
                  </TextInput>
                </View>
              )}
            />

            {errors.password && <Text style={GlobalStyle.form__input__validation}>Password is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: userId != null ? false : true }}
              name="confirmPassword"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Confirm Password (*)</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    secureTextEntry={true}
                    placeholder={userId != null ? 'Unchanged' : ''}>
                  </TextInput>
                </View>
              )}
            />

            {errors.confirmPassword && <Text style={GlobalStyle.form__input__validation}>Password is required.</Text>}
          </View>

          {role == 'User' && <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="companyId"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) =>
                      onChange(itemValue)
                    }>

                    <Picker.Item label="Please Select" value="" />

                    {companies != null && companies.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>
          }
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="allowViewAudit"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Allow View Audit (*)</Text>
                  <Checkbox style={{ marginTop: 10 }} value={value} onValueChange={onChange} color={colours.primary} />
                </View>
              )}
            />
          </View>
        </View>

        {(role == "Engineer" || role == "Admin") && 
          <View style={GlobalStyle.form__column}>
            <View>
              {signature != null && signature != "" &&
                <Image
                  style={{ width: 320, height: 120, marginBottom: 10 }}
                  resizeMode={"contain"}
                  source={{
                    uri: signature,
                  }}
                />
              }
            </View>
            <TouchableOpacity style={styles.upload__button} onPress={() => loadFile()}>
              <Text style={styles.upload__button__text}>Upload Signature</Text>
            </TouchableOpacity>
          </View>
        }


        <View style={GlobalStyle.form__row}>
          {companyId != null &&
            <View style={{ width: '100%', marginTop: 10, borderColor: '#cecece', borderWidth: 1 }}>
              <Text style={{ fontWeight: 'bold', padding: 10, backgroundColor: '#f2f2f2', flex: 1 }}>
                <Checkbox style={{ marginRight: 20 }} color={colours.primary} value={toggleAll} onValueChange={() => toggleAllList()} />
                Locations
              </Text>
              {locationsList != null && locationsList.filter(l => l.companyId == companyId).map((location, index) => {
                return (
                  <View key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10, borderTopWidth: 1, borderTopColor: '#cecece', paddingTop: 10, paddingBottom: 10 }}>
                    <Checkbox style={{ marginRight: 20 }} color={colours.primary}
                      value={location.isSelected}
                      onValueChange={() => toggleLocation(location)}
                    />
                    <Text style={GlobalStyle.form__column__text}>{location.name}</Text>
                  </View>
                )
              })}
            </View>
          }
        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  upload__button: {
    marginBottom: 10,
    backgroundColor: PRIMARY_COLOUR,
    width: 200,
  },

    upload__button__text: {
      fontFamily: 'OpenSans-Bold',
      padding: 5,
      color: '#FFF',
      textAlign: 'center',
      fontSize: 14,
    },
});
