import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { useIsFocused } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';
import { categoryApi, useCreateCategoryMutation, useGetAllMasterCategoriesQuery, useUpdateCategoryMutation } from '../../../../../../store/services/api/category-api';
import Checkbox from "expo-checkbox";
import { colours } from '../../../../../../config';

export default function CategoryManageScreen({navigation, route}: {navigation: any, route: any}) {
  const [createCategory, createCategoryResult] = useCreateCategoryMutation();
  const [updateCategory, updateCategoryResult] = useUpdateCategoryMutation();

  const [trigger, categoryResult] = categoryApi.useLazyGetCategoryQuery();
  const { data: masterCategories } = useGetAllMasterCategoriesQuery(null);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: "",
      inspectionInterval: "",
      masterCategoryId: "",
      cost: "0",
      allowQuickInspect: false,
      isDeleted: false,
    }
  });

  const id = watch("id");

  React.useEffect(() => {
    if (route.params?.categoryId != null) {
      trigger(route.params?.categoryId);
    }
  }, [])

  React.useEffect(() => {
    if (categoryResult.isSuccess) {
      var data = categoryResult.data;
      console.log(data);
      setValue("id", data.id);
      setValue("name", data.name);
      setValue("inspectionInterval", data.inspectionInterval);
      setValue("masterCategoryId", data.masterCategoryId);
      setValue("cost", data.cost);
      setValue("allowQuickInspect", data.allowQuickInspect);
      setValue("isDeleted", data.isDeleted);
    }
  }, [categoryResult]);

  const onSubmit = async (data) => {
    var result = null;


    if (data.id != null) {
      result = await updateCategory(data) as any;
    } else {
      result = await createCategory(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createCategoryResult.isLoading || updateCategoryResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("name") == null ? "CREATE CATEGORY" : "UPDATE CATEGORY"}</Text>
      </View>
      
      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Category Name (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="inspectionInterval"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Inspection Interval (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.inspectionInterval && <Text style={GlobalStyle.form__input__validation}>Inspection Interval is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="cost"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Cost (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.cost && <Text style={GlobalStyle.form__input__validation}>Cost is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="masterCategoryId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Master Category (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) =>
                      onChange(itemValue)
                    }
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { masterCategories != null && masterCategories.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.masterCategoryId && <Text style={GlobalStyle.form__input__validation}>Master Category is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="allowQuickInspect"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Allow Quick Inspect</Text>
                  <Checkbox style={{ marginRight: 20 }} color={colours.primary} value={value} onValueChange={onChange} />
                </View>
              )}
            />
          </View>

          { id != null && 
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="isDeleted"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Archived</Text>
                    <Checkbox style={{ marginRight: 20 }} color={colours.primary} value={value} onValueChange={onChange} />
                  </View>
                )}
              />
            </View>
          }
          
        </View>


        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
