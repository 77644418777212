import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AppDashboardScreen from './Dashboard/AppDashboard';
import JobManageScreen from './Dashboard/JobManage';
import BreakdownInspect from './Inspect/Breakdown/BreakdownInspect';
import EquipmentDetailsScreen from './Inspect/EquipmentDetails';
import EquipmentManageScreen from './Inspect/EquipmentManage';
import EquipmentViewFilesScreen from './Inspect/EquipmentViewFiles';
import ERSTRScreen from './Inspect/ERSTR/ERSTR';
import JobAssetsScreen from './Inspect/JobAssets';
import JobDetailsScreen from './Inspect/JobDetails';
import ProofLoadInspect from './Inspect/ProofLoad/ProofLoadInspect';
import RAMSScreen from './Inspect/RAMS/RAMSScreen';
import RoteInspect from './Inspect/Rote/RoteInspect';
import ServiceInspect from './Inspect/Servicing/ServiceInspect';

const Stack = createNativeStackNavigator();

export default function AppNavigator({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Dashboard" component={AppDashboardScreen} options={{ headerShown: false, title: 'Dashboard'  }} />
      <Stack.Screen name="JobManage" component={JobManageScreen} options={{ headerShown: false, title: 'Job Manage'  }} />
      <Stack.Screen name="JobAssets" component={JobAssetsScreen} options={{ headerShown: false, title: 'Job Assets'  }} />
      <Stack.Screen name="RAMS" component={RAMSScreen} options={{ headerShown: false, title: 'RAMS'  }} />
      <Stack.Screen name="JobDetails" component={JobDetailsScreen} options={{ headerShown: false, title: 'Job Details'  }} />
      <Stack.Screen name="EquipmentDetails" component={EquipmentDetailsScreen} options={{ headerShown: false, title: 'Job Details'  }} />
      <Stack.Screen name="EquipmentViewFiles" component={EquipmentViewFilesScreen} options={{ headerShown: false, title: 'Job Details'  }} />
      <Stack.Screen name="EquipmentManage" component={EquipmentManageScreen} options={{ headerShown: false, title: 'Job Details'  }} />
      <Stack.Screen name="RoteInspect" component={RoteInspect} options={{ headerShown: false, title: 'Rote Inspect'  }} />
      <Stack.Screen name="ServiceInspect" component={ServiceInspect} options={{ headerShown: false, title: 'Service Inspect'  }} />
      <Stack.Screen name="BreakdownInspect" component={BreakdownInspect} options={{ headerShown: false, title: 'Breakdown Inspect'  }} />
      <Stack.Screen name="ProofLoadInspect" component={ProofLoadInspect} options={{ headerShown: false, title: 'Proof Load Inspect'  }} />
      <Stack.Screen name="ERSTR" component={ERSTRScreen} options={{ headerShown: false, title: 'ERSTR'  }} />
    </Stack.Navigator>
  )
};