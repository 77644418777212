import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { TitleBar } from '@app/components/TitleBar';
import { StyleSheet, Text, TextInput, View, TouchableOpacity, Alert, KeyboardAvoidingView } from 'react-native';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { colours } from '../../../../../config';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { ScrollView } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { ProofLoadDatabase } from '@store/services/database/proof-load-database';
import { setProofLoadData } from '@store/slices/proofload-slice';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { Ionicons } from '@expo/vector-icons';
import { CameraModal } from '@app/components/CameraModal';

const Step1Tab = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [loadTypes, setLoadTypes] = useState([]);
  const [loadUnits, setLoadUnits] = useState([]);

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const result = await trigger();
      console.log(result);
      if (result) {
        // Set our data
        const values = getValues();
        dispatch(setProofLoadData({
          proofLoadTestType: values.proofLoadTestType,
          additionalDetails: values.additionalDetails,
          standards: values.standards,
          quantityToBeTested: values.quantityToBeTested,
          loadType: values.loadType,
          loadValue: values.load + " " + values.loadUnit,
          drawingRef: values.drawingRef
        }));
      }

      return result;
    },
  }));

  useEffect(() => {
    const loadData = async () => {
      const equipmentDatabase = new EquipmentDatabase();
      var loadTypes = await equipmentDatabase.getLoadTypes();
      setLoadTypes(loadTypes);
      
      var loadUnits = await equipmentDatabase.getLoadUnits();
      setLoadUnits(loadUnits);
    }

    loadData();
  }, []);

  const { control, handleSubmit, trigger, getValues, formState: { errors } } = useForm({
    defaultValues: {
      proofLoadTestType: null,
      additionalDetails: null,
      standards: null,
      quantityToBeTested: null,
      loadType: null,
      load: null,
      loadUnit: null,
      drawingRef: null,
    }
  });

  return (
    <ScrollView style={{ flex: 1 }}>
      <Controller
        control={control}
        name="proofLoadTestType"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Test Type</Text>
            <Picker
              selectedValue={value}
              onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="Please Select" value="" />
              <Picker.Item label="Proof Load Test" value="Proof Load Test" />
              <Picker.Item label="Pressure Test" value="Pressure Test" />
            </Picker>

            {errors.proofLoadTestType && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
          </View>
        )}
      />

      <Controller
        control={control}
        name="additionalDetails"
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Additional Details:</Text>

            <TextInput
              style={styles.question__input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />

            {errors.additionalDetails && <Text style={styles.input__validation}>Enter details</Text>}
          </View>
        )}
      />

      <Controller
        control={control}
        name="standards"
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Details of Any Standards / Technical Specifications Used:</Text>

            <TextInput
              style={styles.question__input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />

            {errors.additionalDetails && <Text style={styles.input__validation}>Enter details</Text>}
          </View>
        )}
      />

      <Controller
        control={control}
        name="quantityToBeTested"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Quantity to be tested:</Text>

            <TextInput
              style={styles.question__input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              keyboardType={'decimal-pad'}
            />

            {errors.quantityToBeTested && <Text style={styles.input__validation}>Enter qty</Text>}
          </View>
        )}
      />

      <Controller
        control={control}
        name="loadType"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Load Type</Text>
            <Picker
              selectedValue={value}
              onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="Please Select" value="" />
              { loadTypes != null && loadTypes.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.type} value={value.type} />
                )
              })}
            </Picker>

            {errors.loadType && <Text style={styles.input__validation}>Select load type</Text>}
          </View>
        )}
      />

      <Controller
        control={control}
        name="load"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Load:</Text>

            <TextInput
              style={styles.question__input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />

            {errors.load && <Text style={styles.input__validation}>Enter load</Text>}
          </View>
        )}
      />

      <Controller
        control={control}
        name="loadUnit"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Load Unit</Text>
            <Picker
              selectedValue={value}
              onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="Please Select" value="" />
              { loadUnits != null && loadUnits.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.unit} value={value.unit} />
                )
              })}
            </Picker>

            {errors.loadUnit && <Text style={styles.input__validation}>Select load unit</Text>}
          </View>
        )}
      />

    <Controller
        control={control}
        name="drawingRef"
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.question}>
            <Text style={styles.question__label}>Drawing Ref:</Text>

            <TextInput
              style={styles.question__input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          </View>
        )}
      />
    </ScrollView>
  )
});


const Step2Tab = forwardRef((props, ref) => {
  const [showCamera, setShowCamera] = useState(false);
  const [images, setImages] = useState([]);

  const addImage = (image) => {
    setImages([...images, image]);
    setShowCamera(false);
  }

  const removeImage = (image) => {
    Alert.alert("Images", "Are you sure you want to remove this image?", [
      {
        text: 'No',
        style: 'cancel'
      },
      { text: 'Yes', onPress: () => {setImages(images.filter(i => i !== image))}}
    ])
  }

  const loadCamera = () => {
    if (images.length >= 3) {
      Alert.alert("You have reached the maximum images.")
      return;
    }

    setShowCamera(true);
  }

  if (showCamera) {
    return (
      <CameraModal onCancel={() => setShowCamera(false)} onPictureTaken={(image) => addImage(image)}/>
    )
  }

  return (
    <View style={styles.image__container}>
      <TouchableOpacity onPress={() => loadCamera() } style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Ionicons name="camera" size={92} color="black" />
      </TouchableOpacity>

      { images.map((image, idx) => {
        return (
          <TouchableOpacity key={idx} onPress={() => removeImage(image)}>
            <Image
              style={[styles.image]}
              resizeMode={"contain"}
              source={{
                uri: image,
              }}
            />
          </TouchableOpacity>
        )
      })}
    </View>
  )
})

export default function ProofLoadInspect({ navigation }: { navigation: any }) {
  const ref = useRef();
  const proofLoad = useSelector((state: RootState) => state.proofLoad);
  const job = useSelector((state: RootState) => state.job);
  
  const [loading, setLoading] = useState<any>(false);

  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState<any>(0);
  

  useEffect(() => {
    var tabList = [];

    // Load our tabs
    tabList.push({title: "Step 1"})
    tabList.push({title: "Step 2"})

    setTabs(tabList);
    setActiveTab(tabList[0]);
    setTabIndex(0);
  }, []);

  const previous = () => {
    if (tabIndex == 0)
      return;

    var idx = tabIndex - 1;
    setTabIndex(idx);

    updateActiveTab(idx);
  }

  const next = async () => {
    if (tabIndex == tabs.length - 1) {
      save();
      return;
    };

    //@ts-ignore
    var validation = await ref.current.validate();
    if (!validation) {
      return;
    }

    var idx = tabIndex + 1;
    setTabIndex(idx);

    updateActiveTab(idx);
  }


  const save = async () => {
    //setLoading(true);

    const equipmentDatabase = new EquipmentDatabase();
    const equipment = await equipmentDatabase.getById(job.equipmentId);

    const proofLoadDatabase = new ProofLoadDatabase();
    const inspectionDate = moment().format("YYYY-MM-DD");

    try {
      await proofLoadDatabase.addInspection(inspectionDate, proofLoad.proofLoadTestType, proofLoad.additionalDetails, proofLoad.standards,
        proofLoad.quantityToBeTested, equipment.assetNumber, equipment.dateOfManufacture, equipment.description, equipment.swl, equipment.serialNumber,
        job.equipmentId, job.engineerId, job.jobId, job.purposeId, job.colourId, false, proofLoad.loadType, proofLoad.loadValue, proofLoad.drawingRef);
    } catch (e) {
      setLoading(false);
      Alert.alert("Error saving Proof Load. Please try again");
      return;
    }

    setLoading(false);

    Alert.alert("Success", "Successfully saved Proof Load");

    navigation.navigate("JobAssets");
  }

  const updateActiveTab = (idx) => {
    var newTab = tabs[idx];
    setActiveTab(newTab);
  }

  if (tabs.length == 0 || activeTab == null || loading) {
    return <LoadingIndicator/>
  }


  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title={"Proof Load"} showBackButton={true} showSearchButton={false} onSynced={null} promptBack={true}/>

      <View style={styles.tabs}>
        { tabs.map((screen: any, index: number) => {
          return (
            <TouchableOpacity key={index} style={[styles.tab, activeTab.title == screen.title && styles.tab__active]}>
              <Text style={[styles.tab__text, activeTab.title == screen.title && styles.tab__text__active]}>{screen.title}</Text>
            </TouchableOpacity>
          )
        })}
      </View>

      <View style={{ flex: 1 }}>
        {tabIndex == 0 && <Step1Tab ref={ref} />}
        {tabIndex == 1 && <Step2Tab ref={ref} />}
      </View>

      <View style={styles.action__buttons}>
        <TouchableOpacity style={styles.action__button} onPress={() => previous()}>
          <Text style={styles.action__button__text}>PREVIOUS</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.action__button} onPress={() => next()}>
          <Text style={styles.action__button__text}>
            {tabIndex == tabs.length - 1 ? 'SAVE' : 'NEXT'}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 20,
    paddingTop: 40,
    flexDirection: 'column',
  },

  tabs: {
    flexDirection: 'row',
    marginTop: 10,
  },

    tab: {
      flex: 1,
      backgroundColor: colours.primary,
      borderBottomWidth: 1,
      borderBottomColor: colours.primary,
    },

      tab__active: {
        backgroundColor: '#FFF'
      },

      tab__text: {
        color: '#FFF',
        fontFamily: 'OpenSans-Bold',
        fontSize: 16,
        padding: 10,
        textAlign: 'center'
      },

        tab__text__active: {
          color: '#000'
        },

  action__buttons: {
    flexDirection: 'row',
    marginTop: 30,
  },

    action__button: {
      backgroundColor: colours.primary,
      flex: 1,
      marginLeft: 5,
      marginRight: 5,
      borderRadius: 5
    },

      action__button__text: {
        color: '#FFF',
        padding: 10,
        textAlign: 'center',
        fontSize: 22
      },

  row: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    marginBottom: 10,
  },
  
    column: {
      flex: 1,
      padding: 10,
      fontSize: 14,
      textAlign: 'center',
    },

  input__validation: {
    color: 'red',
    marginTop: 10
  },

  question: {
    margin: 5,
    padding: 10,
    backgroundColor: '#FFF',
  },

    question__label: {
      fontWeight: 'bold',
      fontSize: 20,
    },

    question__input: {
      borderWidth: 1,
      borderColor: '#CECECE',
      padding: 10,
      marginTop: 5,
    },

    image__container: {
      flexDirection: 'row',
      padding: 10,
      marginBottom: 20,
      alignItems: 'center'
    },
  
      // image__block: {
      //   flex: 1,
      // },
  
        image: {
          height: 150,
          width: 150,
          marginRight: 20,
          marginLeft: 20,
        },
  
      empty__image: {
        borderWidth: 1,
        borderColor: '#CECECE'
      }
});

