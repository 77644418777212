import { baseApi, PagedTableDto } from './base-api'

export const companyApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `company/getcompanies?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getCompany: build.query<any, any>({
      query: (companyId) => ({
        url: `company/getcompany/${companyId}`,
      }),
    }),
    getAllCompanies: build.query<any, any>({
      query: (companyId) => ({
        url: `company/getallcompanies`,
      }),
    }),
    createCompany: build.mutation<any, any>({
      query: (body) => ({
        url: 'company/addcompany',
        method: 'post',
        body: body
      }),
    }),
    updateCompany: build.mutation<any, any>({
      query: (body) => ({
        url: `company/updatecompany/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncCompanies: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `company/getcompaniesforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    updateCompanyCosts: build.mutation<any, any>({
      query: (companyId) => ({
        url: `company/updatecompanycosts?companyId=${companyId}`,
        method: 'get'
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useGetCompaniesQuery, useGetCompanyQuery, useGetAllCompaniesQuery, 
  useCreateCompanyMutation, useUpdateCompanyMutation,
  useUpdateCompanyCostsMutation
} = companyApi