import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';

import { DataTable } from 'react-native-paper';
import { useIsFocused } from "@react-navigation/native";
import GlobalStyle from '../../../../../constants/GlobalStyle';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { jobApi, useCompleteJobMutation, useDeleteJobMutation } from '@store/services/api/job-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { Picker } from '@react-native-picker/picker';
import { SimpleLineIcons, Ionicons, AntDesign, Entypo } from '@expo/vector-icons';
import { useGetEngineersQuery } from '@store/services/api/user-api';

export default function JobsListScreen({navigation}: {navigation: any}) {
  const isFocused = useIsFocused();
  const [completeJob, completeJobResult] = useCompleteJobMutation();
  const [deleteJob, deleteJobResult] = useDeleteJobMutation();
  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: engineers } = useGetEngineersQuery(null);

  // Filters 
  const [companyId, setCompanyId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [statusId, setStatusId] = useState("0");
  const [engineerId, setEngineerId] = useState("");

  const [page, setPage] = React.useState<number>(0);
  const [trigger, jobsResult] = jobApi.useLazyGetJobsQuery();

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused]);

  const loadTable = () => {
    trigger(new PagedTableDto(page, {
      companyId: companyId, 
      locationId: locationId,
      jobNumber: jobNumber, 
      statusId: statusId,
      engineerId: engineerId
    }));
  }

  const resetFilters = () => {
    setCompanyId("");
    setLocationId("");
    setJobNumber("");
    setStatusId("0");
    setEngineerId("");
  }

  const markJobCompleted = async (id) => {
    if (confirm("Are you sure you want to complete this job?")) {
      var result = await completeJob(id) as any;
      if (result.error) {
        alert("Error completing job. Please try again.")
        return;
      }

      loadTable();
    }
  }

  const markJobDeleted = async (id) => {
    if (confirm("Are you sure you want to delete this job?")) {
      var result = await deleteJob(id) as any;
      if (result.error) {
        alert("Error deleting job. Please try again.")
        return;
      }

      loadTable();
    }
  }


  if (jobsResult.isLoading) {
    return <LoadingIndicator/>
  }


  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}> 
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); }}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Companies" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Locations" value="" />

              { locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job #</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Job #"
              value={jobNumber}
              onChangeText={setJobNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={statusId}
              onValueChange={(itemValue, itemIndex) => setStatusId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="Current" value="0" />
              <Picker.Item label="Completed" value="1" />
              <Picker.Item label="Deleted" value="2" />
              <Picker.Item label="Un-allocated" value="3" />
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}> 
            <Text style={GlobalStyle.column__header}>Engineers</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={engineerId}
              onValueChange={(itemValue, itemIndex) => { setEngineerId(itemValue); }}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Engineers" value="" />

              { engineers != null && engineers.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <TouchableOpacity onPress={() => navigation.navigate("manage")}>
        <Text style={GlobalStyle.add__new__link}>Add New Job</Text>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Company</DataTable.Title>
          <DataTable.Title>Location</DataTable.Title>
          <DataTable.Title>Job Number</DataTable.Title>
          <DataTable.Title>Added</DataTable.Title>
          <DataTable.Title>Job Type</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
        </DataTable.Header>

        { jobsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.jobNumber}</DataTable.Cell>
              <DataTable.Cell>{element.createdOnFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.jobTypeFormatted}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { jobId: element.id })} style={{ marginRight: 20 }}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>
                  
                  <TouchableOpacity onPress={() => markJobCompleted(element.id)} style={{ marginRight: 20 }}>
                    <Ionicons name="checkmark-done-circle-outline" size={24} color="green" />
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => markJobDeleted(element.id)}>
                    <AntDesign name="delete" size={24} color="red" />
                  </TouchableOpacity>
                </View>
              </DataTable.Cell>

              {/* <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("inspect", { jobId: element.id })}>
                  <Text>INSPECT</Text>
                </TouchableOpacity>
              </DataTable.Cell> */}
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={jobsResult?.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={jobsResult?.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
