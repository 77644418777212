import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { RootState } from '@store/index';
import { CategoryDatabase } from '@store/services/database/category-database';
import { DefectsDatabase } from '@store/services/database/defects-database';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { InspectionDatabase } from '@store/services/database/inspection-database';
import { setInspectionData } from '@store/slices/rote-inspection-slice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RoteStep1 from '../../../../../components/management/Inspection/Rote/Step1';
import RoteStep2 from '../../../../../components/management/Inspection/Rote/Step2';
import RoteStep3 from '../../../../../components/management/Inspection/Rote/Step3';
import RoteStep4 from '../../../../../components/management/Inspection/Rote/Step4';
const Stack = createNativeStackNavigator();

export default function RoteInspect({ navigation, route }: { navigation: any, route: any }) {
  const dispatch = useDispatch();
  const inspection = useSelector((state: RootState) => state.roteInspection);
  const job = useSelector((state: RootState) => state.job);

  const [category, setCategory] = useState(null);

  useEffect(() => {
    const loadDefects = async () => {
      // Load equipmnt
      var equipmentDatabase = new EquipmentDatabase();
      var equipment = await equipmentDatabase.getById(job.equipmentId);

      var defectsDatabase = new DefectsDatabase();
      var defects = await defectsDatabase.getDefects(equipment.categoryId);      
      // We want to check if any of these defects are already selected by the equipment
      for (let defect of defects) {
        //TODO: change this to be a defect id in the future instead of name
        let equipmentDefect = equipment.defects.find(d => d.defect == defect.name);
        defect.isSelected = equipmentDefect != null;
      }

      dispatch(setInspectionData({defects: defects}));
    }

    loadDefects();
  }, []);

  useEffect(() => {
    const loadQuestions = async () => {
      const equipmentDatabase = new EquipmentDatabase();
      var equipment = await equipmentDatabase.getById(job.equipmentId);

      const categoryDatabase = new CategoryDatabase();
      var category = await categoryDatabase.getById(equipment.categoryId);

      var inspectionDatabase = new InspectionDatabase();
      var inspectionQuestions = await inspectionDatabase.getInspectionQuestions(category.id);

      for (let question of inspectionQuestions) {
        var equipmentQuestion = equipment.questions.find(q => q.inspectionQuestionId == question.id);
        if (equipmentQuestion != null) question.response = equipmentQuestion.response;
      }

      setCategory(category);
      
      // Group our questions by title
      var questionGroups = [];
      for (let question of inspectionQuestions.sort((a, b) => a.position - b.position)) {
        var group = questionGroups.find(q => q.title == question.title);
        if (group == null) {
          group = { title: question.title, questions: [] }
          questionGroups.push(group);
        }

        group.questions.push(question);
      }
      
      dispatch(setInspectionData({ questions: questionGroups }))
    }

    loadQuestions();
  }, [])


  const submitHandler = async (inspectionData, images) => {
    if (images == null) { images = []; }
    var inspectionDatabase = new InspectionDatabase();

    // Remap questions
    var questions = [];
    for (var group of inspectionData.questions) {
      for (var question of group.questions) {
        questions.push({
          id: question.id,
          question: question.question,
          response: question.response,
          position: question.position
        })
      }
    }

    var defects = inspectionData.defects.filter(d => d.isSelected == true);

    await inspectionDatabase.addInspection(
      inspectionData.inspectionDate, inspectionData.nextInspectionDate, inspectionData.firstExamination, inspectionData.installedCorrectly, 
      defects, inspectionData.identification, inspectionData.particulars, inspectionData.safeForUse, 
      job.equipmentId, job.engineerId, job.jobId, job.ramsId, job.purposeId, job.colourId, false, images, questions);

    navigation.navigate("JobAssets");
  }

  return (
    <Stack.Navigator screenOptions={{ detachInactiveScreens: false }}>
      <Stack.Screen name="Step1" component={() => RoteStep1({ navigation })} options={{ headerShown: false, title: 'Step1'  }} />
      <Stack.Screen name="Step2" component={() => RoteStep2({ navigation, route })} options={{ headerShown: false, title: 'Step2'  }} />
      <Stack.Screen name="Step3" component={() => RoteStep3({ navigation })} options={{ headerShown: false, title: 'Step3'  }} />
      { category != null && <Stack.Screen name="Step4" component={() => RoteStep4({ navigation, category, submitHandler})} options={{ headerShown: false, title: 'Step4'  }} /> }
    </Stack.Navigator>
  )
};