import { createNativeStackNavigator } from '@react-navigation/native-stack';
import WebUserList from './WebUsersList';
import WebUserManage from './WebUsersManage';


const Stack = createNativeStackNavigator();

export default function WebUsers({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={WebUserList} options={{ headerShown: false, title: 'Users'  }} />
      <Stack.Screen name="manage" component={WebUserManage} options={{ headerShown: false, title: 'Users Manage'}}  />
    </Stack.Navigator>
  )
};