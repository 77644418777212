import * as SQLite from 'expo-sqlite';
import * as FileSystem from 'expo-file-system';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

export abstract class Database {
  static _database = null;
  static getDb() {
    if (this._database == null)
      this._database = SQLite.openDatabase('database.db');

    return this._database;
  }

  static deleteDb() {
    var path = `${FileSystem.documentDirectory}/SQLite/database.db`;
    FileSystem.deleteAsync(path);
    this.resetUpdateTimes();
  }

  static resetUpdateTimes() {
    AsyncStorage.clear();
  }

  constructor() {
    this.initialise();
  }

  abstract initialise() : Promise<void>;

  static formatDate(inputDate) {
    const parsedDate = moment(inputDate, 'DD-MM-YYYY', true);
    // Check if the date is valid in the DD/MM/YYYY format
    if (parsedDate.isValid()) {
      // If it's valid, format it to YYYY/MM/DD
      const formattedDate = parsedDate.format('YYYY-MM-DD');
      return formattedDate;
    } else {
      // If it's not valid, return the original date
      return inputDate;
    }
  }
}