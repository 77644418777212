import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Alert, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { TitleBar } from '../../components/TitleBar';
import { AntDesign, MaterialCommunityIcons, SimpleLineIcons } from '@expo/vector-icons';
import moment from "moment";
import { setJobData } from '../../../../store/slices/job-slice';
import { useIsFocused } from '@react-navigation/native';
import { clearInspectionData } from '../../../../store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useGetEquipmentFilesQuery } from '@store/services/api/equipment-api';
import { DataTable } from 'react-native-paper';
import GlobalStyle from '../../../../constants/GlobalStyle';
import { WebView } from 'react-native-webview';
import { baseApi, baseUrl } from '@store/services/api/base-api';

export default function EquipmentViewFilesScreen({navigation, route}: {navigation: any, route: any}) {
  const job = useSelector((state: RootState) => state.job);

  const { data, error, isLoading, refetch } = useGetEquipmentFilesQuery(job.equipmentId);
 
  const [selectedFile, setSelectedFile] = useState(null);

  if (isLoading) {
    return <LoadingIndicator/>
  }

  const setFile = (file) => {
    console.log(`http://docs.google.com/gview?embedded=true&url=${baseUrl}equipmentfiles/${job.equipmentId}/${file.fileName}`);
    setSelectedFile(file);
  }

  if (selectedFile != null) {
    if (selectedFile.mimeType == "application/pdf") {
      if (Platform.OS == "android") {
        return (
          <WebView style={styles.container} source={{ uri: `http://docs.google.com/gview?embedded=true&url=${baseUrl}equipmentfiles/${job.equipmentId}/${selectedFile.fileName}` }}/>
        )
      } else {
        return (
          <WebView style={styles.container} source={{ uri: `${baseUrl}equipmentfiles/${job.equipmentId}/${selectedFile.fileName}` }}/>
        )
      }
    }
  }

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Equipment Files" showBackButton={true} showSearchButton={false} onSynced={null} onSearch={null}/>

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>View</DataTable.Title>
        </DataTable.Header>

        { data?.map((element: any) => {
          return (
            <DataTable.Row key={element.fileName}>
              <DataTable.Cell>{element.fileName}</DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => setFile(element) }>
                  <SimpleLineIcons name="cloud-download" size={18} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}

      </DataTable> 

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },


});
