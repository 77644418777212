import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { useIsFocused } from "@react-navigation/native";
import { locationApi, useCreateLocationMutation, useGetLocationQuery, useUpdateLocationMutation } from '../../../../../../store/services/api/location-api';
import { useGetAllCompaniesQuery } from '../../../../../../store/services/api/company-api';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import Checkbox from "expo-checkbox";
import { colours } from '../../../../../../config';

export default function LocationManageScreen({navigation, route}: {navigation: any, route: any}) {
  const [createLocation, createLocationResult] = useCreateLocationMutation();
  const [updateLocation, updateLocationResult] = useUpdateLocationMutation();

  const [trigger, locationResult] = locationApi.useLazyGetLocationQuery();
  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);

  const [categoryId, setCategoryId] = React.useState("");
  const [locationCosts, setLocationCosts] = React.useState([]);

  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      postcode: "",
      companyId: "",
      jobPrefix: "",
      email: "",
      hideCost: false,
      costRate: "0"
    }
  });

  React.useEffect(() => {
    if (route.params?.locationId != null) {
      trigger(route.params?.locationId);
    }
  }, [])

  React.useEffect(() => {
    if (locationResult.isSuccess) {
      var data = locationResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
      setValue("address1", data.address1);
      setValue("address2", data.address2);
      setValue("address3", data.address3);
      setValue("address4", data.address4);
      setValue("postcode", data.postcode);
      setValue("companyId", data.companyId);
      setValue("jobPrefix", data.jobPrefix);
      setValue("email", data.email);
      setValue("hideCost", data.hideCost);
      setValue("costRate", data.costRate);
    }
  }, [locationResult]);

  React.useEffect(() => {
    if (locationResult.isSuccess && categories != null && categories.length > 0) {
      // Create our location data
      var location = locationResult.data;
      var costs = [];
      for (var locationCost of location.locationCategoryCosts) {
        var cat = categories.find(c => c.id == locationCost.categoryId);

        costs.push({
          id: locationCost.id,
          cost: locationCost.cost,
          categoryId: locationCost.categoryId,
          locationId: location.id,
          name: cat.name
        });
      }

      setLocationCosts([...costs]);
      
    }
  }, [locationResult.isSuccess, categories]);

  const addLocationCost = (categoryId) => {
    var cat = categories.find(c => c.id == categoryId);
    console.log(cat);
    var costs = locationCosts;
    costs.push({ id: null, categoryId: cat.id, cost: cat.cost, name: cat.name });
    setLocationCosts([...locationCosts]);

    setCategoryId(null);
  }

  const updateLocationCost = (category, cost) => {
    category.cost = cost;
    setLocationCosts([...locationCosts]);
  }

  const onSubmit = async (data) => {
    var result = null;

    data.locationCategoryCosts = locationCosts;
    
    if (data.id != null) {
      result = await updateLocation(data) as any;
    } else {
      result = await createLocation(data) as any;
    }
    
    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createLocationResult.isLoading || updateLocationResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("name") == null ? "CREATE LOCATION" : "UPDATE LOCATION"}</Text>
      </View>
      
      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Location Name (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="address1"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address Line 1 (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.address1 && <Text style={GlobalStyle.form__input__validation}>Address Line 1 is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="address2"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address Line 2</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="address3"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address Line 3</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="address4"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Address Line 4</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="postcode"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Postcode (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.postcode && <Text style={GlobalStyle.form__input__validation}>Postcode is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="jobPrefix"
              rules={{ required: false }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Job Prefix (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.jobPrefix && <Text style={GlobalStyle.form__input__validation}>Job Prefix is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="email"
              rules={{ required: false }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Email</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="companyId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) =>
                      onChange(itemValue)
                    }>

                    <Picker.Item label="Please Select" value="" />

                    { companies != null && companies.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="costRate"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Cost Rate (*) (% increase)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.costRate && <Text style={GlobalStyle.form__input__validation}>Cost Rate is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="hideCost"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Hide Costs (*) {value}</Text>
                  <Checkbox value={value}  onValueChange={onChange} color={colours.primary} />
                </View>
              )}
            />
          </View>
        </View>

        <View style={[GlobalStyle.form__column, styles.category__block]}>
          <Text style={GlobalStyle.form__column__text}>Category Costs</Text>
          
          <View style={styles.category__picker}>
            <Picker
              style={GlobalStyle.form__column__input}
              selectedValue={categoryId}
              onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="Please Select" value="" />

              { categories != null && categories.map((value, index) => {
                return (
                  <Picker.Item key={index} label={value.name} value={value.id} />
                )
              })}
            </Picker>

            <TouchableOpacity style={styles.category__picker__button} onPress={() => addLocationCost(categoryId)}>
              <Text style={styles.category__picker__button__text}>Add</Text>                
            </TouchableOpacity>
          </View>

          { locationCosts != null && locationCosts.length > 0 && locationCosts.map((locationCost) => {
            return (
              <View style={GlobalStyle.form__column} key={locationCost.name}>
                <Text style={GlobalStyle.form__column__text}>{locationCost.name}</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(val) => updateLocationCost(locationCost, val) }
                  value={locationCost.cost}>
                </TextInput>
              </View>
            )
          })}
          

        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  category__block: {
    borderTopWidth: 1,
    borderTopColor: '#CECECE',
    paddingTop: 10
  },

  category__picker__button: {
    backgroundColor: PRIMARY_COLOUR,
    borderWidth: 1,
    borderColor: '#CECECE',
    marginTop: 5,
    marginLeft: 10,
  },

    category__picker__button__text: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      color: '#FFF'
    },

  category__picker: {
    flexDirection: 'row',
  },
});
