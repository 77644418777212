import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { setInspectionData } from '@store/slices/rote-inspection-slice';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { colours } from '../../../../config';
import { TitleBar } from '@app/components/TitleBar';
import { useEffect } from 'react';
import { RootState } from '@store/index';

export default function RoteStep1({navigation}: {navigation: any }) {
  const inspection = useSelector((state: RootState) => state.roteInspection);
  const dispatch = useDispatch();


  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      firstExamination: inspection.firstExamination,
      installedCorrectly: inspection.installedCorrectly
    }
  });

  useEffect(() => {
    setValue('firstExamination', inspection.firstExamination);
    setValue('installedCorrectly', inspection.installedCorrectly);
  }, [inspection])

  // On change update our redux state
  const data = watch();
  useEffect(() => {
    setData(data);
  }, [data]);

  const setData = (data) => {
    dispatch(setInspectionData({
      firstExamination: data.firstExamination, 
      installedCorrectly: data.installedCorrectly, 
    }));
  }

  const onSubmit = data => {
    setData(data);
    navigation.navigate("Step2");
  };


  return (
    <View style={[styles.container]}>
      {Platform.OS != 'web' && <TitleBar navigation={navigation} title="Step 1 of 4" showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null}/> }

      <View style={styles.form}>
        <Controller
          control={control}
          rules={{ required: true }}
          name="firstExamination"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Is this the first examination after installation or assembly at a new site or location?</Text>

              <RadioButtonGroup 
                containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                radioBackground={colours.primary}
                selected={value}
                onSelected={onChange}>

                <RadioButtonItem value={0} label="No" />
                <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
              </RadioButtonGroup>

              {errors.firstExamination && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
            </View>
          )}
        />

        { data.firstExamination == true && 
          <Controller
            control={control}
            rules={{ required: true }}
            name="installedCorrectly"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.step__question}>
                <Text style={styles.step__question__label}>Has the equipment been installed correctly?</Text>

                <RadioButtonGroup 
                  containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                  radioBackground={colours.primary}
                  selected={value}
                  onSelected={onChange}>

                  <RadioButtonItem value={0} label="No" />
                  <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
                </RadioButtonGroup>

                {errors.installedCorrectly && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
              </View>
            )}
          />
        }
      </View>

      {Platform.OS != 'web' &&
        <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
          <Text style={styles.proceed__button__text}>NEXT</Text>
        </TouchableOpacity>
      }

    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFAFA',
    padding: 40,
    ...Platform.select({ 
      android: { flex: 1},
      ios: { flex: 1 },
      web: { paddingBottom: 0, paddingTop: 20 }
    })
  },

  form: {
    flex: 1,
    marginTop: 10,
    ...Platform.select({ 
      web: { marginTop: 5 }
    })
  },

    step__question: {
      padding: 20,
      backgroundColor: '#FFF',
      marginBottom: 20,
    },

    step__question__label: {
      fontFamily: 'OpenSans-Light',
      fontSize: 18,
      marginBottom: 5,
    },

    input__validation: {
      color: 'red',
      marginTop: 10
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },
});
