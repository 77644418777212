import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface RoteInspectionState {
  id: string | null,

  // Step 1
  firstExamination: boolean | null,
  installedCorrectly: boolean | null,

  // Step 2
  defects: Array<any> | null,
  questions: Array<any> | null,

  // Step 2
  identification: string | null,
  particulars: string | null,
  safeForUse: boolean | null,

  inspectionDate: string | null,
  nextInspectionDate: string | null,

  images: Array<any> | null,
}

const initialState: RoteInspectionState = {
  id: null,

  firstExamination: null,
  installedCorrectly: false,

  defects: [],
  questions: [],
  
  identification: null,
  particulars: null,
  safeForUse: null,

  inspectionDate: null,
  nextInspectionDate: null,

  images: [],
}

export const roteInspectionSlice = createSlice({
  name: 'roteinspection',
  initialState,
  reducers: {
    updateQuestionData: (state, action: PayloadAction<any>) => {
      var questions = state.questions;
      for (var group of questions) {
        for (var question of group.questions) {
          if (question.id == action.payload.questionId) {
            question.response = action.payload.response;
          }
        }
      }

      state.questions = questions;
    },

    updateQuestionListData: (state, action: PayloadAction<any>) => {
      state.questions = action.payload.questions;
    },

    addDefectData: (state, action: PayloadAction<any>) => {
      state.defects = [...state.defects, action.payload]
    },

    updateDefectData: (state, action: PayloadAction<any>) => {
      var defects = state.defects;
      var defect = defects.find(d => d.id == action.payload.defectId);
      if (defect == null) defect = defects.find(d => d.id == action.payload.id);
      if (defect == null) return;
      
      console.log(action.payload);

      defect.isSelected = action.payload.isSelected;
      if (action.payload.name != null)
        defect.name = action.payload.name;

      state.defects = defects;
    },

    setInspectionData: (state, action: PayloadAction<any>) => {
      const performFullUpdate = action.payload.performFullUpdate != null && action.payload.performFullUpdate == true;

      if (action.payload.id != null) state.id = action.payload.id;

      if (action.payload.firstExamination != null) state.firstExamination = action.payload.firstExamination;
      if (action.payload.installedCorrectly != null) state.installedCorrectly = action.payload.installedCorrectly;

      if (action.payload.defects != null) state.defects = action.payload.defects;
      if (action.payload.questions != null) state.questions = action.payload.questions;

      if (action.payload.identification != null) state.identification = action.payload.identification;
      if (action.payload.particulars != null) state.particulars = action.payload.particulars;
      if (action.payload.safeForUse != null) state.safeForUse = action.payload.safeForUse;

      if (action.payload.images != null) state.images = action.payload.images;
    },

    clearInspectionData: (state, action: PayloadAction) => {
      state.firstExamination = null;
      state.installedCorrectly = null;
      
      state.defects = [];

      state.identification = null;
      state.particulars = null;
      state.safeForUse = null;
      state.questions = [];

      state.images = [];
    },
  }
})

export const { updateQuestionData, updateQuestionListData, addDefectData, updateDefectData, setInspectionData, clearInspectionData } = roteInspectionSlice.actions;

export default roteInspectionSlice.reducer;