import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, ScrollView, Image, Alert, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { Ionicons, MaterialCommunityIcons  } from '@expo/vector-icons';
import { TitleBar } from '../../../../screens/app/components/TitleBar';
import { Controller, useForm } from 'react-hook-form';
import { setInspectionData } from '@store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { Camera, CameraType } from 'expo-camera';
import moment from 'moment';
import { CameraModal } from '@app/components/CameraModal';
import { Dialog } from 'react-native-simple-dialogs';
import GlobalStyle from '../../../../constants/GlobalStyle';

export default function RoteStep4({navigation, category, submitHandler}: {navigation: any, category: any, submitHandler: any}) {
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const inspection = useSelector((state: RootState) => state.roteInspection);
  const job = useSelector((state: RootState) => state.job);
  const dispatch = useDispatch();

  const [showCamera, setShowCamera] = useState(false);
  const [images, setImages] = useState(inspection.images);
  const [imageName, setImageName] = useState(null);
  const [showImageName, setShowImageName] = useState(false);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      identification: null,
      particulars: null,
      safeForUse: null,
    }
  });

  useEffect(() => {
    setValue("identification", inspection.identification);
    setValue("particulars", inspection.particulars);
    setValue("safeForUse", inspection.safeForUse);
  }, [inspection])
  
  
  const data = watch();

  if (Platform.OS == 'web') {
    useEffect(() => {
      dispatch(setInspectionData({
        identification: data.identification, 
        particulars: data.particulars, 
        safeForUse: data.safeForUse,
      }));
    }, [data.identification, data.safeForUse, data.particulars]);
  }

  const addImage = (image) => {
    var img = {
      imageName: imageName,
      base64: image
    }
    
    setImages([...images, img]);
    setShowCamera(false);
  }

  const removeImage = (image) => {
    Alert.alert("Images", "Are you sure you want to remove this image?", [
      {
        text: 'No',
        style: 'cancel'
      },
      { text: 'Yes', onPress: () => {setImages(images.filter(i => i !== image))}}
    ])
  }

  const loadCamera = () => {
    if (images.length >= 3) {
      Alert.alert("You have reached the maximum images.")
      return;
    }

    setImageName(null);
    setShowImageName(true);
    setShowCamera(true);
  }

  const saveInspection = async (data) => {
    var inspectionData = {...inspection};

    var inspectionDate = moment().format("YYYY-MM-DD");
    var nextInspectionDate = moment().format("YYYY-MM-DD");
    if (data.safeForUse) {
      if (job.purposeInterval != 0) {
        nextInspectionDate = moment().add(job.purposeInterval, 'M').format("YYYY-MM-DD");
      }
      else {
        // Get the category and use that interval
        nextInspectionDate = moment().add(category.inspectionInterval, 'M').format("YYYY-MM-DD");

        // Check if we've got a time to rectify
        var defects = inspection.defects.filter(d => d.defectGroup == 1 && d.isSelected);
        if (defects.length > 0) {
          var timeDefects = defects.filter(d => d.timeToRectify > 0).map(d => parseInt(d.timeToRectify));
          var time = Math.min(...timeDefects);
          if (time != null && time > 0) {
            nextInspectionDate = moment().add(time, "weeks").format("DD-MM-YYYY");
          }
        }
      }
    }

    inspectionData.inspectionDate = inspectionDate;
    inspectionData.nextInspectionDate = nextInspectionDate;

    inspectionData.identification = data.identification;
    inspectionData.particulars = data.particulars;
    inspectionData.safeForUse = data.safeForUse;

    submitHandler(inspectionData, images);
  }

  const onSubmit = async data => {
    if (Platform.OS != "web") {
      if (data.safeForUse == null) {
        Alert.alert("Inspection", "Select inspection status");
        return;
      }

      Alert.alert('Inspection', `Are you sure this is a ${data.safeForUse ? 'PASS' : 'FAIL'}`, [
        {
          text: 'NO',
        },
        { text: 'YES', onPress: () => saveInspection(data)},
      ]);
    } else {
      saveInspection(data);
    }
  };
   
  const onBack = () => {
    dispatch(setInspectionData({
      identification: data.identification, 
      particulars: data.particulars, 
      safeForUse: data.safeForUse,
      images: images,
    }));

    navigation.navigate("Step3");
  }

  if (showCamera) {
    return (
      <View style={{ width: '100%', height: '100%' }}>
        <Dialog visible={showImageName} title="Image Name" dialogStyle={{ width: '50%', alignSelf: 'center', margin: 'auto', padding: 5 }}>
          <View>
            <TextInput placeholder='Enter image name...' style={GlobalStyle.form__column__input} onChangeText={(text) => setImageName(text)}/>
            <TouchableOpacity style={styles.proceed__button} onPress={() => setShowImageName(false)}>
              <Text style={styles.proceed__button__text}>CONFIRM</Text>
            </TouchableOpacity>
          </View>
        </Dialog>
        <CameraModal onCancel={() => setShowCamera(false)} onPictureTaken={(image) => addImage(image)}/>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      { Platform.OS != 'web' && 
        <TitleBar navigation={navigation} title="Step 4 of 4" 
          showSearchButton={false} showBackButton={true} 
          onSynced={null} onSearch={null}
          backButtonDelegate={onBack}/> 
      }

      <ScrollView style={styles.form}>
        {/* { nextInspectionDate != null && 
          <View style={{ margin: 10, padding: 10, backgroundColor: '#e8e8e8', borderRadius: 5, flexDirection: 'column', alignItems: 'center'}}>
            <Text style={{ fontSize: 16 }}>NEXT INSPECTION DATE</Text>
            <Text style={{ fontSize: 44 }}>{ nextInspectionDate }</Text>
          </View>
        } */}

        <Controller
          control={control}
          name="identification"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Identification of any parts not accessible for examination:</Text>

              <TextInput
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        <Controller
          control={control}
          name="particulars"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Particulars of any test carried out as part of this examination or additional information:</Text>

              <TextInput
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        {Platform.OS != 'web' && 
          <View style={styles.image__container}>
            <TouchableOpacity onPress={() => loadCamera() } style={{ paddingLeft: 30, paddingRight: 30 }}>
              <Ionicons name="camera" size={92} color="black" />
            </TouchableOpacity>

            { images.map((image, idx) => {
              return (
                <TouchableOpacity key={idx} onPress={() => removeImage(image)}>
                  <Image
                    style={[styles.image]}
                    resizeMode={"contain"}
                    source={{
                      uri: image.base64,
                    }}
                  />
                </TouchableOpacity>
              )
            })}
          </View>
        }

        <Controller
          control={control}
          name="safeForUse"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.safe__block}>
              <TouchableOpacity style={[styles.safe__button, styles.not_safe, data.safeForUse == false && styles.unsafe_checked]} onPress={() => onChange(false)}>
                <MaterialCommunityIcons name="cancel" size={44} color={data.safeForUse == false ? 'white' : 'black'}/>
                <Text style={[styles.safe__button__text, data.safeForUse == false && styles.safe__button__text__checked]}>NOT SAFE FOR USE </Text>
              </TouchableOpacity>

              <TouchableOpacity style={[styles.safe__button, styles.safe, data.safeForUse == true && styles.safe_checked]} onPress={() => onChange(true)}>
                <Ionicons name="checkmark-circle-outline" size={44} color={data.safeForUse == true ? 'white' : 'black'} />
                <Text style={[styles.safe__button__text, data.safeForUse == true && styles.safe__button__text__checked]}>SAFE FOR USE</Text>
              </TouchableOpacity>
            </View>
          )}
        />


      </ScrollView>

      <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
        <Text style={styles.proceed__button__text}>SAVE</Text>
      </TouchableOpacity>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFAFA',
    padding: 40,
    ...Platform.select({ 
      android: { flex: 1},
      ios: { flex: 1 },
      web: { paddingBottom: 100, paddingTop: 0 }
    })
  },

  form: {
    flex: 1,
    marginTop: 10,
    ...Platform.select({ 
      web: { marginTop: 5 }
    })
  },

    step__question: {
      padding: 20,
      backgroundColor: '#FFF',
      marginBottom: 20,
    },

    step__question__label: {
      fontFamily: 'OpenSans-Light',
      fontSize: 18,
      marginBottom: 5,
    },

    step__question__input: {
      borderWidth: 1,
      borderColor: '#CECECE',
      padding: 10,
      marginTop: 5,
    },

    input__validation: {
      color: 'red',
      marginTop: 10
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },

  safe__block: {
    flexDirection: 'row',
  },

  safe__button: {
    flex: 1,
    height: 140,
    backgroundColor: '#edebeb',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 5,
  },

    safe__button__text: {
      fontFamily: 'OpenSans-Bold',
      marginLeft: 10,
      fontSize: 38
    },

    not_safe: {
      marginRight: 10,
    },

    safe: {
      marginLeft: 10,
    },

    unsafe_checked: {
      backgroundColor: 'red',
    },

    safe_checked: {
      backgroundColor: 'green',
    },

    safe__button__text__checked: {
      color: '#FFF'
    },

  image__container: {
    flexDirection: 'row',
    padding: 10,
    marginBottom: 20,
    alignItems: 'center'
  },

    // image__block: {
    //   flex: 1,
    // },

      image: {
        height: 150,
        width: 150,
        marginRight: 20,
        marginLeft: 20,
      },

    empty__image: {
      borderWidth: 1,
      borderColor: '#CECECE'
    }
});
