import { baseApi, PagedTableDto } from './base-api'

export const categoryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `category/getcategories?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getCategory: build.query<any, any>({
      query: (categoryId) => ({
        url: `category/getcategory/${categoryId}`,
      }),
    }),
    getAllCategories: build.query<any, any>({
      query: (companyId) => ({
        url: `category/getallcategories`,
      }),
    }),
    getAllMasterCategories: build.query<any, any>({
      query: (companyId) => ({
        url: `category/getallmastercategories`,
      }),
    }),
    createCategory: build.mutation<any, any>({
      query: (body) => ({
        url: 'category/addcategory',
        method: 'post',
        body: body
      }),
    }),
    updateCategory: build.mutation<any, any>({
      query: (body) => ({
        url: `category/updatecategory/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncCategories: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `category/getcategoriesforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncMasterCategories: build.query<any, any>({
      query: (lastModifiedOn) => ({
        url: `category/getmastercategoriesforsync?lastmodifiedon=${lastModifiedOn}`,
      }),
    }),
  }),
  overrideExisting: true,
})

export const { useGetCategoriesQuery, useGetCategoryQuery, useGetAllCategoriesQuery, useGetAllMasterCategoriesQuery, useCreateCategoryMutation, useUpdateCategoryMutation, useLazyGetAllMasterCategoriesQuery } = categoryApi