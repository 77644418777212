import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Touchable, Modal } from 'react-native';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, SimpleLineIcons, AntDesign, Ionicons   } from '@expo/vector-icons';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { equipmentApi, useAddFileMutation, useGetEquipmentFilesQuery, useRemoveFileMutation } from '@store/services/api/equipment-api';
import { PagedTableDto } from '@store/services/api/base-api';
import moment from 'moment';
import * as DocumentPicker from 'expo-document-picker';
import { convertBase64toBlob } from '../../../../../../components/helpers/Extensions';
import { DataTableHeader } from '@web/components/table/DataTableHeader';

export const EquipmentFileManager = ({ equipmentId, onCancel }: { equipmentId: any, onCancel: any }) => {
  const [addFile, addFileResult] = useAddFileMutation();
  const [removeFile, removeFileResult] = useRemoveFileMutation();
  const [trigger, filesResult] = equipmentApi.useLazyGetEquipmentFilesQuery();

  React.useEffect(() => {
    trigger(equipmentId);
  }, [])
  
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({
      base64: reader.result,
      fileName: file.name
    });
    reader.onerror = error => reject(error);
  });

  const uploadFile = async () => {
    if (filesResult?.data?.length >= 5) {
      alert("Maximum 5 files allowed");
      return;
    }

    var result = await DocumentPicker.getDocumentAsync({
      type: ".pdf"
    }) as any;

    const file = result.file;
    var base64File = await toBase64(file) as any;

    var dto = {
      equipmentId: equipmentId,
      fileName: base64File.fileName,
      base64: base64File.base64
    }

    var result = null;
    result = await addFile(dto) as any;
    if (result.error) {
      console.log(result);
      return;
    }

    trigger(equipmentId);
  }

  const download = (file) => {
    let blob = convertBase64toBlob(file.base64, file.mimeType);

    const fileSaver = require('file-saver');
    fileSaver(blob, file.fileName);
  }

  const remove = async (file) => {
    if (confirm("Are you sure you want to delete this file?")) {
      var result = null;
      result = await removeFile({ equipmentId: equipmentId, fileName: file.fileName }) as any;
      if (result.error) {
        console.log(result);
        return;
      }

      trigger(equipmentId);
    }
  }
  

  return (
    <View>
      <View style={{ flexDirection: 'row', borderBottomColor: '#CECECE', borderBottomWidth: 1, paddingBottom: 5 }}>
        <Text style={{ fontWeight: 'bold', fontSize: 18, flex: 1 }}>Equipment Manager</Text>
        <TouchableOpacity onPress={onCancel}>
          <Ionicons name="ios-close-circle-sharp" size={24} color="black" />
        </TouchableOpacity>
      </View>

      <TouchableOpacity style={styles.upload__button} onPress={() => uploadFile()}>
        <Text style={styles.upload__button__text}>Upload File</Text>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>Download</DataTable.Title>
          <DataTable.Title>Delete</DataTable.Title>
        </DataTable.Header>

        { filesResult?.data?.map((element: any) => {
          return (
            <DataTable.Row key={element.fileName}>
              <DataTable.Cell>{element.fileName}</DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => download(element)}>
                  <SimpleLineIcons name="cloud-download" size={18} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => remove(element)}>
                  <SimpleLineIcons name="trash" size={18} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>
    </View>
  )
}

export default function EquipmentsListScreen({navigation}: {navigation: any}) {
  const isFocused = useIsFocused()

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);

  const optionsPerPage = [5, 10, 50];
  const [page, setPage] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const [sortedColumn, setSortedColumn] = React.useState<string>("companyName");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null);

  // Filters 
  const [companyId, setCompanyId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [description, setDescription] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [trigger, equipmentResult] =  equipmentApi.useLazyGetEquipmentsQuery();

  const loadTable = () => {
    var dto = new PagedTableDto(page, { 
      companyId: companyId,
      locationId: locationId,
      categoryId: categoryId,
      serialNumber: serialNumber,
      description: description,
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const resetFilters = () => {
    setCompanyId("");
    setLocationId("");
    setDescription("");
    setSerialNumber("");
    setCategoryId("");
  }
  
  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused, sortedColumn, sortedColumnAscending]);

  if (equipmentResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={selectedEquipmentId != null}>

        <View style={styles.modal__block}>
          <View style={styles.modal}>
            <EquipmentFileManager equipmentId={selectedEquipmentId} onCancel={() => setSelectedEquipmentId(null)}/>
          </View>
        </View>

      </Modal>

      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); }}>

              <Picker.Item label="All Companies" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

              <Picker.Item label="All Locations" value="" />

              { locations != null && locations.filter(l => companyId == "" || l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Categories</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={categoryId}
              onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}>

              <Picker.Item label="All Categories" value="" />

              { categories != null && categories.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Serial / Asset</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Serial / Asset Number"
              onChangeText={setSerialNumber}
              value={serialNumber}
            />
          </View>

          
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Description</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Description"
              onChangeText={setDescription}
              value={description}
            />
          </View>
         
          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row'}}>
        <TouchableOpacity style={{ marginRight: 20 }} onPress={() => navigation.navigate("manage")}>
          <Text style={GlobalStyle.add__new__link}>Add New Equipment</Text>
        </TouchableOpacity>

        <TouchableOpacity style={{ marginRight: 20 }} onPress={() => navigation.navigate("massupload")}>
          <Text style={GlobalStyle.add__new__link}>Upload from Excel</Text>
        </TouchableOpacity>
      </View>

   

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Upload', visible: true },
            { text: 'Company', sortedName: "companyName", visible: true },
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Category', sortedName: "categoryName", visible: true },
            { text: 'Date Of Manufacture', sortedName: "dateOfManufacture", visible: true },
            { text: 'Manufacturer', sortedName: "manufacturerName", visible: true },
            { text: 'Option', visible: true },
          ]}
        />
        {/* <DataTable.Header>
          <DataTable.Title>Upload</DataTable.Title>
          <DataTable.Title>Company</DataTable.Title>
          <DataTable.Title>Location</DataTable.Title>
          <DataTable.Title>Description</DataTable.Title>
          <DataTable.Title>Serial Number</DataTable.Title>
          <DataTable.Title>Category</DataTable.Title>
          <DataTable.Title>Date of Manufacture</DataTable.Title>
          <DataTable.Title>Manufacturer</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
        </DataTable.Header> */}

        { equipmentResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => setSelectedEquipmentId(element.id)}>
                  <AntDesign name="folder1" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.dateOfManufacture == "" ? "N/A" : moment(element.dateOfManufacture).format("DD/MM/YYYY")}</DataTable.Cell>
              <DataTable.Cell>{element.manufacturerName}</DataTable.Cell>
              <DataTable.Cell>
                <TouchableOpacity onPress={() => navigation.navigate("manage", { equipmentId: element.id })}>
                  <Entypo name="pencil" size={24} color="black" />
                </TouchableOpacity>
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={equipmentResult?.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={equipmentResult?.data?.pagination.pageSize}
        setItemsPerPage={setItemsPerPage}
        showFastPagination
        optionsLabel={'Rows per page'}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

    modal: {
      backgroundColor: '#FFF',
      padding: 10,
      width: 600,
      borderRadius: 5,

      flexDirection: 'column',
    },

  upload__button: {
    marginBottom: 10,
    backgroundColor: PRIMARY_COLOUR,
    width: 200,
    marginTop: 10,
  },

    upload__button__text: {
      fontFamily: 'OpenSans-Bold',
      padding: 5,
      color: '#FFF',
      textAlign: 'center',
      fontSize: 14,
    },
});
