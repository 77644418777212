import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Picker } from '@react-native-picker/picker';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import moment from 'moment';
import { RootState } from '@store/index';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';

import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { useDispatch, useSelector } from 'react-redux';
import { PagedTableDto } from '@store/services/api/base-api';
import { inspectionApi } from '@store/services/api/inspection-api';
import GlobalStyle from '../../../../../constants/GlobalStyle';
import DateTimePicker from '@web/components/navigation/controls/DateTimePicker';
import { SimpleLineIcons } from '@expo/vector-icons';
import { DataTable } from 'react-native-paper';
import { convertBase64toBlob } from '../../../../../components/helpers/Extensions';
import { DataTableHeader } from '@web/components/table/DataTableHeader';

export default function InspectionCostsScreen({ navigation }: { navigation: any }) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);

  const [page, setPage] = React.useState<number>(0);
  const [sortedColumn, setSortedColumn] = React.useState<string>("assetNumber");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

  const [trigger, inspectionResult] = inspectionApi.useLazyGetInspectionsCostsQuery();
  const [triggerExport, exportResult] = inspectionApi.useLazyExportInspectionsCostsQuery();

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState(moment().add(-6, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [jobNumber, setJobNumber] = useState("");



  useEffect(() => {
    if (exportResult.status == "fulfilled" && exportResult.data != null) {
      let blob = convertBase64toBlob(exportResult.data.base64, "application/vnd.ms-excel");
      const fileSaver = require('file-saver');
      fileSaver(blob, "report.xlsx");
    }
  }, [exportResult])

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  const resetFilters = () => {
    setCompanyId(user.companyId);
    setLocationId("");
    setDateRange("");
    setStartDate(moment().add(-6, 'months').toDate());
    setEndDate(moment().toDate());
    setJobNumber("");
  }

  const loadTable = () => {
    if (companyId == null) {
      alert("Select company");
      return;
    }
    var dto = new PagedTableDto(page, { 
      companyId: companyId,
      locationId: locationId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      jobNumber: jobNumber,
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  React.useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending]);

  const exportTable = () => {
    if (inspectionResult.data.list.count == 0) {
      return;
    }

    var dto = new PagedTableDto(page, { 
      companyId: companyId,
      locationId: locationId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      jobNumber: jobNumber,
    }, sortedColumn, sortedColumnAscending);
    
    triggerExport(dto);
  }

  if (inspectionResult.isLoading || exportResult.isLoading) {
    return <LoadingIndicator />
  }


  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              enabled={user.role != 'User'}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); }}>

              <Picker.Item label="Please Select" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

              <Picker.Item label="Please Select" value="" />

              { locations != null && locations.filter(l => companyId == "" || l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChangeText={setJobNumber}
              value={jobNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}></View>
        </View>
        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Date Range</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={dateRange}
              onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

              <Picker.Item label="Predefined Date Range" value="" />
              <Picker.Item label="1 Week" value="-1" />
              <Picker.Item label="1 Month" value="1" />
              <Picker.Item label="3 Months" value="3" />
              <Picker.Item label="6 Months" value="6" />
              <Picker.Item label="12 Months" value="12" />
              
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Start Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChange={setStartDate}
              value={startDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>End Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input,]} 
              onChange={setEndDate}
              value={endDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      { inspectionResult.data?.list.length > 0 && 
        <View style={{ flexDirection: 'row'}}>
          <TouchableOpacity style={{ marginRight: 20 }} onPress={() => exportTable()}>
            <Text style={GlobalStyle.add__new__link}>Export</Text>
          </TouchableOpacity>
        </View> 
      }

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Asset Number', sortedName: "assetNumber", visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Job Number', sortedName: "jobNumber", visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Inspection Date', sortedName: "inspectionDateFormatted", visible: true },
            { text: 'Purpose Of Examination', sortedName: "purposeOfExamination", visible: true },
            { text: 'Item Cost', sortedName: "itemCostFormatted", visible: true },
          ]}
        />

        { inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.assetNumber}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.jobNumber}</DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.inspectionDateFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.purposeOfExamination}</DataTable.Cell>
              <DataTable.Cell>{element.itemCostFormatted}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={inspectionResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={inspectionResult.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

});
