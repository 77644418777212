import { Feather, EvilIcons } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import DateTimePicker from "@web/components/navigation/controls/DateTimePicker";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Platform, FlatList } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from "../../constants/GlobalStyle";
import Checkbox from "expo-checkbox";
import { colours } from "../../config";
import { store } from "@store/index";
import { equipmentApi } from "@store/services/api/equipment-api";


export const JobManageControl = ({ companies, locations, allEngineers, job, onSubmit }) => {
  const [engineerId, setEngineerId] = React.useState("");
  const [equipmentList, setEquipmentList] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [selectAll, setSelectAll] = React.useState(false);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      companyId: "",
      locationId: "",
      jobType: "0",
      scheduledDate: new Date(Date.now()),
      notes: "",
      engineerIds: [],
      engineers: [],
      autoReschedule: true,
    }
  });

  React.useEffect(() => {
    if (job != null && allEngineers != null && allEngineers.length > 0) {
      setValue("id", job.id);
      setValue("companyId", job.companyId);
      setValue("locationId", job.locationId);
      setValue("jobType", job.jobType);
      setValue("scheduledDate", moment(job.scheduledDate).toDate());
      setValue("notes", job.notes);
      setValue("autoReschedule", job.autoReschedule);

      console.log(job.engineerIds);
      for (let engineerId of job.engineerIds) {
        addEngineer(engineerId);
      }
    }
  }, [job, allEngineers])

  const jobId = watch("id");
  const companyId = watch("companyId");
  const locationId = watch("locationId");
  const engineersList = watch("engineers");

  if (Platform.OS == "web") {
    React.useEffect(() => {
      const loadEquipment = async () => {
        var result = await store.dispatch(equipmentApi.endpoints.getEquipmentByLocationId.initiate(locationId)) as any;

        var equipment = JSON.parse(JSON.stringify(result.data.slice().sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))));
        if (job == null) { job = {}; }
        if (job.assetsIds != null) {
          for (let assetId of job.assetsIds) {
            var equip = equipment.find(e => e.id == assetId);
            if (equip == null) continue;
            equip.isSelected = true;
          }
        }
        setEquipmentList(equipment);
      }

      if (locationId == null || locationId == "") {
        setEquipmentList([]);
        return;
      }
      
      loadEquipment();
    }, [locationId])
  }

  useEffect(() => {
    if (equipmentList != null && equipmentList.length > 0) {
      for (var equip of equipmentList) { equip.isSelected = selectAll; }
      setEquipmentList([...equipmentList]);
    }
  }, [selectAll])

  const submit = async (data) => {
    data.jobType = parseInt(data.jobType);
    data.equipmentIds = equipmentList.filter(e => e.isSelected == true).map(e => e.id);

    onSubmit(data);
  }

  const addEngineer = (engineerIdToAdd) => {
    if (engineerIdToAdd == null || engineerIdToAdd == "")
      return;

    var engineerIds = getValues("engineerIds");
    var formEngineers = getValues("engineers");

    const engineer = allEngineers.find(e => e.id == engineerIdToAdd);
    if (engineer == null) return;
    
    if (engineerIds.filter(e => e.id == engineer.id).length > 0)
      return;

    engineerIds = [...engineerIds, engineer.id];    
    setValue("engineerIds", engineerIds);

    formEngineers = [...formEngineers, engineer];    
    setValue("engineers", allEngineers.filter(e => engineerIds.includes(e.id)));
  }

  const removeEngineer = (engineer) => {
    var engineerIds = getValues("engineerIds");
    var formEngineers = getValues("engineers");

    engineerIds = engineerIds.filter(e => e != engineer.id);
    setValue("engineerIds", engineerIds);

    formEngineers = formEngineers.filter(e => e.id != engineer.id);
    setValue("engineers", formEngineers);
  }

  const toggleEquipment = (equipment) => {
    var isSelected = equipment.isSelected == null ? false : equipment.isSelected;
    isSelected = !isSelected;

    setEquipmentList(equipmentList => {
      return equipmentList.map((item) => {
        if (item.id == equipment.id) { return { ...item, isSelected: isSelected }; }
        return item;
      })
    }) 
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.form}>
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="companyId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    enabled={jobId == null}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { companies != null && companies.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="locationId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Location (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    enabled={jobId == null}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.locationId && <Text style={GlobalStyle.form__input__validation}>Location is required.</Text>}
          </View>

          {/* <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="jobType"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Job Type (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    enabled={jobId == null}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />
                    <Picker.Item key={0} label={"ROTE"} value={0} />
                    <Picker.Item key={1} label={"Servicing"} value={1} />
                    <Picker.Item key={2} label={"Repair/Breakdown"} value={2} />
                    <Picker.Item key={3} label={"Proof Load"} value={3} />

                  </Picker>
                </View>
              )}
            />

            {errors.jobType && <Text style={GlobalStyle.form__input__validation}>Job Type is required.</Text>}
          </View> */}

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="notes"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Notes</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          {Platform.OS == "web" &&
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="scheduledDate"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Scheduled Date</Text>
                    <DateTimePicker
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}>
                    </DateTimePicker>
                  </View>
                )}
              />
            </View>
          }

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="autoReschedule"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Auto Reschedule</Text>
                  <Checkbox style={{ marginTop: 10 }} value={value} onValueChange={onChange} color={colours.primary} />
                </View>
              )}
            />
          </View>
          
        </View>

        {allEngineers != null && allEngineers.length > 0 && 
          <View style={styles.engineer__picker__list}>
            <Text>Engineers</Text>
            <View style={styles.engineer__picker}>
              <Picker
                style={GlobalStyle.form__column__input}
                selectedValue={engineerId}
                onValueChange={(itemValue, itemIndex) => setEngineerId(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                { allEngineers != null && allEngineers.map((value, index) => {
                  return (
                    <Picker.Item key={index} label={value.name} value={value.id} />
                  )
                })}
              </Picker>

              <TouchableOpacity style={styles.engineer__picker__button} onPress={() => addEngineer(engineerId)}>
                <Text style={styles.engineer__picker__button__text}>Add</Text>                
              </TouchableOpacity>
            </View>

            <View>
              { engineersList != null && engineersList.map((element: any) => {
                return (
                  <View style={styles.engineer__row} key={element.id}>
                    <Text style={styles.engineer__row__text}>{element.name}</Text>
                    <TouchableOpacity onPress={() => removeEngineer(element)}>
                      <Feather name="trash" size={20} color="red" />
                    </TouchableOpacity>
                  </View>
                )
              })}
            </View>
          </View>
        }

        {equipmentList != null && equipmentList.length > 0 &&
          <View style={styles.equipment__picker__list}>
            <Text>Equipment List</Text>
            <View style={styles.search__bar}>
              <TextInput onChangeText={setSearch} style={styles.search__input} placeholder="Search"/>
            </View>

            <Checkbox style={{ margin: 10 }} value={selectAll} onValueChange={() => setSelectAll(!selectAll) } color={colours.primary} />
            <FlatList
              data={search == null || search == '' ? equipmentList : equipmentList.filter(e => 
                e.serialNumber.toLowerCase().includes(search.toLowerCase()) ||
                e.description.toLowerCase().includes(search.toLowerCase()) ||
                e.categoryName.toLowerCase().includes(search.toLowerCase())
              )}
              renderItem={({item}) => 
                <View style={styles.equipment__item}>
                  <Checkbox style={{ marginRight: 10 }} value={item.isSelected} onValueChange={() => toggleEquipment(item)} color={colours.primary} />
                  <Text>{item.serialNumber} - {item.description} - { item.categoryName }</Text>
                </View>
              }
            />
          </View>
        }

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(submit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
};


const styles = StyleSheet.create({
  container: {
    //flex: 1,
    //position: 'relative',
    padding: 10,
  },

  engineer__picker: {
    flexDirection: 'row',
  },

    engineer__picker__button: {
      backgroundColor: PRIMARY_COLOUR,
      borderWidth: 1,
      borderColor: '#CECECE',
      marginTop: 5,
      marginLeft: 10,
    },

      engineer__picker__button__text: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        color: '#FFF'
      },
      
  engineer__picker__list: {
    margin: 5,
  },

    engineer__row: {
      marginTop: 5,
      marginBottom: 5,
      backgroundColor: '#f5f0f0',
      padding: 5,
      
      flexDirection: 'row',
      alignItems: 'center',
    },

      engineer__row__text: {
        fontFamily: 'OpenSans-Bold',
        padding: 5,
        marignRight: 10,
      },
    
  equipment__picker__list: {
    margin: 5,
    maxHeight: 350
  },

    equipment__item: {
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      backgroundColor: '#f5f5f5',
      marginBottom: 5,
      alignItems: 'center'
    },

    search__bar: {
      backgroundColor: '#FFF',
      alignItems: 'center',
      width: 350,
      flexDirection: 'row',
      borderRadius: 5,
      borderWidth: 1,
      marginTop: 5,
      marginBottom: 5,
      borderColor: '#CECECE'
    },

      search__input: {
        padding: 5,
        flex: 1,
        fontFamily: 'OpenSans-Regular',
      },

      search__button: {
        backgroundColor: config.colours.primary,
        height: 35,
        width: 35,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
      },
});
