import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, Alert } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { SyncService } from '@store/services/sync-service';
import { JobGridStats } from './components/JobGridStats';
import { JobsList } from './components/Jobs';
import { TitleBar } from '../../components/TitleBar';
import { useIsFocused } from '@react-navigation/native';
import { JobManageControl } from '../../../../components/management/JobManageControl';
import { CompanyDatabase } from '@store/services/database/company-database';
import { LocationDatabase } from '@store/services/database/location-database';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import uuid from 'react-native-uuid';
import moment from 'moment';

export default function JobManageScreen({navigation}: {navigation: any}) {
  const isFocused = useIsFocused();
  const user = useSelector((state: RootState) => state.user.user);
  const jobDatabase = new JobDatabase();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [companies, setCompanies] = React.useState<any>([]);
  const [locations, setLocations] = React.useState<any>([]);

  useEffect(() => {
    const loadData = async () => {
      var companyDatabase = new CompanyDatabase();
      var companies = await companyDatabase.getAllCompanies();
      setCompanies(companies);

      var locationDatabase = new LocationDatabase();
      var locations = await locationDatabase.getAllLocations();
      setLocations(locations);
    }

    loadData();
    
  }, []);

  const onSubmit = async (data) => {
    // Create our job and assets
    var equipmentDatabase = new EquipmentDatabase();
    var equipment = await equipmentDatabase.getByLocationId(data.locationId);
    
    // Create a job
    var job = {
      id: uuid.v4(),
      locationId: data.locationId,
      jobType: data.jobType,
      scheduledDate: moment(data.scheduledDate).format("YYYY-MM-DD"),
      notes: data.notes,
      createdOn: moment(data.scheduledDate).format("YYYY-MM-DD"),
      purposeId: null,
      colourId: null,
      ramsId: null,
      engineerReportId: null,
      isCompleted: false,
      jobNumber: "",
      jobAssets: [],
      engineerIds: [],
      isDeleted: false,
      autoReschedule: data.autoReschedule
    };

    console.log(job);

    for (let equip of equipment) {
      job.jobAssets.push({
        id: uuid.v4(),
        equipmentId: equip.id,
        completed: false,
        jobId: job.id
      });
    }

    job.engineerIds.push(user.id);
    var jobDatabase = new JobDatabase();
    await jobDatabase.insertOrUpdateList([job], true);

    Alert.alert("Job", "Successfully created job");
    navigation.goBack();
  };

  if (isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} 
        title="Create Job" 
        showSearchButton={false} 
        showBackButton={true}
        onSearch={null} 
        onSynced={null} />

        <View>
          <JobManageControl companies={companies} locations={locations} allEngineers={[]} job={null} onSubmit={onSubmit}/> 
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 20,
    paddingTop: 40,
  },

});
