import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Alert, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { TitleBar } from '../../components/TitleBar';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import moment from "moment";
import { setJobData } from '../../../../store/slices/job-slice';
import { useIsFocused } from '@react-navigation/native';
import { clearInspectionData } from '../../../../store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { CategoryDatabase } from '@store/services/database/category-database';
import { EquipmentManageControl } from '../../../../components/management/EquipmentManageControl';
import { ManufacturerDatabase } from '@store/services/database/manufacturer-database';
import { CompanyDatabase } from '@store/services/database/company-database';
import { LocationDatabase } from '@store/services/database/location-database';
import uuid from 'react-native-uuid';

export default function EquipmentManageScreen({navigation, route}: {navigation: any, route: any}) {
  const isFocused = useIsFocused();
//   const dispatch = useDispatch();
//   const user = useSelector((state: RootState) => state.user.user);
  const job = useSelector((state: RootState) => state.job);

  const [equipment, setEquipment] = React.useState<any>(null);
  const [companies, setCompanies] = React.useState<any>([]);
  const [locations, setLocations] = React.useState<any>([]);
  const [masterCategories, setMasterCategories] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);
  const [manufacturers, setManufacturers] = React.useState<any>([]);

  const equipmentDatabase = new EquipmentDatabase();
  const jobDatabase = new JobDatabase();


  const loadEquipment = async () => {
    const companyDatabase = new CompanyDatabase();
    const companies = await companyDatabase.getAllCompanies();
    setCompanies(companies);

    const locationDatabase = new LocationDatabase();
    const locations = await locationDatabase.getAllLocations();
    setLocations(locations);

    const categoryDatabase = new CategoryDatabase();
    const masterCategories = await categoryDatabase.getAllMasterCategories();
    setMasterCategories(masterCategories);

    const categories = await categoryDatabase.getAllCategories();
    setCategories(categories);

    const manufacturerDatabase = new ManufacturerDatabase();
    const manufacturers = await manufacturerDatabase.getAllManufacturers();
    setManufacturers(manufacturers);

    var equip = null;

    if (route.params?.equipmentId != null) {
      equip = await equipmentDatabase.getById(route.params?.equipmentId);

      var selectedLocation = locations.find(l => l.id == equip.locationId);
      equip.companyId = selectedLocation.companyId;
  
      var selectedCategory = categories.find(c => c.id == equip.categoryId);
      equip.masterCategoryId = selectedCategory.masterCategoryId;  
    } else {
      equip = {
        id: null,
        companyId: job.companyId,
        locationId: job.locationId
      }
    }

    setEquipment(equip);
  }

  useEffect(() => {
    const load = async () => {
      await loadEquipment();   
    }

    load();
  }, [isFocused]);

  const cancel = () => {
    navigation.goBack();
  }

  const submit = async (equipment, isNew) => {
    if (isNew) { equipment.id = uuid.v4(); }
    var entities = [equipment];
    
    await equipmentDatabase.insertOrUpdateList(entities, true);

    // Create a job record for this item
    if (isNew && !equipment.doc) {
      await jobDatabase.createJobAsset(equipment.id, job.jobId);
    }

    navigation.goBack();
  }

  if (equipment == null) {
    return <LoadingIndicator/>
  }


  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Equipment Manage" showBackButton={true} showSearchButton={false} onSynced={null} onSearch={null}/>

      <ScrollView>
        <View style={{ paddingTop: 10 }}>
          <EquipmentManageControl  
            equipment={equipment} companies={companies} locations={locations} masterCategories={masterCategories} 
            categories={categories.sort((a, b) => a.name.localeCompare(b.name))} manufacturers={manufacturers.sort((a, b) => a.name.localeCompare(b.name))} onCancel={cancel} onSubmit={submit} 
            hideMenu={true} disablePickers={true}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

});
