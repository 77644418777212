import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface ProofLoadState {
  // Inspection Data
  proofLoadTestType: string | null,
  additionalDetails: string | null,
  standards: string | null,
  quantityToBeTested: number | null,
  loadType: string | null,
  loadValue: string | null,
  drawingRef: string | null,
}

const initialState: ProofLoadState = {
  proofLoadTestType: null,
  additionalDetails: null,
  standards: null,
  quantityToBeTested: null,
  loadType: null,
  loadValue: null,
  drawingRef: null,
}

export const proofLoadSlice = createSlice({
  name: 'proofload',
  initialState,
  reducers: {
    setProofLoadData: (state, action: PayloadAction<any>) => {
      if (action.payload.proofLoadTestType != null) state.proofLoadTestType = action.payload.proofLoadTestType;
      if (action.payload.additionalDetails != null) state.additionalDetails = action.payload.additionalDetails;
      if (action.payload.standards != null) state.standards = action.payload.standards;
      if (action.payload.quantityToBeTested != null) state.quantityToBeTested = action.payload.quantityToBeTested;

      if (action.payload.loadType != null) state.loadType = action.payload.loadType;
      if (action.payload.loadValue != null) state.loadValue = action.payload.loadValue;
      if (action.payload.drawingRef != null) state.drawingRef = action.payload.drawingRef;
    },

    clearProofLoadData: (state, action: PayloadAction) => {
      state.proofLoadTestType = null;
      state.additionalDetails = null;
      state.standards = null;
      state.quantityToBeTested = null;

      state.loadType = null;
      state.loadValue = null;
      state.drawingRef = null;
    },
  }
})

export const { setProofLoadData, clearProofLoadData } = proofLoadSlice.actions;

export default proofLoadSlice.reducer;