import { createNativeStackNavigator } from '@react-navigation/native-stack';
import DefectsListScreen from './DefectsList';
import DefectManageScreen from './DefectManage';

const Stack = createNativeStackNavigator();

export default function Categories({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={DefectsListScreen} options={{ headerShown: false, title: 'Defects'  }} />
      <Stack.Screen name="manage" component={DefectManageScreen} options={{ headerShown: false, title: 'Defect Manage'}}  />
    </Stack.Navigator>
  )
};