import { useWindowDimensions, View, Text, PanResponder } from 'react-native';
import { createDrawerNavigator, useNavigation } from '@react-navigation/drawer';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useCallback, useEffect, useMemo  } from 'react';

import { DrawerContent } from '../components/navigation/DrawerContent';

import CompaniesList from './AuthenticatedScreens/Management/Companies/CompaniesList';
import DashboardScreen from './AuthenticatedScreens/DashboardScreen';

import { RootState } from '../../../store';
import { Header } from '../components/navigation/Header';

import Companies from './AuthenticatedScreens/Management/Companies/Companies';
import Locations from './AuthenticatedScreens/Management/Locations/Locations';
import Categories from './AuthenticatedScreens/Management/Categories/Categories';
import Manufacturers from './AuthenticatedScreens/Management/Manufacturers/Manufacturers';
import Equipment from './AuthenticatedScreens/Management/Equipment/Equipment';
import Jobs from './AuthenticatedScreens/Jobs/Jobs';
import BreakdownParts from './AuthenticatedScreens/Management/Parts/Parts';
import TabletUsers from './AuthenticatedScreens/Management/TabletUsers/TabletUsers';
import WebUsers from './AuthenticatedScreens/Management/WebUsers/WebUsers';
import InspectionScreen from './AuthenticatedScreens/Reports/Inspections';;
import AssetRegisterScreen from './AuthenticatedScreens/Reports/AssetRegister';
import JobApprovalScreen from './AuthenticatedScreens/Jobs/JobApproval/JobApproval';
import RAMSScreen from './AuthenticatedScreens/Reports/RAMS';
import DefectsScreen from './AuthenticatedScreens/Reports/Defects';
import InspectionCostsScreen from './AuthenticatedScreens/Reports/InspectionCost';
import JobApprovalAssetsScreen from './AuthenticatedScreens/Jobs/JobApproval/JobApprovalAssets';
import JobInspect from './AuthenticatedScreens/Jobs/Inspect/JobInspect';
import Defects from './AuthenticatedScreens/Management/Defects/Defects';
import JobAutoScheduledScreen from './AuthenticatedScreens/Jobs/AutoScheduled/AutoScheduledJobs';
import AsyncStorage from '@react-native-async-storage/async-storage';

const Drawer = createDrawerNavigator();

export default function WebNavigator({navigation}: {navigation: any}) {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;
  const user = useSelector((state: RootState) => state.user.user);

  const dispatch = useDispatch();

  const lastInteraction = useRef(new Date());
  const [timeWentInactive, setTimeWentInactive] = useState<Date | null>(null);
  const inactivityTimer = useRef<boolean | NodeJS.Timeout | number>(false);
  const waitForInactivity = useRef<number>(0);

  const INACTIVITY_CHECK_INTERVAL_MS = 1000;

  useEffect(() => {
    if (user == null) {
      navigation.navigate("login")
    }
  }, [user])

  useEffect(() => {
    if (user) {
      // 30 mins
      const autologoutTime = (1000 * 60) * 30;
      waitForInactivity.current = autologoutTime * 1000;
    }
  }, [user, waitForInactivity.current]);

  const performAutoLogout = useCallback(() => {
    AsyncStorage.removeItem("user"); 
    navigation.navigate("login");
  }, []);

  const checkInactive = useCallback(() => {
    if (inactivityTimer.current) {
      return;
    }
    inactivityTimer.current = setInterval(() => {
      if (Math.abs(new Date().valueOf() - lastInteraction.current.valueOf()) >=
        waitForInactivity.current) {
        setIsInactive();
      }
    }, INACTIVITY_CHECK_INTERVAL_MS);
  }, []);

  useEffect(() => {
    if (user) {
      checkInactive();
    }
  }, [checkInactive]);

  const setIsActive = useCallback(() => {
    lastInteraction.current = new Date();
    if (timeWentInactive) {
      setTimeWentInactive(null);
    }

    if (user) {
      checkInactive();
    }
  }, []);

  const setIsInactive = () => {
    setTimeWentInactive(new Date());
    performAutoLogout();
    clearInterval(inactivityTimer.current as number);
    inactivityTimer.current = false;
  };

  const handleMoveShouldSetPanResponder = useCallback(() => {
    setIsActive();
    return false;
  }, [setIsActive]);

  const handleStartShouldSetPanResponder = useCallback(() => {
    setIsActive();
    return false;
  }, [setIsActive]);

  const panResponder = useMemo(() =>
    PanResponder.create({
      onStartShouldSetPanResponder: handleStartShouldSetPanResponder,
      onMoveShouldSetPanResponder: handleMoveShouldSetPanResponder,
      onStartShouldSetPanResponderCapture: () => false,
      onMoveShouldSetPanResponderCapture: () => false,
      onPanResponderTerminationRequest: () => true,
      onShouldBlockNativeResponder: () => false,
    }), []);

  return (
    <View {...panResponder.panHandlers}>
      <Drawer.Navigator 
        useLegacyImplementation={true}
        detachInactiveScreens={true}
        defaultStatus="open"
        screenOptions={{
          drawerType: isLargeScreen ? 'permanent' : 'back',
          drawerStyle: isLargeScreen ? {width: 250} : { width: '100%' },
          overlayColor: 'transparent',
          header: (props) => Header(props, user),
          unmountOnBlur: true
        }}
        drawerContent={(props) =>  <DrawerContent {...props} /> }
      >
        <Drawer.Screen name="dashboard" component={DashboardScreen} options={{ title: 'Dashboard' }} />

        <Drawer.Screen name="reports-asset-register" component={AssetRegisterScreen} options={{ title: 'Asset Register' }} />
        <Drawer.Screen name="reports-inspections" component={InspectionScreen} options={{ title: 'Inspections' }} />
        <Drawer.Screen name="reports-inspection-costs" component={InspectionCostsScreen} options={{ title: 'Inspection Costs' }} />
        <Drawer.Screen name="reports-defects" component={DefectsScreen} options={{ title: 'Defects' }} />
        <Drawer.Screen name="reports-rams" component={RAMSScreen} options={{ title: 'RAMS Reports' }} />

        <Drawer.Screen name="job-management" component={Jobs} options={{ title: 'Jobs' }} />
        <Drawer.Screen name="job-approval" component={JobApprovalScreen} options={{ title: 'Job Approval' }} />
        <Drawer.Screen name="job-approval-assets" component={JobApprovalAssetsScreen} options={{ title: 'Job Approval' }} />
        <Drawer.Screen name="job-auto-scheduled" component={JobAutoScheduledScreen} options={{ title: 'Scheduled Jobs' }} />
        <Drawer.Screen name="job-inspect" component={JobInspect} options={{ title: 'Job Inspect' }} />

        <Drawer.Screen name="administration-companies" component={Companies} options={{ title: 'Companies' }} />
        <Drawer.Screen name="administration-locations" component={Locations} options={{ title: 'Locations' }} />
        <Drawer.Screen name="administration-categories" component={Categories} options={{ title: 'Categories' }} />
        <Drawer.Screen name="administration-manufacturers" component={Manufacturers} options={{ title: 'Manufacturers' }} />
        <Drawer.Screen name="administration-equipment" component={Equipment} options={{ title: 'Equipment' }} />
        {/* <Drawer.Screen name="administration-breakdown-parts" component={BreakdownParts} options={{ title: 'Breakdown Parts' }} /> */}
        <Drawer.Screen name="administration-defects" component={Defects} options={{ title: 'Defects' }} />

        <Drawer.Screen name="administration-web-users" component={WebUsers} options={{ title: 'Users' }} />
        {/* <Drawer.Screen name="administration-engineers" component={TabletUsers} options={{ title: 'Engineers' }} /> */}
      </Drawer.Navigator>
    </View>
  );
}

