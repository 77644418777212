import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import { FlashList } from "@shopify/flash-list";
import { Octicons, Ionicons, AntDesign } from '@expo/vector-icons';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { clearJobData, setJobData } from '../../../../../store/slices/job-slice';
import { JobDatabase } from '../../../../../store/services/database/job-database';
import { RootState } from '@store/index';
import { clearBreakdownData } from '@store/slices/breakdown-slice';
import { colours } from '../../../../../config';

export function JobsList({ navigation, jobs }) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const inspectJob = async (jobViewModel) => {
    const jobDatabase = new JobDatabase();
    var job = await jobDatabase.getJobById(jobViewModel.id);

    dispatch(clearBreakdownData());
    dispatch(clearJobData());
    dispatch(setJobData({
      jobId: job.id,
      purposeId: job.purposeId,
      colourId: job.colourId,
      engineerId: user.id,
      jobTitle: `${job.companyName} - ${job.locationName} - ${moment(job.createdOn).format("DD/MM/YYYY")}`,
      jobType: job.jobType,
      ramsId: job.ramsId,
      engineerReportId: job.engineerReportId,
      locationId: job.locationId,
      companyId: job.companyId,
      autoReschedule: job.autoReschedule
    }))


    navigation.navigate('JobDetails')
  }

  const addJob = () => {
    navigation.navigate('JobManage')
  }

  const renderItem = ({item, index}) => (
    <TouchableOpacity onPress={() => inspectJob(item)} style={{ borderBottomColor: colours.primary, borderBottomWidth: 1, marginBottom: 10  }}>
      <View style={styles.row}>
        <View style={{ width: 60 }}>
          {item.jobType == 0 && 
            <View style={[styles.job__icon]}>
              <Ionicons name="checkmark-circle-outline" size={32} color="#2B93F1" />
            </View>
          }
          {item.jobType == 1 && 
            <View style={[styles.job__icon, styles.servicing__icon]}>
              <Ionicons name="cog-outline" size={32} color="#F7A931" />
            </View>
          }    
          {item.jobType == 2 && 
            <View style={[styles.job__icon, styles.breakdown__icon]}>
              <Ionicons name="warning-outline" size={32} color="#FF4C4F" />
            </View>
          }  
          {item.jobType == 3 && 
            <View style={[styles.job__icon, styles.proofload__icon]}>
              <Ionicons name="ios-reload-circle-outline" size={32} color="0D4487" />
            </View>
          }
        </View>
        <View style={[styles.column, styles.body__column]}>
          <Text style={styles.job__text}>{item.companyName}</Text>
        </View>
        <View style={[styles.column, styles.body__column]}>
          <Text  style={styles.job__text}>{item.locationName}</Text>
        </View>
        <View style={[styles.column, styles.body__column]}>
          <Text style={styles.job__text}>{item.jobNumber}</Text>
        </View>
        <View style={[styles.column, styles.body__column]}>
        <Text style={styles.job__text}>{item.createdOn == null ? null : moment(item.createdOn).format("DD/MM/YYYY")}</Text>
        </View>
        <View style={[styles.column, styles.body__column]}>
          <Text style={styles.job__text}>{item.scheduledDate == null ? null : moment(item.scheduledDate).format("DD/MM/YYYY")}</Text>
        </View>
        <View style={[styles.column, styles.body__column]}>
          <Text style={styles.job__text}>{item.jobTypeFormatted}</Text>
        </View>
        <View style={{ width: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
          <AntDesign name="rightcircleo" size={24} color="black" style={{ marginLeft: 10, }} />
        </View>
      </View>
      {item.notes != null && item.notes != "" &&
        <View style={{ padding: 10, backgroundColor: '#FFF', marginBottom: 10, marginLeft: 60, marginRight: 40 }}>
          <Text><Text style={{ fontWeight: 'bold' }}>Notes:</Text> <Text>{item.notes}</Text></Text>
        </View>
      }
    </TouchableOpacity>

  );

  return (
    <View style={styles.jobs__container}>
      <View style={{ flexDirection: 'row', marginBottom: 20 }}>
        <Text style={styles.title__text}>Jobs</Text>

        <View style={{ flex: 1 }}></View>

        <TouchableOpacity onPress={() => addJob()} style={[styles.add__job__button]}>
          <Octicons style={{ marginRight: 10 }} size={24} color={"#FFF"} name="checklist" />
          <Text style={{ color: '#FFF', fontFamily: 'OpenSans-Bold' }}>ADD JOB</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.row}>
        <View style={{ width: 60 }}></View>
        <Text style={[styles.column, styles.head__column]}>Company</Text>
        <Text style={[styles.column, styles.head__column]}>Location</Text>
        <Text style={[styles.column, styles.head__column]}>Job #</Text>
        <Text style={[styles.column, styles.head__column]}>Added On</Text>
        <Text style={[styles.column, styles.head__column]}>Scheduled Date</Text>
        <Text style={[styles.column, styles.head__column]}>Job Type</Text>
        <View style={{ width: 40 }}></View>
      </View>

      <FlashList
        data={jobs}
        renderItem={renderItem}
        estimatedItemSize={71}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  jobs__container: {
    borderRadius: 10,
    flex: 1,
    padding: 20,
    margin: 20,
    marginTop: 0,
  },

  title__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 28,
    marginBottom: 5,
  },

  row: {
    flexDirection: 'row',
  },

  column: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },

    head__column: {
      padding: 15,
      paddingTop: 0,
      fontFamily: 'OpenSans-Bold',
    },

    body__column: {
      marginBottom: 5,
      backgroundColor: '#FFF',
      flexDirection: 'row',
    },

    job__icon: {
      padding: 10,
      backgroundColor: '#E9F5FE',
      borderRadius: 10,
      
      alignSelf: 'flex-start',
      marginRight: 10,
    },

    job__text: {
      fontFamily: 'OpenSans-Regular',
      marginLeft: 5,
    },

    servicing__icon: {
      backgroundColor: '#FFF6E8'
    },

    breakdown__icon: {
      backgroundColor: '#FFE0E0',
    },

    proofload__icon: {
      backgroundColor: '#E7ECF3' 
    },
  
  add__job__button: {
    backgroundColor: colours.primary,
    padding: 8,
    borderRadius: 10,
    width: 150,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
